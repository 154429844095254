import { useEffect, useRef, useState } from "react";
import "./pops.scss";
import { AlertTriangle, Check, FileUp, Info, Loader2, X } from "lucide-react";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import axios from "axios";
import api from "../../../../api";
import { useAuth } from "../../../../providers/AuthProvider";
import { useProfile } from "../../../../providers/ProfileProvider";
import { useTransactionDetails } from "../../providers/transactionDetailsProvider";
import { reactSelectStyle } from "../../../../reactselectstyles/reactselectstyles";
import ReactSelect from "react-select";
import { useForm } from "react-hook-form";
import Big from "big.js";
import cn from "classnames";

const PAYMENT_METHOD_TYPES = {
	BANK_ACCOUNT: "BANK_ACCOUNT",
	PHONE_NUMBER: "PHONE_NUMBER",
	WALLET_ADDRESS: "WALLET_ADDRESS",
};

const Pops = () => {
	const { authTokens } = useAuth();
	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = useForm();

	const [popState, setPopState] = useState({
		paymentMethods: [
			{
				label: "",
				options: [],
			},
		],
		selectedPaymentMethods: [],
		fileToUpload: {},
		uploadingPop: false,
		uploadProgress: 0,
		uploadStarted: false,
		uploadSuccessful: false,
		loadingPops: false,
		myPops: [],
		otherPops: [],
		updatingPops: [],
		rejectingPops: [],
	});

	const [fileToUpload, setFileToUpload] = useState({});
	const { profile } = useProfile();
	const { request } = useTransactionDetails();

	const userIsOwnerOfOrderRequest = () => {
		const isOwner = request.createdBy == profile.id;
		return isOwner;
	};

	const userIsBuyer = userIsOwnerOfOrderRequest;

	const userIsOwnerOfOrder = () => {
		const isOwner = request.order.createdBy == profile.id;
		return isOwner;
	};
	const userIsSeller = userIsOwnerOfOrder;

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/*": [],
		},
		maxFiles: 1,
		onDrop: (acceptedFiles) => {
			if (!_.isEmpty(acceptedFiles)) {
				const file = {
					acceptedFile: acceptedFiles[0],
					name: acceptedFiles[0].name,
					preview: URL.createObjectURL(acceptedFiles[0]),
				};
				setFileToUpload(file);
			}
		},
	});

	useEffect(() => {
		//Build payment methods
		const buildBuyersPaymentMethods = userIsSeller();

		let paymentMethods;
		if (buildBuyersPaymentMethods) {
			// Building buyer's payment methods (from request)
			paymentMethods = request.paymentMethods;
		} else {
			// Building seller's payment methods (from order)
			paymentMethods = request.order.paymentMethods;
		}

		const PaymentMethodsOptions = {};
		paymentMethods.forEach((paymentMethod) => {
			switch (paymentMethod.type) {
				case PAYMENT_METHOD_TYPES.BANK_ACCOUNT:
					PaymentMethodsOptions["Bank"] = PaymentMethodsOptions["Bank"] || {
						label: "Bank",
						options: [],
					};
					PaymentMethodsOptions["Bank"].options.push({
						label: `${paymentMethod.bankName} : ${paymentMethod.accountNumber}`,
						value: paymentMethod,
					});
					break;
				case PAYMENT_METHOD_TYPES.PHONE_NUMBER:
					PaymentMethodsOptions["Phonenumber"] = PaymentMethodsOptions["Phonenumber"] || { label: "Phonenumber", options: [] };
					PaymentMethodsOptions["Phonenumber"].options.push({
						label: `${paymentMethod.phonenumberLabel} : ${paymentMethod.phonenumber}`,
						value: paymentMethod,
					});
					break;
				case PAYMENT_METHOD_TYPES.WALLET_ADDRESS:
					PaymentMethodsOptions["Wallet Address"] = PaymentMethodsOptions["Wallet Address"] || { label: "Wallet Address", options: [] };
					PaymentMethodsOptions["Wallet Address"].options.push({
						label: `${paymentMethod.walletAddressLabel} : ${paymentMethod.walletAddress}`,
						value: paymentMethod,
					});
					break;
			}
		});

		const popStatePaymentMethods = [];
		Object.keys(PaymentMethodsOptions).forEach((key) => {
			const paymentMethodOption = PaymentMethodsOptions[key];
			popStatePaymentMethods.push(paymentMethodOption);
		});

		// Get proof of payments
		(async () => {
			const domain = api.getApiDomain();
			updatePopState({ key: "loadingPops", value: true });
			const order = request.order;
			const result = await api.getProofOfPayments({ token: authTokens.IdToken, request, order });
			const proofOfPayments = result.data.data.map((datum) => {
				const pop = datum.attributes;
				const fileUrl = `${domain}/orders/${order.id}/requests/${request.id}/proofofpayments/${pop.id}/files/${pop.file}?token=${authTokens.IdToken}`;
				pop.fileUrl = fileUrl;
				return pop;
			});

			const buyerProofOfPayments = proofOfPayments.filter((pop) => pop.popType == "BUYER_POP");
			const sellerProofOfPayments = proofOfPayments.filter((pop) => pop.popType == "SELLER_POP");

			if (userIsBuyer()) {
				updatePopStates([
					{ key: "paymentMethods", value: popStatePaymentMethods },
					{ key: "loadingPops", value: false },
					{ key: "myPops", value: buyerProofOfPayments },
					{ key: "otherPops", value: sellerProofOfPayments },
				]);
			} else {
				updatePopStates([
					{ key: "paymentMethods", value: popStatePaymentMethods },
					{ key: "loadingPops", value: false },
					{ key: "myPops", value: sellerProofOfPayments },
					{ key: "otherPops", value: buyerProofOfPayments },
				]);
			}
		})();

		// Make sure to revoke the data uris to avoid memory leaks, will run on unmount
		return () => URL.revokeObjectURL(fileToUpload.preview);
	}, []);

	const uploadPopDialogRef = useRef(null);

	const updatePopState = ({ key, value }) => {
		const newPopState = { ...popState };
		newPopState[key] = value;
		setPopState(newPopState);
	};

	const updatePopStates = (states) => {
		const newPopState = { ...popState };
		states.forEach(({ key, value }) => {
			newPopState[key] = value;
		});
		setPopState(newPopState);
	};

	const startUploadPop = () => {
		uploadPopDialogRef.current.showModal();
	};

	const getUploadPopLabel = () => {
		let label = "Drag & Drop or Browse the file";
		if (!_.isEmpty(fileToUpload.name)) {
			label = fileToUpload.name;
		}
		return label;
	};

	const uploadFile = async ({ amount, paymentMethod }) => {
		const domain = api.getApiDomain();
		const fileForm = new FormData();
		fileForm.append("paymentMethod", JSON.stringify(paymentMethod.value));
		fileForm.append("amount", amount);
		fileForm.append("currency", paymentMethod.value.currency);
		fileForm.append("fileName", fileToUpload.name);
		fileForm.append("file", fileToUpload.acceptedFile);
		const token = authTokens.IdToken;
		const orderId = request.order.id;
		const requestId = request.id;
		const result = await axios.post(`${domain}/orders/${orderId}/requests/${requestId}/proofofpayment`, fileForm, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			onUploadProgress: (progress) => {},
		});

		updatePopStates([
			{ key: "uploadStarted", value: true },
			{ key: "uploadingPop", value: false },
			{ key: "uploadSuccessful", value: true },
		]);

		closeUploadPop();

		setTimeout(() => {
			updatePopStates([
				{ key: "uploadStarted", value: false },
				{ key: "uploadingPop", value: false },
				{ key: "uploadSuccessful", value: false },
			]);

			//TO DO : Clear upload memory and Close upload pop form
		}, 3000);
	};

	const confirmUploadPop = async (popForm) => {
		const amount = popForm.receiptAmount;
		const paymentMethod = popState.selectedPaymentMethods;

		updatePopStates([
			{ key: "uploadStarted", value: true },
			{ key: "uploadingPop", value: true },
		]);

		await uploadFile({ amount, paymentMethod });
	};

	const closeUploadPop = () => {
		//TO DO : Clear upload pop memory
		uploadPopDialogRef.current.close();
	};

	const formatAmounts = (amount) => {
		const locale = Intl.NumberFormat().resolvedOptions().locale;
		let formatedAmount = parseFloat(amount);
		formatedAmount = `${formatedAmount.toLocaleString(locale)}`;
		return formatedAmount;
	};

	const viewPaymentMethod = (paymentMethod) => {
		switch (paymentMethod.type) {
			case PAYMENT_METHOD_TYPES.BANK_ACCOUNT:
				return `${paymentMethod.bankName} : ${paymentMethod.accountNumber}`;
			case PAYMENT_METHOD_TYPES.PHONE_NUMBER:
				return `${paymentMethod.phonenumberLabel} : ${paymentMethod.phonenumber}`;
			case PAYMENT_METHOD_TYPES.WALLET_ADDRESS:
				return `${paymentMethod.walletAddressLabel} : ${paymentMethod.walletAddress}`;
		}
	};

	const verifyPop = async (pop) => {
		let updatingPops = [...popState.updatingPops];
		updatingPops.push(pop.id);
		updatePopState({ key: "updatingPops", value: updatingPops });
		const order = request.order;
		const result = await api.verifyProofOfPayment({ token: authTokens.IdToken, request, order, pop });
		updatingPops = updatingPops.filter((id) => id != pop.id);
		updatePopState({ key: "updatingPops", value: updatingPops });
		pop.popState = "VERIFIED";
	};

	const rejectPop = async (pop) => {
		let updatingPops = [...popState.updatingPops];
		updatingPops.push(pop.id);
		updatePopState({ key: "updatingPops", value: updatingPops });
		const order = request.order;
		const result = await api.rejectProofOfPayment({ token: authTokens.IdToken, request, order, pop });
		updatingPops = updatingPops.filter((id) => id != pop.id);
		updatePopState({ key: "updatingPops", value: updatingPops });
		pop.popState = "REJECTED";
	};
	const REQUEST_FORM_FIELDS = {
		receiptAmount: "receiptAmount",
	};
	const setMaxAmount = () => {
		const amount = userIsSeller() ? request.sellBalance : request.buyBalance;

		setValue(REQUEST_FORM_FIELDS.receiptAmount, amount);
	};

	const canUpload = () => {
		let pass = true;
		if (request.requestState == "FULFILLED") {
			pass = false;
		}
		const balanceToCheck = userIsSeller() ? request.sellBalance : request.buyBalance;
		if (Big(balanceToCheck).lte(0)) {
			pass = false;
		}
		return pass;
	};

	return (
		<div id="pops_component">
			<div className="my_documents">
				<div className="header">
					<div className="labe">My Documents</div>

					{canUpload() && (
						<div className="upload_pop" onClick={startUploadPop}>
							Upload
						</div>
					)}

					<dialog className="upload_pop_dialog_container" ref={uploadPopDialogRef}>
						<div className="upload_pop_dialog_header">
							<div className="label">Upload Proof of Payment</div>
							<X className="close" onClick={closeUploadPop} />
						</div>
						<div className="upload_pop_dialog_body">
							<div className="upload_pop_dialog_details">
								<div className="amount_container">
									<div className="label">Bal Amount</div>
									<div className="amount">
										{" "}
										{userIsBuyer() && (
											<>
												{formatAmounts(request.buyBalance)} {request.buyBalanceCurrency}
											</>
										)}
										{userIsSeller() && (
											<>
												{formatAmounts(request.sellBalance)} {request.sellBalanceCurrency}
											</>
										)}
									</div>
								</div>
								<div className="payment_methods">
									<div className="header"> Payment methods</div>
									<ReactSelect
										styles={reactSelectStyle}
										closeMenuOnSelect={false}
										value={popState.selectedPaymentMethods}
										options={popState.paymentMethods}
										onChange={(evt) => {
											updatePopState({
												key: "selectedPaymentMethods",
												value: evt,
											});
										}}
									/>

									<div className="deposit_amounts"></div>
								</div>

								<div className="upload_btn_container">
									<div className="label">Upload POP picture</div>
									<div {...getRootProps({ className: "dropzone upload_btn" })} title={getUploadPopLabel()}>
										<input {...getInputProps()} />
										<div className="label">{getUploadPopLabel()}</div>
										<FileUp className="icon" />
									</div>

									{popState.uploadStarted && (
										<div className="uploading_progress_bar_container">
											<div className="uploading_progress_bar" style={{ width: `${popState.uploadProgress}%` }}></div>
										</div>
									)}
								</div>

								<div className="receipt_amount_container">
									Enter Amount on the receipt
									<input
										className="receipt_amount"
										type="number"
										{...register("receiptAmount", {
											required: "Please fill in receipt amount",
											validate: {
												positive: (value) => parseFloat(value) > 0 || "Receipt amount must be greater than 0",
												notExceedBalance: (value) => {
													let pass = false;
													let balanceAmt = "N/A";

													if (userIsBuyer()) {
														balanceAmt = `${formatAmounts(request.buyBalance)} ${request.buyBalanceCurrency}`;
														pass = Big(value).lte(Big(request.buyBalance));
													} else if (userIsSeller()) {
														balanceAmt = `${formatAmounts(request.sellBalance)} ${request.sellBalanceCurrency}`;
														pass = Big(value).lte(Big(request.sellBalance));
													}

													return pass || `Receipt amount must not exceed balance ( ${balanceAmt} )`;
												},
											},
										})}
									/>
									{errors.receiptAmount && (
										<span className="receipt_amount_input_error">
											<Info className="receipt_amount_input_error_icon" />
											{errors.receiptAmount.message}
										</span>
									)}
									<div className="subtitle">
										<div className="max_amount_subtitle">Set to Bal Amount</div>
										<div className="max_control" onClick={setMaxAmount}>
											ALL
										</div>
									</div>
								</div>
								{!popState.uploadStarted && (
									<div className="upload_pop_controls">
										<div className="upload_pop_control cancel" onClick={closeUploadPop}>
											Cancel
										</div>
										<div className="upload_pop_control confirm" onClick={handleSubmit(confirmUploadPop)}>
											Submit PoP
										</div>
									</div>
								)}
								{popState.uploadStarted && (
									<div className="upload_status">
										{popState.uploadingPop && <Loader2 className="uploading_pop_loader" />}
										{!popState.uploadingPop && popState.uploadSuccessful && <Check />}
										{!popState.uploadingPop && !popState.uploadSuccessful && (
											<div className="upload_error">
												<AlertTriangle /> Error uploading POP.. please try again later
											</div>
										)}
									</div>
								)}
							</div>

							{!_.isEmpty(fileToUpload.preview) && (
								<div className="image_preview">
									<div className="header">Image Preview</div>
									<div className="image_container">
										<img
											src={fileToUpload.preview}
											// Revoke data uri after image is loaded
											onLoad={() => {
												URL.revokeObjectURL(fileToUpload.preview);
											}}
										/>
									</div>
								</div>
							)}
						</div>
					</dialog>
				</div>

				{popState.loadingPops && (
					<div className="pop_list_loader_container">
						Loading documents...
						<Loader2 className="pop_list_loader" />
					</div>
				)}

				{!popState.loadingPops && (
					<div className="pop_list">
						{popState.myPops.length == 0 && <div className="no_pop">No document yet..</div>}

						{popState.myPops.map((pop) => (
							<div className={cn("pop", { verified: pop.popState == "VERIFIED" })} key={pop.id}>
								<div className="image_preview_container">
									<img src={pop.fileUrl} alt="" />
								</div>
								<div className="details_container">
									<div className="pop_line_1">
										<div className="pop_amount">
											{formatAmounts(pop.amount)} {pop.currency}
										</div>
										{pop.popState == "PENDING" && <div className="pop_status pending">pending</div>}
										{pop.popState == "VERIFIED" && <div className="pop_status verified">verified</div>}
										{pop.popState == "REJECTED" && <div className="pop_status rejected">rejected</div>}
									</div>
									<div className="pop_line_2">
										<div className="pop_payment_method_label">Paid to</div>
										<div className="pop_payment_method">{viewPaymentMethod(pop.paymentMethod)}</div>
									</div>
								</div>
								<div className="more_container">
									<svg
										className="detailsIcon"
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
										class="lucide lucide-ellipsis-vertical more_action">
										<circle cx="12" cy="12" r="1" />
										<circle cx="12" cy="5" r="1" />
										<circle cx="12" cy="19" r="1" />
									</svg>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
			<div className="counter_party_documents">
				<div className="header">
					{userIsBuyer() && <> Seller's Documents</>}
					{userIsSeller() && <>Buyer's Documents</>}
				</div>
				{popState.loadingPops && (
					<div className="pop_list_loader_container">
						Loading documents...
						<Loader2 className="pop_list_loader" />
					</div>
				)}

				{!popState.loadingPops && (
					<div className="pop_list">
						{popState.otherPops.length == 0 && <div className="no_pop">No document yet..</div>}
						{popState.otherPops.map((pop) => (
							<div className="pop_container" key={pop.id}>
								<div className="pop">
									<div className="image_preview_container">
										<img src={pop.fileUrl} alt="" />
									</div>
									<div className="details_container">
										<div className="pop_line_1">
											<div className="pop_amount">
												{formatAmounts(pop.amount)} {pop.currency}
											</div>
											{pop.popState == "PENDING" && <div className="pop_status pending">pending</div>}
											{pop.popState == "VERIFIED" && <div className="pop_status verified">verified</div>}
											{pop.popState == "REJECTED" && <div className="pop_status rejected">rejected</div>}
										</div>
										<div className="pop_line_2">
											<div className="pop_payment_method_label">Paid to</div>
											<div className="pop_payment_method">{viewPaymentMethod(pop.paymentMethod)}</div>
										</div>
									</div>
									<div className="more_container">
										<svg
											className="detailsIcon"
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											stroke="currentColor"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
											class="lucide lucide-ellipsis-vertical more_action">
											<circle cx="12" cy="12" r="1" />
											<circle cx="12" cy="5" r="1" />
											<circle cx="12" cy="19" r="1" />
										</svg>
									</div>
								</div>
								{popState.updatingPops.includes(pop.id) && (
									<div className="pop_actions_loader_container">
										<Loader2 className="pop_actions_loader" />
									</div>
								)}

								{!popState.updatingPops.includes(pop.id) && pop.popState == "PENDING" && (
									<div className="pop_actions">
										<div
											className="reject"
											onClick={() => {
												rejectPop(pop);
											}}>
											Reject
										</div>
										<div
											className="verify"
											onClick={() => {
												verifyPop(pop);
											}}>
											Verify
										</div>
									</div>
								)}
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};
export default Pops;
