import React, { useRef } from "react";
import "./profilePopover.scss";
import profileImage from "../../assets/profile-pic.png";
import stroke from "../../assets/stroke.svg";
import settingsIcon from "../../assets/settings_icon.svg";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import logout_icon from "../../assets/popover_logout.svg";
import { useProfile } from "../../providers/ProfileProvider";
import { useFeatureFlags } from "../../providers/FeatureFlags/FeatureFlagsProvider";
import { useAuth } from "../../providers/AuthProvider";
const ProfilePopOver = () => {
	const { setIsLoggedIn, setAuthTokens } = useAuth();
	const { isFeatureFlagEnabled } = useFeatureFlags();
	const { profile } = useProfile();
	const navigate = useNavigate();
	const logoutDialogRef = useRef(null);

	const goToSettings = () => {
		navigate("/settings");
	};

	const startLogout = () => {
		logoutDialogRef.current.showModal();
	};

	const cancelLogout = () => {
		logoutDialogRef.current.close();
	};

	const confirmLogout = () => {
		//Logout (should be in auth provider)
		localStorage.removeItem("authTokens");
		setAuthTokens({});
		setIsLoggedIn(false);
		navigate("/login");
	};

	return (
		<div className="profilePopOver">
			<div className="profileImage">
				<img src={profileImage} alt="" />
			</div>
			<span className="username">
				{profile.firstName} {profile.lastName}
			</span>
			<div className="divider">
				<img src={stroke} alt="" />
			</div>

			{isFeatureFlagEnabled("settings") && (
				<div className="settings" onClick={goToSettings}>
					<img src={settingsIcon} alt="" />
					<span className="label">Settings</span>
				</div>
			)}

			{/* <div className="logout_container" onClick={startLogout}>
				<img src={logout_icon} alt="" />
				<span className="logout_label">Logout</span>
			</div> */}

			<div className="logout_container" onClick={startLogout}>
				<img src={logout_icon} alt="" />
				<div className="logout_label">Logout</div>
			</div>
			<dialog className="logout_dialog_container" ref={logoutDialogRef}>
				<div className="logout_dialog_heading">Are you sure you want to log out?</div>
				<div className="logout_dialog_controls">
					<div onClick={confirmLogout} className="logout_dialog_control logout_dialog_confirm">
						Yes
					</div>
					<div onClick={cancelLogout} className="logout_dialog_control logout_dialog_cancel">
						No
					</div>
				</div>
			</dialog>
		</div>
	);
};

export default ProfilePopOver;
