import React, { useState, useRef } from "react";
import "./verification_code_input.scss";

const VerificationCodeInput = ({ confirmEmail, code, setCode }) => {
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      code[index] = value;
      setCode([...code]);

      if (index < code.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    }

    const codeStr = code.join("");

    if (codeStr.length == 6) {
      confirmEmail(codeStr);
    }
  };

  const handlePaste = (e) => {
    const pastedValue = e.clipboardData.getData("text");
    if (/^[0-9]{6}$/.test(pastedValue)) {
      const newCode = pastedValue.split("").slice(0, 6);
      setCode(newCode);
      confirmEmail(newCode.join(""));
      return;
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();

      code[index] = "";
      setCode([...code]);

      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }

      return;
    }

    if (e.key === "Delete") {
      e.preventDefault();

      code[index] = "";
      setCode([...code]);

      if (index > -1) {
        inputRefs[index + 1].current.focus();
      }

      return;
    }

    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      code[index] = value;
      setCode([...code]);

      if (index < code.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  return (
    <div className="verification-code">
      {code.map((digit, index) => (
        <>
          <input
            className="verification_code_input"
            type="text"
            key={index}
            value={digit}
            maxLength="1"
            pattern="[0-9]"
            title="Please enter a digit (0-9)"
            onChange={(e) => handleInputChange(e, index)}
            onPaste={handlePaste}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={inputRefs[index]}
          />
          {index == 2 && (
            <div
              key={"seperator"}
              className="verification_code_input_seperator">
              -
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default VerificationCodeInput;
