import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useReducer } from "react";
import { useAuth } from "../../../../../providers/AuthProvider";
import _ from "lodash";
import api from "../../../../../api";
import { formatAmount } from "../../../../../common/formatAmount";
import moment from "moment";
import Big from "big.js";

const AllOrdersReportContext = createContext();
const AllOrdersReportDispatchContext = createContext();

export const ALL_ORDERS_REPORT_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
};

export const AllOrdersReportProvider = ({ children }) => {
	const { authTokens } = useAuth();

	const getAllOrdersReport = useQuery({
		queryKey: ["allOrdersReport"],
		queryFn: () => {
			return api.getAllOrdersReport({ token: authTokens.IdToken });
		},
		enabled: false,
	});

	const fetchAllOrdersReport = async () => {
		allOrdersReportDispatch({ type: ALL_ORDERS_REPORT_ACTIONS.updateState, key: "isFetchingAllOrdersReport", value: true });
		const allOrdersReportRefetchResult = await getAllOrdersReport.refetch();
		allOrdersReportDispatch({ type: ALL_ORDERS_REPORT_ACTIONS.updateState, key: "isFetchingAllOrdersReport", value: false });
		if (allOrdersReportRefetchResult.status == "success") {
			let allOrdersReport = allOrdersReportRefetchResult.data.data.data;
			allOrdersReport = allOrdersReport.map((_) => {
				const order = _.attributes;
				const txn = order._rawTxn;
				const txnType = order._rawTxnType;
				order.transactionID = order.transactionID.split("-")[0];

				//Clean up manual transaction
				if (txnType == "manual") {
					order.price = `1 ${txn.sellCurrency} / ${formatAmount(txn.sellRate)} ${txn.buyCurrency}`;
					order.amount = `${formatAmount(txn.sellVolume)} ${txn.buyCurrency}`;
					order.total = `${formatAmount(txn.deliveredVolume)} ${txn.sellCurrency}`;
					order.date = moment(txn.date).format("DD/MM/YYYY - h:mm A");
				}

				//Clean up otctrade transaction
				if (txnType == "otctrade") {
					order.pair = `${txn.order.buyCurrency} - ${txn.order.sellCurrency}`;
					order.price = `1 ${txn.order.sellCurrency} / ${formatAmount(txn.order.volumeTypeAmount)} ${txn.order.buyCurrency}`;
					order.amount = `${formatAmount(txn.desiredPurchaseAmount)} ${txn.order.buyCurrency}`;
					const total = Big(txn.desiredPurchaseAmount).div(Big(txn.order.volumeTypeAmount)).toString();
					order.total = `${formatAmount(total)} ${txn.order.sellCurrency}`;
					order.date = moment(txn.createdAt).format("DD/MM/YYYY - h:mm A");
				}

				return order;
			});
			allOrdersReportDispatch({ type: ALL_ORDERS_REPORT_ACTIONS.updateState, key: "allOrdersReport", value: allOrdersReport });
		}
	};

	const [allOrdersReportState, allOrdersReportDispatch] = useReducer(AllOrdersReportReducer, {
		allOrdersReport: [],
		isFetchingAllOrdersReport: false,
		fetchAllOrdersReport,
		getAllOrdersReport,
	});

	return (
		<AllOrdersReportContext.Provider value={allOrdersReportState}>
			<AllOrdersReportDispatchContext.Provider value={allOrdersReportDispatch}>{children}</AllOrdersReportDispatchContext.Provider>
		</AllOrdersReportContext.Provider>
	);
};

export const useAllOrdersReport = () => {
	return useContext(AllOrdersReportContext);
};

export const useAllOrdersReportDispatch = () => {
	return useContext(AllOrdersReportDispatchContext);
};

const AllOrdersReportReducer = (allOrdersReportState, action) => {
	switch (action.type) {
		case ALL_ORDERS_REPORT_ACTIONS.updateState: {
			const newState = { ...allOrdersReportState };
			newState[action.key] = action.value;
			return newState;
		}
		case ALL_ORDERS_REPORT_ACTIONS.updateStates: {
			const newState = { ...allOrdersReportState };
			action.states.forEach(({ key, value }) => {
				newState[key] = value;
			});
			return newState;
		}
		default: {
			throw Error("[AllOrdersReportReducer] Unknown action: " + action.type);
		}
	}
};
