import "./createCompany.scss";
import { useNavigate } from "react-router-dom";
import { Loader2, Info } from "lucide-react";
import { useForm } from "react-hook-form";
import bellIcon from '../../assets/notification_icon.svg';
import cn from "classnames";
import api from "../../api";
import { useMutation } from "@tanstack/react-query";
const CreateCompany = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();
    const navigate = useNavigate();
    const createCompany = useMutation({
        mutationFn: (details) => {
          return api.createCompany(details);
        },
        onSuccess: () => {
          navigate("/verify_company_account");
        },
    });

    const onNext = (details) => {
        createCompany.mutate(details);
    };
    return (
        <div className="create_company_account_page">
            <div className="create_company_account_header">
                <div className="create_company_account_heading">
                    Create a company account
                </div>
                <div className="bellIcon">
                    <img src={bellIcon} alt="" />
                </div>
            </div>
            <div className="create_company_account_sub_heading">
                Setup a company account to operate company funds, trade and make transfers on Muda. Company account allows to manage funds by teams with established roles and scopes of responsibilities.
            </div>
          <div className="create_company_account_body">
            <div className="create_company_account_form">
                <div className="form_leftside">
                    <label className="create_acct_input">
                        <span className="create_acct_input_label">
                            Company name <span className="required">*</span>
                        </span>
                        <input 
                            placeholder="Company name"
                            name="companyName"
                            className={cn({ error: errors.companyName?.type == "required" })}
                            {...register("companyName", { required: true })}
                        />
                        {errors.companyName?.type === "required" && (
                            <span className="create_acct_input_error">
                            <Info className="signup_input_error_icon" /> Please fill in
                            your company name
                            </span>
                        )}
                    </label>
                    <div className="spacer"></div>
                    <label className="create_acct_input">
                        <span className="create_acct_input_label">
                            Country of incorporation <span className="required">*</span>
                        </span>
                        <input
                            placeholder="Enter your country"
                            name="country" 
                            id="country"
                            {...register("country", { required: true })}
                        />  
                    </label>
                    <div className="spacer"></div>
                    <label className="create_acct_input">
                        <span className="create_acct_input_label">
                            City of incorporation  <span className="required">*</span>
                        </span>
                        <input
                            placeholder="Enter your city"
                            name="city"
                            id="city"
                            {...register("city", { required: true })}
                        />  
                    </label>
                    <div className="spacer"></div>
                    <label className="create_acct_input">
                        <span className="create_acct_input_label">
                            Incorporation address
                        </span>
                        <input
                            placeholder="Enter incorporation address"
                            name="address"
                            id="address"
                            {...register("address")}
                        />  
                    </label>
                </div>
                <div className="form_rightside">
                    <label className="create_acct_input">
                        <span className="create_acct_input_label">
                        Zip/Postal code
                        </span>
                        <input
                            placeholder="SE17 9SE"
                            name="zip_code"
                            {...register("zip_code")}
                        />
                    </label>
                    <div className="spacer"></div>
                    <label className="create_acct_input">
                        <span className="create_acct_input_label">
                            Incorporation number <span className="required">*</span>
                        </span>
                        <input
                            placeholder="123456789"
                            name="incorporationNumber"
                            {...register("incorporationNumber", { required: true })}
                        />
                        {errors.incorporationNumber?.type === "required" && (
                            <span className="create_acct_input_error">
                                <Info className="signup_input_error_icon" /> Please fill in
                                your incorporation number
                            </span>
                        )}
                    </label>
                    <div className="spacer"></div>
                    <label className="create_acct_input">
                        <span className="create_acct_input_label">
                            Tax identification number
                        </span>
                        <input placeholder="123456789" name="tin" />
                        {errors.tin?.type === "required" && (
                            <span className="create_acct_input_error">
                                <Info className="signup_input_error_icon" /> Please fill in
                                your Tax identification number
                            </span>
                        )}
                    </label>
                </div>
            </div>
            <div className="create_acct_btn_box">
                <div
                    className={cn("next_btn", { is_creating_company: createCompany.isPending })}
                    onClick={handleSubmit(onNext)}>
                    {createCompany.isPending && <Loader2 className="createCompany_loader" />}
                    {!createCompany.isPending && <span>Next</span>}
                </div>
                <div className="back_btn">Back to settings</div>
            </div>
          </div>
        </div>
    );
};
export default CreateCompany;