import "./wallets.scss";
import dashboard_copy_icon from "../../assets/dashboard_copy_icon.svg";
import dashboard_trade_icon from "../../assets/dashboard_trade_icon.svg";
import dashboard_deposit_icon from "../../assets/dashboard_deposit_icon.svg";
import dashboard_withdraw_icon from "../../assets/dashboard_withdraw_icon.svg";
import bell_icon from "../../assets/bell_icon.svg";
import dot_icon from "../../assets/dot_icon.svg";
import tether_icon from "../../assets/tether_icon.svg";
import ethereum_icon from "../../assets/ethereum_icon.svg";
import bitcoin_icon from "../../assets/bitcoin_icon.svg";
import ngn_icon from "../../assets/ngn_icon.svg";
import kes_icon from "../../assets/kes_icon.svg";
import ugx_icon from "../../assets/ugx_icon.svg";
import trend_down_icon from "../../assets/trend_down_icon.svg";
import trend_up_icon from "../../assets/trend_up_icon.svg";
import Transact from "../transact/transact";
import { ChevronDown, PlusIcon } from "lucide-react";
import { useWallets } from "../../providers/WalletsProvider";
import { useRates } from "../../providers/Rates/RatesProvider";
import Big from "big.js";
import { useEffect } from "react";

const columns = [
	{
		id: 1,
		name: "Wallet",
		isInput: true,
	},
	{
		did: 2,
		name: "Amount",
		isInput: false,
	},
	{
		id: 3,
		name: "Price (USD)",
		isInput: false,
	},
	{
		id: 4,
		name: "Rate",
		isInput: false,
	},
	{
		id: 5,
		name: "Trend 24h",
		isInput: false,
	},
	{
		id: 6,
		name: "Actions",
		isInput: false,
	},
];

const actions = [
	{
		id: 1,
		icon: dashboard_trade_icon,
	},
	{
		id: 2,
		icon: dashboard_deposit_icon,
	},
	{
		id: 3,
		icon: dashboard_withdraw_icon,
	},
];

const body_content = [
	{
		id: 1,
		name: "Tether",
		icon: tether_icon,
		amount: "14.630 USDT",
		price: "USD 14.62",
		rate: "0.00015",
		trend: "-0.08%",
		actions: actions,
	},
	{
		id: 2,
		name: "Ethereum",
		icon: ethereum_icon,
		amount: "0.0600 ETH",
		price: "USD 100.08",
		rate: "0.00015",
		trend: "+0.77%",
		actions: actions,
	},
	{
		id: 3,
		name: "Bitcoin",
		icon: bitcoin_icon,
		amount: "0.0097 USDT",
		price: "USD 256.39",
		rate: "0.00015",
		trend: "+0.36%",
		actions: actions,
	},
	{
		id: 4,
		name: "NGN",
		icon: ngn_icon,
		amount: "0.000 NGN",
		price: "USD 00.00",
		rate: "0.00013",
		trend: "-0.34%",
		actions: actions,
	},
	{
		id: 5,
		name: "KES",
		icon: kes_icon,
		amount: "0.000 KES",
		price: "USD 00.00",
		rate: "0.00068",
		trend: "-0.034%",
		actions: actions,
	},
	{
		id: 6,
		name: "UGX",
		icon: ugx_icon,
		amount: "0.000 UGX",
		price: "USD 00.00",
		rate: "0.00026",
		trend: "-0.34%",
		actions: actions,
	},
];

const Wallets = () => {
	const { isLoadingRates, convert, rates, currencies, getRate } = useRates();
	const { wallets, isLoadingWallets } = useWallets();

	const getWalletPrice = (wallet) => {
		let amountStr = "-1";
		try {
			const amount = convert({
				rates,
				from: wallet.currency,
				to: "USD",
				amount: wallet.balance,
			});
			amountStr = `USD ${Big(amount).round(4).toString()}`;
		} catch (err) {}
		return amountStr;
	};

	const getWalletRate = (wallet) => {
		let rateStr = "-1";
		try {
			let rate = getRate({
				rates,
				from: wallet.currency,
				to: "USD",
			});
			rateStr = `${Big(rate).round(4).toString()} USD / ${wallet.currency}`;

			if (Big(rate).lt(1)) {
				rate = getRate({
					rates,
					from: "USD",
					to: wallet.currency,
				});
				rateStr = `${Big(rate).round(4).toString()} ${wallet.currency} / USD`;
			}
		} catch (err) {}
		return rateStr;
	};

	return (
		<div className="wallet_page">
			<div className="wallet_heading">
				<div className="title">Wallets</div>
				<div className="icon">
					<img src={bell_icon} alt="bell icon" className="bell_icon" />
					<img src={dot_icon} alt="copy icon" className="dot_icon" />
				</div>
			</div>
			<div className="wallet_sub_heading">
				<div className="wallet_lhs">
					<div className="balance_container">
						<div className="balance_amount">
							$1,003.<span className="decimal">61</span>
						</div>
						<div className="balance_title">Total balance</div>
					</div>
					<div className="currency_container">
						<div className="input_field">
							<select>
								<option selected>USD</option>
							</select>
						</div>
						<div className="currency_title">Default currency</div>
					</div>
				</div>

				<div className="wallet_rhs">
					<div className="copy">
						<img src={dashboard_copy_icon} alt="copy icon" />
					</div>
					<button className="control trade" popovertarget="transact_popover" popovertargetaction="show">
						<div className="control_icon">
							<img src={dashboard_trade_icon} alt="trade icon" />
						</div>
						<div className="control_title">TRADE</div>
					</button>
					<button className="control deposit">
						<div className="control_icon">
							<img src={dashboard_deposit_icon} alt="deposit icon" />
						</div>
						<div className="control_title">DEPOSIT</div>
					</button>
					<button className="control withdraw">
						<div className="control_icon">
							<img src={dashboard_withdraw_icon} alt="withdraw icon" />
						</div>
						<div className="control_title">WITHDRAW</div>
					</button>
					<div id="transact_popover" popover="auto">
						<Transact />
					</div>
				</div>
			</div>
			<div className="plus_container">
				<div className="plus_button">
					<PlusIcon />
				</div>
			</div>
			<div className="wallet_table_container">
				<table className="wallet_table">
					<thead>
						<tr>
							{columns.map((col) => (
								<th key={col.id}>
									<div className={`${col.isInput && "field_with_input"}`}>
										<p>{col.name}</p>
										{col.isInput && (
											<div className="input_field">
												<input placeholder="search" />
												<ChevronDown />
											</div>
										)}
									</div>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{wallets.map((wallet) => (
							<tr key={wallet.id}>
								<td className="row_with_icon">
									<div>
										<img src={bitcoin_icon} alt={`${wallet.currency}`} />
									</div>
									<p className="title">{wallet.currency}</p>
								</td>
								<td>{wallet.balance}</td>
								<td>{getWalletPrice(wallet)}</td>
								<td>{getWalletRate(wallet)}</td>

								<td>
									{/* {wallet.trend.startsWith("+") && (
                    <div className="trends">
                      <img src={trend_up_icon} alt="trend up" />
                      <p>{wallet.trend}</p>
                    </div>
                  )}
                  {item.trend.startsWith("-") && (
                    <div className="trends">
                      <img src={trend_down_icon} alt="trend down" />
                      <p>{item.trend}</p>
                    </div>
                  )} */}
								</td>

								<td className="action_row">
									{actions.map((action) => (
										<div className="action_button" key={action.id}>
											<img src={action.icon} alt={`${action.id}`} />
										</div>
									))}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};
export default Wallets;
