import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useReducer } from "react";
import _ from "lodash";
import moment from "moment";
import { formatAmount } from "../../../../common/formatAmount";
import { useAuth } from "../../../../providers/AuthProvider";
import api from "../../../../api";

const ProfitAndLossReportContext = createContext();
const ProfitAndLossReportDispatchContext = createContext();

export const PROFIT_AND_LOSS_REPORT_ACTIONS = {
  updateState: "updateState",
  updateStates: "updateStates",
};

export const ProfitAndLossReportProvider = ({ children }) => {
  const { authTokens } = useAuth();

  const getProfitAndLossReport = useQuery({
    queryKey: ["profitAndLossReport"],
    queryFn: () => {
      return api.getProfitAndLossReport({ token: authTokens.IdToken });
    },
    enabled: false,
  });

  const fetchProfitAndLossReport = async () => {
    profitAndLossReportDispatch({
      type: PROFIT_AND_LOSS_REPORT_ACTIONS.updateState,
      key: "isFetchingProfitAndLossReport",
      value: true,
    });
    const profitAndLossReportRefetchResult =
      await getProfitAndLossReport.refetch();
    profitAndLossReportDispatch({
      type: PROFIT_AND_LOSS_REPORT_ACTIONS.updateState,
      key: "isFetchingProfitAndLossReport",
      value: false,
    });
    if (profitAndLossReportRefetchResult.status == "success") {
      let profitAndLossReport = profitAndLossReportRefetchResult.data.data.data;
      profitAndLossReport = profitAndLossReport.map((_) => {
        const order = _.attributes;

        order.transactionID = order.transactionID.split("-")[0];

        //Clean up manual transaction
        if (order._rawTxnType == "manual") {
          const txn = order._rawTxn;
          order.price = `1 ${txn.sellCurrency} / ${formatAmount(
            txn.sellRate
          )} ${txn.buyCurrency}`;
          order.amount = `${formatAmount(txn.sellVolume)} ${txn.buyCurrency}`;
          order.total = `${formatAmount(txn.deliveredVolume)} ${
            txn.sellCurrency
          }`;
          order.date = moment(txn.date).format("DD/MM/YYYY - h:mm A");
        }

        return order;
      });

      profitAndLossReportDispatch({
        type: PROFIT_AND_LOSS_REPORT_ACTIONS.updateState,
        key: "profitAndLossReport",
        value: profitAndLossReport,
      });
    }
  };

  const [profitAndLossReportState, profitAndLossReportDispatch] = useReducer(
    ProfitAndLossReportReducer,
    {
      profitAndLossReport: [],
      isFetchingProfitAndLossReport: false,
      fetchProfitAndLossReport,
      getProfitAndLossReport,
    }
  );

  return (
    <ProfitAndLossReportContext.Provider value={profitAndLossReportState}>
      <ProfitAndLossReportDispatchContext.Provider
        value={profitAndLossReportDispatch}
      >
        {children}
      </ProfitAndLossReportDispatchContext.Provider>
    </ProfitAndLossReportContext.Provider>
  );
};

export const useProfitAndLossReport = () => {
  return useContext(ProfitAndLossReportContext);
};

export const useProfitAndLossReportDispatch = () => {
  return useContext(ProfitAndLossReportDispatchContext);
};

const ProfitAndLossReportReducer = (profitAndLossReportState, action) => {
  switch (action.type) {
    case PROFIT_AND_LOSS_REPORT_ACTIONS.updateState: {
      const newState = { ...profitAndLossReportState };
      newState[action.key] = action.value;
      return newState;
    }
    case PROFIT_AND_LOSS_REPORT_ACTIONS.updateStates: {
      const newState = { ...profitAndLossReportState };
      action.states.forEach(({ key, value }) => {
        newState[key] = value;
      });
      return newState;
    }
    default: {
      throw Error(
        "[ProfitAndLossReportReducer] Unknown action: " + action.type
      );
    }
  }
};
