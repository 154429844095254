import { useState } from "react";
import cn from "classnames";
import Markettrade from "./markettrade/markettrade";
import Otctrade from "./otctrade/otctrade";
import "./trade.scss";
import { useFeatureFlags } from "../../../providers/FeatureFlags/FeatureFlagsProvider";

const TRADE_OPTIONS = {
	MARKET_TRADE: "MARKET_TRADE",
	OTC_TRADE: "OTC_TRADE",
};

const Trade = () => {
	const { isFeatureFlagEnabled } = useFeatureFlags();

	const [tradeState, setTradeState] = useState({
		tradeOption: TRADE_OPTIONS.OTC_TRADE,
	});

	const setTradeOption = (tradeOption) => {
		setTradeState({ ...tradeState, tradeOption });
	};
	return (
		<div id="trade_component">
			<div id="trade_header">
				{isFeatureFlagEnabled("markettrades") && (
					<div
						onClick={() => setTradeOption(TRADE_OPTIONS.MARKET_TRADE)}
						className={cn("trade_option market", { active: tradeState.tradeOption == TRADE_OPTIONS.MARKET_TRADE })}>
						Market
					</div>
				)}
				<div
					onClick={() => setTradeOption(TRADE_OPTIONS.OTC_TRADE)}
					className={cn("trade_option otc", { active: tradeState.tradeOption == TRADE_OPTIONS.OTC_TRADE })}>
					OTC
				</div>
			</div>
			<div id="trade_body">
				{tradeState.tradeOption == TRADE_OPTIONS.MARKET_TRADE && <Markettrade />}
				{tradeState.tradeOption == TRADE_OPTIONS.OTC_TRADE && <Otctrade />}
			</div>
		</div>
	);
};
export default Trade;
