import { createContext, useContext, useReducer } from "react";

const CreateRequestContext = createContext();
const CreateRequestDispatchContext = createContext();

export const CREATE_REQUEST_PAGES = {
	CONFIRM_CREATE_REQUEST: "CONFIRM_CREATE_REQUEST",
	CREATE_REQUEST_CONFIRMED: "CREATE_REQUEST_CONFIRMED",
	CONFIGURE_PAYMENT_METHODS: "CONFIGURE_PAYMENT_METHODS",
	ADD_PAYMENT_METHOD: "ADD_PAYMENT_METHOD",
	ADD_PAYMENT_METHOD_SUCCESS: "ADD_PAYMENT_METHOD_SUCCESS",
};

export const CREATE_REQUEST_ACTIONS = {
	goToConfirmCreateRequest: "goToConfirmCreateRequest",
	goToAddPaymentMethod: "goToAddPaymentMethod",
	goToConfigurePaymentMethods: "goToConfigurePaymentMethods",
	goToCreateRequestConfirmed: "goToCreateRequestConfirmed",
	setCreateReqeustPopoverRef: "setCreateReqeustPopoverRef",
	updateState: "updateState",
};

const emptyCreateRequestState = {
	page: CREATE_REQUEST_PAGES.CONFIGURE_PAYMENT_METHODS,
	addPaymentMethodSuccessNextRequestPage: "",
	order : {},
	request: {
		desiredPurchaseAmount: "",
		windowPaymentAmount: "",
		windowPaymentType: "",
		paymentMethods: [],
		depositAmounts: [],
	},
	createReqeustPopoverRef: null,
};

export const CreateRequestProvider = ({ children }) => {
	const [createRequestState, createRequestDispatch] = useReducer(createRequestReducer, emptyCreateRequestState);

	return (
		<CreateRequestContext.Provider value={createRequestState}>
			<CreateRequestDispatchContext.Provider value={createRequestDispatch}>{children}</CreateRequestDispatchContext.Provider>
		</CreateRequestContext.Provider>
	);
};

export const useCreateRequest = () => {
	return useContext(CreateRequestContext);
};

export const useCreateRequestDispatch = () => {
	return useContext(CreateRequestDispatchContext);
};

const createRequestReducer = (createRequestState, action) => {
	switch (action.type) {
		case CREATE_REQUEST_ACTIONS.goToAddPaymentMethod:
			return { ...createRequestState, page: CREATE_REQUEST_PAGES.ADD_PAYMENT_METHOD };
		case CREATE_REQUEST_ACTIONS.goToConfigurePaymentMethods:
			return { ...createRequestState, page: CREATE_REQUEST_PAGES.CONFIGURE_PAYMENT_METHODS };
		case "goToAddPaymentMethodSuccess":
			return { ...createRequestState, page: CREATE_REQUEST_PAGES.ADD_PAYMENT_METHOD_SUCCESS, addPaymentMethodSuccessNextRequestPage: action.addPaymentMethodSuccessNextRequestPage };
		case CREATE_REQUEST_ACTIONS.goToConfirmCreateRequest:
			return { ...createRequestState, page: CREATE_REQUEST_PAGES.CONFIRM_CREATE_REQUEST };
		case CREATE_REQUEST_ACTIONS.goToCreateRequestConfirmed:
			return { ...createRequestState, page: CREATE_REQUEST_PAGES.CREATE_REQUEST_CONFIRMED };
		case CREATE_REQUEST_ACTIONS.setCreateReqeustPopoverRef:
			return { ...createRequestState, createReqeustPopoverRef: action.createReqeustPopoverRef };
		case "goToConfigurePaymentMethods":
			return { ...createRequestState, page: CREATE_REQUEST_PAGES.CONFIGURE_PAYMENT_METHODS };
		case "clearState":
			return { ...createRequestState, ...emptyCreateRequestState };
		case "updateState":
		const newCreateRequestState = { ...createRequestState };
		newCreateRequestState[action.key] = action.value;
		return newCreateRequestState;
		
		default: {
			throw Error("[createRequestReducer] Unknown action: " + action.type);
		}
	}
};
