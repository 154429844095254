import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./AuthProvider";
import { ProfileProvider } from "./ProfileProvider";
import { ChatProvider } from "../workspace/dealrooms/providers/ChatProvider";
import { RatesProvider } from "./Rates/RatesProvider";
import { TransactProvider } from "../workspace/transact/providers/TransactProvider";
import { WalletsProvider } from "./WalletsProvider";
import { OtctradesProvider } from "./OtctradesProvider";
import { PaymentMethodsProvider } from "./PaymentMethodsProvider";
import { MarketplaceProvider } from "../workspace/marketplace/providers/MarketplaceProvider";
import { TransactionDetailsProvider } from "../workspace/transactionDetails/providers/transactionDetailsProvider";
import { CreateRequestProvider } from "../workspace/transactionDetails/createRequest/providers/createRequestProvider";
import { RequestsProvider } from "./RequestsProvider";
import { SwapsReportProvider } from "../workspace/reports/transactionsHistory/swaps/providers/swapsReportProvider";
import ReportsProvider from "../workspace/reports/reportsProvider";
import { FeatureFlagsProvider } from "./FeatureFlags/FeatureFlagsProvider";
import { MyOrdersProvider } from "../workspace/myactivity/myOrders/providers/myOrdersProvider";
const queryClient = new QueryClient();

const SystemProviders = ({ children }) => {
	return (
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<FeatureFlagsProvider>
					<RatesProvider>
						<WalletsProvider>
							<OtctradesProvider>
								<ProfileProvider>
									<MarketplaceProvider>
										<TransactionDetailsProvider>
											<PaymentMethodsProvider>
												<CreateRequestProvider>
													<RequestsProvider>
														<TransactProvider>
															<ChatProvider>
																<ReportsProvider>
																	<MyOrdersProvider>{children}</MyOrdersProvider>
																</ReportsProvider>
															</ChatProvider>
														</TransactProvider>
													</RequestsProvider>
												</CreateRequestProvider>
											</PaymentMethodsProvider>
										</TransactionDetailsProvider>
									</MarketplaceProvider>
								</ProfileProvider>
							</OtctradesProvider>
						</WalletsProvider>
					</RatesProvider>
				</FeatureFlagsProvider>
			</AuthProvider>
		</QueryClientProvider>
	);
};

export default SystemProviders;
