import "./marketplace.scss";
import dashboard_trade_icon from "../../assets/dashboard_trade_icon.svg";
import dashboard_deposit_icon from "../../assets/dashboard_deposit_icon.svg";
import dashboard_withdraw_icon from "../../assets/dashboard_withdraw_icon.svg";
import Transact from "../transact/transact";
import { useTransactDispatch } from "../transact/providers/TransactProvider";
import { useEffect, useState } from "react";
import { useRates } from "../../providers/Rates/RatesProvider";
import ReactSelect from "react-select";
import { Loader2, RefreshCw } from "lucide-react";
import { useMarketplace } from "./providers/MarketplaceProvider";
import Big from "big.js";
import { useNavigate } from "react-router-dom";
import { useTransactionDetailsDispatch } from "../transactionDetails/providers/transactionDetailsProvider";
import moment from "moment/moment";
import { getProcessedOrders } from "../../common/getProcessedOrders";
import { useFeatureFlags } from "../../providers/FeatureFlags/FeatureFlagsProvider";
import _ from "lodash";
import cn from "classnames";

const reactSelectStyleMarketplace = {
	control: (baseStyles, state) => ({
		...baseStyles,
		fontSize: ".9rem",
		fontWeight: "600",
		padding: ".2rem",
		borderRadius: "8px",
		width: "9rem",
		color: "#172c50",
		backgroundColor: "#ffffff",
		borderColor: "#3E9CF3",
	}),
};

const Marketplace = () => {
	const { isFeatureFlagEnabled } = useFeatureFlags();
	const [marketplaceState, setMarketplaceState] = useState({
		currencyOptions: [],
		buyCurrencyFilter: [],
		sellCurrencyFilter: [],
	});

	const { currencies } = useRates();
	const { marketplaceOrders, isFetchingMarketplaceOrders, reloadMarketPlaceOrders } = useMarketplace();
	const transactDispatch = useTransactDispatch();
	const transactionDetailsDispatch = useTransactionDetailsDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		const newMarketplaceState = { ...marketplaceState };
		// build currency options
		const currencyOptions = currencies.map((currency) => ({ value: currency.code, label: currency.code }));
		newMarketplaceState.currencyOptions = currencyOptions;

		setMarketplaceState(newMarketplaceState);
	}, []);

	const processedOrders = () => {
		const orders = getProcessedOrders({ orders: marketplaceOrders });

		const buyCurrencyFilter = marketplaceState.buyCurrencyFilter;
		const sellCurrencyFilter = marketplaceState.sellCurrencyFilter;
		let filterMode = "NONE";
		if (buyCurrencyFilter.length > 0 && sellCurrencyFilter.length > 0) {
			filterMode = "BOTH";
		} else if (buyCurrencyFilter.length > 0) {
			filterMode = "BUY";
		} else if (sellCurrencyFilter.length > 0) {
			filterMode = "SELL";
		}

		const filteredOrders = orders.filter((order) => {
			let showOrder = true;
			const buyCurrency = order.pair.split("-")[0].trim();
			const sellCurrency = order.pair.split("-")[1].trim();

			switch (filterMode) {
				case "BUY":
					showOrder = buyCurrencyFilter.includes(buyCurrency);
					break;
				case "SELL":
					showOrder = sellCurrencyFilter.includes(sellCurrency);
					break;
				case "BOTH":
					showOrder = buyCurrencyFilter.includes(buyCurrency) && sellCurrencyFilter.includes(sellCurrency);
					break;
				case "NONE":
				default:
					showOrder = true;
					break;
			}

			return showOrder;
		});

		return filteredOrders;
	};

	const viewOrder = ({ order }) => {
		transactionDetailsDispatch({ type: "open", order, back: "/marketplace" });
		navigate(`/transactiondetails/${order.id}`);
	};

	const buyCurrencyFilterChanged = (evt) => {
		let buyCurrencyFilter = [];
		if (!_.isEmpty(evt)) {
			const currency = evt.value;
			buyCurrencyFilter = [`${currency}`];
		}

		const newMarketplaceState = { ...marketplaceState };
		newMarketplaceState.buyCurrencyFilter = buyCurrencyFilter;
		setMarketplaceState(newMarketplaceState);
	};

	const sellCurrencyFilterChanged = (evt) => {
		let sellCurrencyFilter = [];
		if (!_.isEmpty(evt)) {
			const currency = evt.value;
			sellCurrencyFilter = [`${currency}`];
		}

		const newMarketplaceState = { ...marketplaceState };
		newMarketplaceState.sellCurrencyFilter = sellCurrencyFilter;
		setMarketplaceState(newMarketplaceState);
	};

	return (
		<div id="marketplace_page">
			<div className="heading">
				<div className="title">Marketplace</div>
				<RefreshCw onClick={reloadMarketPlaceOrders} className={cn("refresh_icon", { rotating: isFetchingMarketplaceOrders })} />
			</div>
			<div className="trading_pairs_and_controls">
				<div className="lhs">
					<div className="trading_pair">
						<div className="label">Pick the trading pair</div>
						<div className="inputs">
							<ReactSelect
								styles={reactSelectStyleMarketplace}
								options={marketplaceState.currencyOptions}
								onChange={buyCurrencyFilterChanged}
								isClearable={true}></ReactSelect>
							<ReactSelect
								styles={reactSelectStyleMarketplace}
								options={marketplaceState.currencyOptions}
								onChange={sellCurrencyFilterChanged}
								isClearable={true}></ReactSelect>
						</div>
					</div>
				</div>
				<div className="rhs">
					<div className="controls">
						{/* Transact Buttons */}
						<button
							onClick={() => {
								transactDispatch({ type: "transact" });
							}}
							className="control trade"
							popovertarget="transact_popover"
							popovertargetaction="show">
							<div className="control_icon">
								<img src={dashboard_trade_icon} alt="trade icon" />
							</div>
							<div className="control_title">TRADE</div>
						</button>
						{isFeatureFlagEnabled("deposits") && (
							<button className="control deposit">
								<div className="control_icon">
									<img src={dashboard_deposit_icon} alt="deposit icon" />
								</div>
								<div className="control_title">DEPOSIT</div>
							</button>
						)}
						{isFeatureFlagEnabled("withdrawals") && (
							<button className="control withdraw">
								<div className="control_icon">
									<img src={dashboard_withdraw_icon} alt="withdraw icon" />
								</div>
								<div className="control_title">WITHDRAW</div>
							</button>
						)}
						<div id="transact_popover" popover="auto">
							<Transact />
						</div>
					</div>
				</div>
			</div>

			{isFetchingMarketplaceOrders && (
				<div className="marketplace_orders_loader_container">
					Loading marketplace orders...
					<Loader2 className="marketplace_orders_loader" />
				</div>
			)}

			{!isFetchingMarketplaceOrders && (
				<table className="marketplace_orders_table">
					<thead>
						<tr>
							<th>
								Pair <span className="help_text">( Buy - Sell )</span>
							</th>
							<th>Price</th>
							<th>Amount</th>
							<th>Username</th>
							<th>Expiry</th>
							<th>Total volume (USD)</th>
						</tr>
					</thead>
					<tbody>
						{processedOrders().map((order) => (
							<tr
								className="marketplace_order"
								key={order.id}
								onClick={() => {
									viewOrder({ order });
								}}>
								<td>{order.pair}</td>
								<td>{order.price}</td>
								<td>{order.balance}</td>
								<td>{order.username}</td>
								<td>{order.expiry}</td>
								<td>{order.totalVolume}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
export default Marketplace;
