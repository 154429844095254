import { useMutation } from "@tanstack/react-query";
import { CREATE_REQUEST_ACTIONS, useCreateRequest, useCreateRequestDispatch } from "../providers/createRequestProvider";
import "./confirmCreateRequest.scss";
import { useAuth } from "../../../../providers/AuthProvider";
import api from "../../../../api";
import { useNavigate, useParams } from "react-router-dom";
import { Loader2 } from "lucide-react";
const ConfirmCreateRequest = () => {
	const { authTokens } = useAuth();
	const params = useParams();
	const createRequestDispatch = useCreateRequestDispatch();
	const { request, order } = useCreateRequest();
	const navigate = useNavigate();

	const goToConfigurePaymentMethods = () => {
		createRequestDispatch({
			type: CREATE_REQUEST_ACTIONS.goToConfigurePaymentMethods,
		});
	};

	const createRequest = useMutation({
		mutationFn: ({ request, orderId, token }) => api.createRequest({ request, orderId, token }),
		onError: () => {},
		onSuccess: (data, variables, context) => {
			console.log({ data, variables, context });
			const orderRequestId = data.data.data.attributes.id;
			createRequestDispatch({
				type: CREATE_REQUEST_ACTIONS.goToCreateRequestConfirmed,
			});
			navigate(`/transactiondetails/request/${orderRequestId}`);
		},
	});
	const formatAmounts = (amount) => {
		const locale = Intl.NumberFormat().resolvedOptions().locale;
		let formatedAmount = parseFloat(amount);
		formatedAmount = `${formatedAmount.toLocaleString(locale)}`;
		return formatedAmount;
	};

	const onCreateRequest = () => {
		const orderId = order.id;
		createRequest.mutate({
			request,
			orderId,
			token: authTokens.IdToken,
		});
	};

	return (
		<div id="createrequest_component">
			<div className="createrequest_controls">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="createrequest_controls_back"
					onClick={goToConfigurePaymentMethods}
					width="20"
					height="20"
					viewBox="0 0 24 24"
					fill="none">
					<path d="M16 4L8 12L16 20" stroke="#172C50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			</div>
			<div className="createrequest_header">
				<p>CREATE REQUEST</p>
			</div>
			<div className="createrequest_summary">
				<div className="confirm_summary">
					<div className="confirm_header">Confirmation Page</div>
					<div className="confirm_info">
						<div className="confirm_sell">
							<div className="left_label">Currency pair</div>
							<div className="right_label">{order.pair}</div>
						</div>
						<div className="confirm_buy">
							<div className="left_label">Price</div>
							<div className="right_label">{order.price}</div>
						</div>
						<div className="confirm_exchange_rate">
							<div className="left_label">Currency Rate</div>
							<div>
								<div className="right_label">{order.buyPrice}</div>
								<div className="right_label">{order.sellPrice}</div>
							</div>
						</div>
						<div className="info_section">
							<div className="left_label">Amount</div>
							<div className="right_label">{order.amount}</div>
						</div>
						<div className="info_section">
							<div className="left_label">Purchase Amount</div>
							<div className="right_labels">
								<div className="right_label">
									{request.desiredPurchaseAmount} {order?.pair?.split(" - ")[0]}
								</div>
								<div className="right_label">
									{
										// Calculate the equivalent amount in the second currency
										(isNaN(parseFloat(order.price))
											? request.desiredPurchaseAmount / order.price.split(".")[0]
											: request.desiredPurchaseAmount / parseFloat(order.price)
										).toFixed(2)
									}{" "}
									{order?.pair?.split(" - ")[1]}
								</div>
							</div>
						</div>

						<div className="info_section">
							<div className="left_label">Username</div>
							<div className="right_label">{order.username}</div>
						</div>
						<div className="info_section">
							<div className="left_label">Account holder name</div>
							{order.paymentMethods.map((method, index) => (
								<div key={index}>
									<div className="right_label">{method.accountName ? method.accountName : ""}</div>
								</div>
							))}
						</div>
						<div className="payment_method_info">
							<div className="payment_method_label">Payment method</div>
							{!order.depositAmounts || order.depositAmounts.length === 0
								? order.paymentMethods.map((paymentMethod) => (
										<div className="payment_method" key={paymentMethod.id}>
											<div className="name">{paymentMethod.type === "BANK_ACCOUNT" ? paymentMethod.bankName : paymentMethod.phonenumberLabel}</div>
											<div className="details"> {order.amount} </div>
										</div>
								  ))
								: order.depositAmounts.map((deposit) => (
										<div className="payment_method" key={deposit.paymentMethod.id}>
											<div className="name">
												{deposit.paymentMethod.type === "BANK_ACCOUNT"
													? deposit.paymentMethod.bankName
													: deposit.paymentMethod.phonenumberLabel || deposit.paymentMethod.label}
											</div>
											<div className="details">
												{deposit.instruction} {deposit.paymentMethod.value.currency}
											</div>
										</div>
								  ))}
						</div>
						<div className="info_section">
							<div className="left_label">Order ID</div>
							<div className="right_label">{order.id}</div>
						</div>
						<div className="info_section">
							<div className="left_label">Email</div>
							{order.paymentMethods.map((method, index) => (
								<div key={index}>
									<div className="right_label">{method.email ? method.email : ""}</div>
								</div>
							))}
						</div>
						<div className="info_section">
							<div className="left_label">Expiry Date</div>
							<div className="right_label">{order.expiry}</div>
						</div>
						<div className="info_section">
							<div className="left_label">Payment window</div>
							<div className="right_label">
								{request.windowPaymentAmount} {request.windowPaymentType}
							</div>
						</div>
					</div>
					<div className="confirm_info">
						<div className="confirm_order">
							<div className="left_label">Min. Order Volume</div>
							<div className="right_label">
								{order.minOrderVolume} {order?.pair?.split(" - ")[1]}
							</div>
						</div>
						<div className="confirm_pay_window">
							<div className="left_label">Payment Window</div>
							<div className="right_label">
								{order.windowPaymentAmount} {order.windowPaymentType}
							</div>
						</div>
						<div className="confirm_order_window">
							<div className="left_label">Order Window</div>
							<div className="right _label">
								{order.windowOrderAmount} {order.windowOrderType}
							</div>
						</div>
					</div>
					<hr />
					<div className="confirm_info">
						<div className="payment_method_info">
							<div className="payment_method_label">Payment method</div>
							{request.depositAmounts && request.depositAmounts.length > 0
								? request.depositAmounts.map((deposit) => (
										<div className="payment_method" key={deposit.paymentMethod.id}>
											<div className="name">{deposit.paymentMethod.type === "BANK_ACCOUNT" ? deposit.paymentMethod.bankName : deposit.paymentMethod.label}</div>
											<div className="details">
												{deposit.instruction} {deposit.paymentMethod.value.currency}
											</div>
										</div>
								  ))
								: request.paymentMethods.map((method, index) => (
										<div className="payment_method" key={index}>
											<div className="name">{method.type === "BANK_ACCOUNT" ? method.bankName : method.phonenumberLabel || method.phonenumber}</div>
											<div className="details">
												{(isNaN(parseFloat(order.price))
													? request.desiredPurchaseAmount / order.price.split(".")[0]
													: request.desiredPurchaseAmount / parseFloat(order.price)
												).toFixed(2)}
											</div>
										</div>
								  ))}
						</div>
					</div>
				</div>
				<div className="summary">
					{!createRequest.isPending && (
						<div onClick={onCreateRequest} className="create_request">
							Submit
						</div>
					)}

					{createRequest.isPending && (
						<div className="create_request">
							<Loader2 className="create_request_loader" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default ConfirmCreateRequest;
