import React from "react";
import ReactDOM from "react-dom/client";
import "./main.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./login/login.jsx";
import Signup from "./signup/signup.jsx";
import Vpopup from "./scratch/verification_popup/vpopup";
import AccountDetails from "./scratch/accountDetails/accountDetails";
import SettingsGeneral from "./scratch/account_settings_general/account_settings_general";
import SettingsLocation from "./scratch/account_settings_location/account_settings_location";
import CreateAccount from "./scratch/create_company_account/create_company_account";
import AccountVerification from "./scratch/company_acct_verification/acct_verification";
import Dealrooms from "./scratch/dealrooms/dealrooms";
import NewDealroom from "./scratch/new_dealroom/new_dealroom";
import NewDealroomInvite from "./scratch/new_dealroom_invite/new_dealroom_invite";
import ChatWindow from "./scratch/chat_window/chat_window";
import ConfirmEmail from "./confirm_email/confirm_email";
import SystemProviders from "./providers/SystemProviders";
import Workspace from "./workspace/workspace";
import workspaceRoutes from "./workspace/workspaceRoutes";
import NotFound from "./not_found/notFound.jsx";
import ResetPassword from "./resetPassword/resetPassword.jsx";
import StartResetPassword from "./resetPassword/startResetPassword.jsx";
import { initializeSentry } from "./common/Sentry.js";

initializeSentry();

const router = createBrowserRouter([
	{
		path: "/",
		element: <Workspace />,
		errorElement: <Workspace />,
		children: workspaceRoutes,
	},
	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/signup",
		element: <Signup />,
	},
	{
		path: "/confirm/email",
		element: <ConfirmEmail />,
	},
	{
		path: "/reset/password",
		element: <ResetPassword />,
	},
	{
		path: "/start/reset/password",
		element: <StartResetPassword />,
	},
	{
		path: "*",
		element: <NotFound />,
	},
]);

ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<SystemProviders>
			<RouterProvider router={router} />
		</SystemProviders>
	</React.StrictMode>
);
