import "./allRequests.scss";
import { useEffect } from "react";
import { REQUEST_STATES, useRequests } from "../../../../providers/RequestsProvider";
import { useAuth } from "../../../../providers/AuthProvider";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useTransactionDetailsDispatch } from "../../../transactionDetails/providers/transactionDetailsProvider";

const AllRequests = () => {
	const { isLoggedIn } = useAuth();
	const { allRequests, isFetchingAllRequests, fetchAllRequests,} = useRequests();
	const transactionDetailsDispatch = useTransactionDetailsDispatch();
	const navigate = useNavigate();

	//Get received requests
	useEffect(() => {
		(async () => {
			// fetch received if logged in
			if (isLoggedIn) {
				await fetchAllRequests();
			}
		})();
	}, [isLoggedIn]);

	const viewRequest = ({ request }) => {
		transactionDetailsDispatch({ type: "updateState", key: "request", value: request });
		navigate(`/transactiondetails/request/${request.id}`);
	};

	return (
		<div id="all_requests_component">
			{isFetchingAllRequests && (
				<div className="all_requests_loader_container">
					Loading all requests...
					<Loader2 className="all_requests_loader" />
				</div>
			)}

			{!isFetchingAllRequests && (
				<table className="all_requests_table transactions_table">
					<thead>
						<tr>
							<th>
								Pair <span className="help_text">( Buy - Sell )</span>
							</th>
							<th>Price</th>
							<th>Amount</th>
							<th>User</th>
							<th>Date</th>
							<th>Details</th>
						</tr>
					</thead>
					<tbody>
						{allRequests
							.map((request) => (
								<tr key={request.id} onClick={() => viewRequest({ request })}>
									<td>
										{request.order.sellCurrency} - {request.order.buyCurrency}
									</td>
									<td>
										<p>
											1 {request.order.sellCurrency} / {request.processedOrder.price}
										</p>
									</td>
									<td>
										<p>
											{request.order.buyCurrency} {request.desiredPurchaseAmountFormatted}
										</p>
										<p>
											{request.order.sellCurrency} {request.desiredPurchaseAmountConvertedFormatted}
										</p>
									</td>
									<td>
										<p>{request.createdByName}</p>
									</td>
									<td>{request.createdAtFormatted}</td>
									<td>
										<svg
											className="detailsIcon"
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											stroke="currentColor"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
											class="lucide lucide-ellipsis-vertical">
											<circle cx="12" cy="12" r="1" />
											<circle cx="12" cy="5" r="1" />
											<circle cx="12" cy="19" r="1" />
										</svg>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			)}
		</div>
	);
};
export default AllRequests;
