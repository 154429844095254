import AddPaymentMethod from "../../transact/paymentmethods/addpaymentmethod/addpaymentmethod";
import AddPaymentMethodSuccess from "../../transact/paymentmethods/addpaymentmethodsuccess/addpaymentmethodsuccess";
import ConfigurePaymentMethods from "../../transact/paymentmethods/configurepaymentmethods/configurepaymentmethods";
import ConfirmCreateRequest from "./confirmCreateRequest/confirmCreateRequest";
import "./createRequest.scss";
import CreateRequestConfirmed from "./createRequestConfirmed/createRequestConfirmed";
import { CREATE_REQUEST_PAGES, useCreateRequest } from "./providers/createRequestProvider";
const CreateRequest = () => {
	const { page } = useCreateRequest();
	return (
		<div id="create_request_component">
			{page == CREATE_REQUEST_PAGES.CONFIGURE_PAYMENT_METHODS && <ConfigurePaymentMethods />}
			{page == CREATE_REQUEST_PAGES.ADD_PAYMENT_METHOD && <AddPaymentMethod />}
			{page == CREATE_REQUEST_PAGES.ADD_PAYMENT_METHOD_SUCCESS && <AddPaymentMethodSuccess />}
			{page == CREATE_REQUEST_PAGES.CONFIRM_CREATE_REQUEST && <ConfirmCreateRequest />}
			{page == CREATE_REQUEST_PAGES.CREATE_REQUEST_CONFIRMED && <CreateRequestConfirmed />}
		</div>
	);
};
export default CreateRequest;
