import { useEffect } from "react";
import "./volume.scss";
import { Loader2 } from "lucide-react";
import { useVolumeReport } from "./providers/volumeReportProvider";
import { useAuth } from "../../../providers/AuthProvider";
import { useReportFilters } from "../reportsFilterProvider";
import { useNavigate } from "react-router-dom";
import { useTransactionDetailsDispatch } from "../../transactionDetails/providers/transactionDetailsProvider";

const Volume = () => {
	const { isLoggedIn } = useAuth();
	const { fetchVolumeReport, isFetchingVolumeReport, volumeReport } = useVolumeReport();
	const { filters } = useReportFilters();
	const transactionDetailsDispatch = useTransactionDetailsDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		(async () => {
			if (isLoggedIn) {
				await fetchVolumeReport();
			}
		})();
	}, [isLoggedIn]);

	const parseDate = (dateStr) => {
		if (!dateStr || typeof dateStr !== "string" || dateStr === "Invalid date") return null;

		const [datePart, timePart] = dateStr.split(" - ");
		const [day, month, year] = datePart.split("/").map((num) => parseInt(num, 10));
		const [time, period] = timePart.split(" ");
		const [hours, minutes] = time.split(":").map((num) => parseInt(num, 10));

		let hours24 = hours;
		if (period === "PM" && hours !== 12) hours24 = hours + 12;
		if (period === "AM" && hours === 12) hours24 = 0;

		return new Date(year, month - 1, day, hours24, minutes);
	};

	const applyFilters = (orders) => {
		let filteredOrders = orders;

		if (filters.searchQuery) {
			filteredOrders = filteredOrders.filter(
				(order) =>
					order.transactionID.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					order.pair.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					order.amount.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					order.price.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					order.total.toLowerCase().includes(filters.searchQuery.toLowerCase())
			);
		}

		if (filters.startDate && filters.endDate) {
			const start = new Date(filters.startDate);
			const end = new Date(filters.endDate);

			if (!isNaN(start) && !isNaN(end)) {
				filteredOrders = filteredOrders.filter((order) => {
					const orderDate = parseDate(order.date);
					return orderDate && orderDate >= start && orderDate <= end;
				});
			}
		}

		const buyCurrencyFilter = filters.buyCurrency ? [filters.buyCurrency] : [];
		const sellCurrencyFilter = filters.sellCurrency ? [filters.sellCurrency] : [];

		let filterMode = "NONE";
		if (buyCurrencyFilter.length > 0 && sellCurrencyFilter.length > 0) {
			filterMode = "BOTH";
		} else if (buyCurrencyFilter.length > 0) {
			filterMode = "BUY";
		} else if (sellCurrencyFilter.length > 0) {
			filterMode = "SELL";
		}

		filteredOrders = filteredOrders.filter((order) => {
			let showOrder = true;
			const [orderBuyCurrency, orderSellCurrency] = order.pair.split("-").map((currency) => currency.trim());

			switch (filterMode) {
				case "BUY":
					showOrder = buyCurrencyFilter.includes(orderBuyCurrency);
					break;
				case "SELL":
					showOrder = sellCurrencyFilter.includes(orderSellCurrency);
					break;
				case "BOTH":
					showOrder = buyCurrencyFilter.includes(orderBuyCurrency) && sellCurrencyFilter.includes(orderSellCurrency);
					break;
				case "NONE":
				default:
					showOrder = true;
					break;
			}

			return showOrder;
		});

		return filteredOrders;
	};

	const filteredVolumeReport = applyFilters(volumeReport.orders || []);

	const viewTxn = ({ txn }) => {
		const id = txn._rawTxn.id;
		switch (txn._rawTxnType) {
			case "request":
				//clear state
				transactionDetailsDispatch({
					type: "updateState",
					key: "request",
					value: {},
				});
				navigate(`/transactiondetails/request/${id}`);
				break;
			case "otctrade":
				//clear state
				transactionDetailsDispatch({
					type: "updateState",
					key: "order",
					value: {},
				});
				navigate(`/transactiondetails/${id}`);
				break;
		}
	};

	return (
		<div className="volume_report_body">
			{isFetchingVolumeReport && (
				<div className="volume_report_loader_container">
					Loading volume report...
					<Loader2 className="volume_report_loader" />
				</div>
			)}

			{!isFetchingVolumeReport && (
				<table className="report_table volume_report_table">
					<thead>
						<tr>
							<th>Transaction ID</th>
							<th>
								Pair <span className="help_text">( Buy - Sell )</span>
							</th>
							<th>Price</th>
							<th>Amount</th>
							<th>Total</th>
							<th>Date</th>
						</tr>
					</thead>
					<tbody>
						{filteredVolumeReport.map((order) => (
							<tr key={order.transactionID} onClick={() => viewTxn({ txn: order })}>
								<td>
									{order.transactionID}
									<br />
									<span className="help_text">( {order._rawTxnType} )</span>
								</td>
								<td>{order.pair}</td>
								<td>{order.price}</td>
								<td>{order.amount}</td>
								<td>{order.total}</td>
								<td>{order.date}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};

export default Volume;
