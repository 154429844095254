import { Loader2, Search, User } from "lucide-react";
import _ from "lodash";
import "./threads.scss";
import React, { useEffect, useState } from "react";
import { useChat, useChatDispatch } from "../providers/ChatProvider";
import { useProfile } from "../../../providers/ProfileProvider";
const Threads = () => {
  const [threadsState, setThreadsState] = useState({
    filterTerm: "",
    filteredThreads: []
  });
  const { selectedThread, isFetchingThreads, threads, getThreads } = useChat();
  const chatDispatch = useChatDispatch();
  const { profile: myProfile } = useProfile();

  useEffect(() => {
    let filteredThreads = threads;
    if (!_.isEmpty(threadsState.filterTerm)) {
      filteredThreads = threads.filter((thread) => thread.name.toLowerCase().includes(threadsState.filterTerm.toLowerCase()));
    }
    setThreadsState({ ...threadsState, filteredThreads });
  }, [threads, threadsState.filterTerm]);

  const onThreadSelected = async (thread) => {
    chatDispatch({ type: "setSelectedThread", thread });
  };

  const onFilterThreads = (evt) => {
    setThreadsState({ ...threadsState, filterTerm: evt.target.value });
  };

  const getThreadName = (thread) => {
    let name = thread.name;
    if (thread.type == "individual") {
      const tokens = name.split("<>");
      name = thread.createdBy == myProfile.id ? tokens[1] : tokens[0];
    }
    return name;
  };

  return (
    <div className="thread">
      <div id="chat_threads_search">
        <Search />
        <input type="text" placeholder="Search" onChange={onFilterThreads} />
      </div>

      {isFetchingThreads && (
        <div className="threads_loader_container">
          <Loader2 className="threads_loader" />
        </div>
      )}

      {!isFetchingThreads && (
        <div className="chat_threads_list">
          {threadsState.filteredThreads &&
            threadsState.filteredThreads.map((thread) => (
              <React.Fragment key={"thread_" + thread.id}>
                <div className={`${thread.id === selectedThread.id ? "chat_thread active" : "chat_thread"}`} onClick={() => onThreadSelected(thread)}>
                  {thread.type.toLowerCase() == "individual" && (
                    <div className="icon_individual">
                      <User />
                    </div>
                  )}

                  {thread.type.toLowerCase() == "group" && (
                    <div className="icon_group">
                      <div className="icon bottom">
                        <User />
                      </div>
                      <div className="icon top">
                        <User />
                      </div>
                    </div>
                  )}

                  <div className="info">
                    <div className="heading">
                      <div className="name">{getThreadName(thread)}</div>
                      <div className="notification_badge">{thread.participants.length}</div>
                    </div>
                    <div className="status">John is typing</div>
                  </div>
                </div>
                <div className="seperator"></div>
              </React.Fragment>
            ))}
        </div>
      )}
    </div>
  );
};

export default Threads;
