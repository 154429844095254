import "./dashboard.scss";
import dashboard_copy_icon from "../../assets/dashboard_copy_icon.svg";
import dashboard_trade_icon from "../../assets/dashboard_trade_icon.svg";
import dashboard_deposit_icon from "../../assets/dashboard_deposit_icon.svg";
import dashboard_withdraw_icon from "../../assets/dashboard_withdraw_icon.svg";
import Transact from "../transact/transact";
import { useTransactDispatch } from "../transact/providers/TransactProvider";
import { useState } from "react";
import Openorders from "./openorders/openorders";
import { useFeatureFlags } from "../../providers/FeatureFlags/FeatureFlagsProvider";
const Dashboard = () => {
	const { isFeatureFlagEnabled } = useFeatureFlags();
	const [dashboardState, setDashboardState] = useState({
		viewOpenOrders: true,
		viewRequests: false,
	});
	const transactDispatch = useTransactDispatch();
	return (
		<div className="dashboard_page">
			<div className="heading">
				<div className="title">Dashboard</div>
			</div>
			<div className="wallets_and_controls">
				<div className="lhs">
					{isFeatureFlagEnabled("wallets") && (
						<>
							<div className="wallets_header">Wallets</div>
							<div className="balance_container">
								<div className="balance_amount">
									$ 1,003.<span className="decimal">61</span>
								</div>
								<div className="balanace_title">Total balance</div>
							</div>
						</>
					)}
				</div>
				<div className="rhs">
					<div className="controls">
						{isFeatureFlagEnabled("wallets") && <div className="view_all_wallets">View all wallets</div>}
						{/* <div className="copy">
							<img src={dashboard_copy_icon} alt="copy icon" />
						</div> */}
						{/* Transact Buttons */}
						<button
							onClick={() => {
								transactDispatch({ type: "transact" });
							}}
							className="control trade"
							popovertarget="transact_popover"
							popovertargetaction="show">
							<div className="control_icon">
								<img src={dashboard_trade_icon} alt="trade icon" />
							</div>
							<div className="control_title">TRADE</div>
						</button>

						{isFeatureFlagEnabled("deposits") && (
							<button className="control deposit">
								<div className="control_icon">
									<img src={dashboard_deposit_icon} alt="deposit icon" />
								</div>
								<div className="control_title">DEPOSIT</div>
							</button>
						)}

						{isFeatureFlagEnabled("withdrawals") && (
							<button className="control withdraw">
								<div className="control_icon">
									<img src={dashboard_withdraw_icon} alt="withdraw icon" />
								</div>
								<div className="control_title">WITHDRAW</div>
							</button>
						)}

						<div id="transact_popover" popover="auto">
							<Transact />
						</div>
					</div>

					{isFeatureFlagEnabled("wallets") && (
						<div className="cards">
							<div className="card tether">
								<div className="info">
									<div className="heading">Tether</div>
									<div className="sub_heading">
										$ 14<span className="decimal">.63</span>
									</div>
									<div className="sub_sub_heading">USD 14.62</div>
								</div>
								<div className="icon"></div>
							</div>
							<div className="card">
								<div className="info">
									<div className="heading">Ethereum</div>
									<div className="sub_heading">
										0<span className="decimal">.0600 ETH</span>
									</div>
									<div className="sub_sub_heading">USD 100.08</div>
								</div>
								<div className="icon"></div>
							</div>
							<div className="card">
								<div className="info">
									<div className="heading">Bitcoin</div>
									<div className="sub_heading">
										0<span className="decimal">.0097 BTC</span>
									</div>
									<div className="sub_sub_heading">USD 256.39</div>
								</div>
								<div className="icon"></div>
							</div>
							<div className="card_control">+</div>
						</div>
					)}
				</div>
			</div>
			<div className="order_and_requests">
				<div className="controls">
					<div className="control active open_orders"> Open orders </div>
					<div className="control requests"> Requests </div>
					<div className="view_all">View all</div>
				</div>
				<div className="tables">
					<Openorders />
				</div>
			</div>
		</div>
	);
};
export default Dashboard;
