import { useEffect, useRef } from "react";
import { REQUESTS_ACTIONS, REQUEST_STATES, useRequests, useRequestsDispatch } from "../../../providers/RequestsProvider";
import "./allRequests.scss";
import { useAuth } from "../../../providers/AuthProvider";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useTransactionDetailsDispatch, useTransactionDetails } from "../../transactionDetails/providers/transactionDetailsProvider";

const AllRequests = () => {
	const { isLoggedIn, authTokens } = useAuth();
	const { allRequests, isFetchingAllRequests, fetchAllRequests, isAcceptingRequest, acceptRequest, rejectRequest } = useRequests();
	const requestsDispatch = useRequestsDispatch();
    const { order } = useTransactionDetails();
	const acceptRequestDialogRef = useRef(null);
	const rejectRequestDialogRef = useRef(null);
	const transactionDetailsDispatch = useTransactionDetailsDispatch();
	const navigate = useNavigate();

	console.log(order, allRequests)
	//Get received requests
	useEffect(() => {
		(async () => {
			// fetch received if logged in
			if (isLoggedIn) {
				await fetchAllRequests();
			}
		})();
	}, [isLoggedIn]);

	const startAcceptRequest = () => {
		requestsDispatch({ type: REQUESTS_ACTIONS.updateState, key: "isAcceptingRequest", value: false });
		acceptRequestDialogRef.current.showModal();
	};

	const confirmAcceptRequest = ({ request }) => {
		(async () => {
			const token = authTokens.IdToken;
			await acceptRequest({ request, token });
			acceptRequestDialogRef.current.close();
		})();
	};
	const viewRequest = ({ request }) => {
		transactionDetailsDispatch({ type: "updateState", key: "request", value: request });
		navigate(`/transactiondetails/request/${request.id}`);
	};

	const cancelAcceptRequest = () => {
		acceptRequestDialogRef.current.close();
	};

	const startRejectRequest = () => {
		rejectRequestDialogRef.current.showModal();
	};

	const confirmRejectRequest = ({request}) => {(async () => {
		const token = authTokens.IdToken;
		await rejectRequest({ request, token });
		rejectRequestDialogRef.current.close();
	})();};

	const cancelRejectRequest = () => {
		rejectRequestDialogRef.current.close();
	};

	return (
		<div id="all_requests_component">
			{isFetchingAllRequests && (
				<div className="all_requests_loader_container">
					Loading requests...
					<Loader2 className="all_requests_loader" />
				</div>
			)}

			{!isFetchingAllRequests && (
				<table className="all_requests_table transactions_table">
					<thead>
						<tr>
							<th>
								Pair <span className="help_text">( Buy - Sell )</span>
							</th>
							<th>Price</th>
							<th>Amount</th>
							<th>User</th>
							<th>Date</th>
							<th>Actions</th>
							<th>Details</th>
						</tr>
					</thead>
					<tbody>
						{allRequests
							.filter((request) => request.order.id === order.id && request.requestState === "CREATED") //GET PENDING REQUESTS
							.map((request) => (
								<tr key={request.id}>
									<td onClick={() => viewRequest({ request })}>
										{request.order.sellCurrency} - {request.order.buyCurrency}
									</td>
									<td onClick={() => viewRequest({ request })}>
										<p>
											1 {request.order.sellCurrency} / {request.processedOrder.price}
										</p>
									</td>
									<td onClick={() => viewRequest({ request })}>
										<p>
											{request.order.buyCurrency} {request.desiredPurchaseAmountFormatted}
										</p>
										<p>
											{request.order.sellCurrency} {request.desiredPurchaseAmountConvertedFormatted}
										</p>
									</td>
									<td onClick={() => viewRequest({ request })}>
										<p>{request.createdByName}</p>
									</td>
									<td onClick={() => viewRequest({ request })}>{request.createdAtFormatted}</td>
									<td>
										{request.requestState === REQUEST_STATES.CREATED && (
											<div className="request_actions">
												<div className="action accept" onClick={startAcceptRequest}>
													Accept
												</div>
												<dialog className="accept_request_dialog_container" ref={acceptRequestDialogRef}>
													<div className="accept_request_dialog_heading">Are you sure you want to accept this request?</div>
													<div className="accept_request_dialog_controls">
														{isAcceptingRequest && (
															<div className="all_requests_loader_container">
																Accepting request ...
																<Loader2 className="all_requests_loader" />
															</div>
														)}

														{!isAcceptingRequest && (
															<>
																<div onClick={() => confirmAcceptRequest({ request })} className="accept_request_dialog_control accept_request_dialog_confirm">
																	Yes
																</div>
																<div onClick={cancelAcceptRequest} className="accept_request_dialog_control accept_request_dialog_cancel">
																	No
																</div>
															</>
														)}
													</div>
												</dialog>

												<div className="action reject" onClick={startRejectRequest}>
													Reject
												</div>
												<dialog className="accept_request_dialog_container" ref={rejectRequestDialogRef}>
													<div className="accept_request_dialog_heading">Are you sure you want to reject this request?</div>
													<div className="accept_request_dialog_controls">
														<div onClick={() => confirmRejectRequest({ request })} className="accept_request_dialog_control accept_request_dialog_confirm">
															Yes
														</div>
														<div onClick={cancelRejectRequest} className="accept_request_dialog_control accept_request_dialog_cancel">
															No
														</div>
													</div>
												</dialog>
											</div>
										)}
									</td>
									<td>
										<svg
											className="detailsIcon"
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											stroke="currentColor"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
										>
											<circle cx="12" cy="12" r="1" />
											<circle cx="12" cy="5" r="1" />
											<circle cx="12" cy="19" r="1" />
										</svg>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			)}
		</div>
	);
};

export default AllRequests;