import React from 'react';
import './notFound.scss';
import logo from "../assets/logo.svg"

const NotFound = () => {
  return (
    <div className="not_found_page">
        <div className="top-bar">
				<div className="logo">
					<img src={logo} alt="" />
				</div>
			</div>
        <div className="not_found_body_container">
            <div className="not_found_body">
                <div className="not_found_heading">
                404 - Page Not Found
                </div>
                <div className="not_found_subheading">
                Sorry, the page you are looking for does not exist.
                </div>
            </div>
        </div>
      
    </div>
  );
};

export default NotFound;