import { useEffect } from "react";
import "./openOrders.scss";
import { Loader2 } from "lucide-react";
import { useOpenOrdersReport } from "./providers/openOrdersReportProvider";
import { useAuth } from "../../../../providers/AuthProvider";
import { useReportFilters } from "../../reportsFilterProvider";
import { useTransactionDetailsDispatch } from "../../../transactionDetails/providers/transactionDetailsProvider";
import { useNavigate } from "react-router-dom";

const OpenOrders = () => {
	const { isLoggedIn } = useAuth();
	const { fetchOpenOrdersReport, isFetchingOpenOrdersReport, openOrdersReport } = useOpenOrdersReport();
	const { filters } = useReportFilters();
	const transactionDetailsDispatch = useTransactionDetailsDispatch();
	const navigate = useNavigate();

	//Get openOrders report
	useEffect(() => {
		(async () => {
			// fetch openOrders report if logged in
			if (isLoggedIn) {
				await fetchOpenOrdersReport();
			}
		})();
	}, [isLoggedIn]);

	const parseDate = (dateStr) => {
		if (!dateStr || typeof dateStr !== "string" || dateStr == "Invalid date") return null;

		const [datePart, timePart] = dateStr.split(" - ");
		const [day, month, year] = datePart.split("/").map((num) => parseInt(num, 10));
		const [time, period] = timePart.split(" ");
		const [hours, minutes] = time.split(":").map((num) => parseInt(num, 10));

		let hours24 = hours;
		if (period === "PM" && hours !== 12) hours24 = hours + 12;
		if (period === "AM" && hours === 12) hours24 = 0;

		return new Date(year, month - 1, day, hours24, minutes);
	};

	const applyFilters = (openOrders) => {
		let filteredOpenOrders = openOrders;

		if (filters.searchQuery) {
			filteredOpenOrders = filteredOpenOrders.filter(
				(openOrder) =>
					openOrder.transactionID.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					openOrder.pair.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					openOrder.counterpartyName.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					openOrder.amount.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					openOrder.price.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					openOrder.total.toLowerCase().includes(filters.searchQuery.toLowerCase())
			);
		}

		if (filters.startDate && filters.endDate) {
			const start = new Date(filters.startDate);
			const end = new Date(filters.endDate);

			if (!isNaN(start) && !isNaN(end)) {
				filteredOpenOrders = filteredOpenOrders.filter((openOrder) => {
					const orderDate = parseDate(openOrder.date);
					return orderDate && orderDate >= start && orderDate <= end;
				});
			}
		}

		// Define filter mode based on buyCurrency and sellCurrency
		const buyCurrencyFilter = filters.buyCurrency ? [filters.buyCurrency] : [];
		const sellCurrencyFilter = filters.sellCurrency ? [filters.sellCurrency] : [];

		let filterMode = "NONE";
		if (buyCurrencyFilter.length > 0 && sellCurrencyFilter.length > 0) {
			filterMode = "BOTH";
		} else if (buyCurrencyFilter.length > 0) {
			filterMode = "BUY";
		} else if (sellCurrencyFilter.length > 0) {
			filterMode = "SELL";
		}

		filteredOpenOrders = filteredOpenOrders.filter((openOrder) => {
			let showOpenOrder = true;
			const [orderBuyCurrency, orderSellCurrency] = openOrder.pair.split(" - ").map((currency) => currency.trim());

			switch (filterMode) {
				case "BUY":
					showOpenOrder = buyCurrencyFilter.includes(orderBuyCurrency);
					break;
				case "SELL":
					showOpenOrder = sellCurrencyFilter.includes(orderSellCurrency);
					break;
				case "BOTH":
					showOpenOrder = buyCurrencyFilter.includes(orderBuyCurrency) && sellCurrencyFilter.includes(orderSellCurrency);
					break;
				case "NONE":
				default:
					showOpenOrder = true;
					break;
			}

			return showOpenOrder;
		});

		return filteredOpenOrders;
	};

	const filteredOpenOrdersReport = applyFilters(openOrdersReport);

	const viewTxn = ({ txn }) => {
		const id = txn._rawTxn.id;
		switch (txn._rawTxnType) {
			case "request":
				//clear state
				transactionDetailsDispatch({
					type: "updateState",
					key: "request",
					value: {},
				});
				navigate(`/transactiondetails/request/${id}`);
				break;
			case "otctrade":
				//clear state
				transactionDetailsDispatch({
					type: "updateState",
					key: "order",
					value: {},
				});
				navigate(`/transactiondetails/${id}`);
				break;
		}
	};

	return (
		<div className="open_orders_report_body">
			{isFetchingOpenOrdersReport && (
				<div className="open_orders_report_loader_container">
					Loading Open Orders report...
					<Loader2 className="open_orders_report_loader" />
				</div>
			)}

			{!isFetchingOpenOrdersReport && (
				<table className="report_table open_orders_report_table">
					<thead>
						<tr>
							<th>Transaction ID</th>
							<th>
								Pair <span className="help_text">( Buy - Sell )</span>
							</th>
							<th>Price</th>
							<th>Amount</th>
							<th>Total</th>
							<th>Username</th>
							<th>Type</th>
							<th>Date</th>
						</tr>
					</thead>
					<tbody>
						{filteredOpenOrdersReport.map((order) => (
							<tr key={order.transactionID} onClick={() => viewTxn({ txn: order })}>
								<td>
									{order.transactionID}
									<br />
									<span className="help_text">( {order._rawTxnType} )</span>
								</td>
								<td>{order.pair}</td>
								<td>{order.price}</td>
								<td>
									{order.amount}
									{order.sellingOrBuying == "SELLING" && (
										<>
											<br />
											<span className="help_text">( You will receive this )</span>
										</>
									)}
								</td>
								<td>
									{order.total}
									{order.sellingOrBuying == "BUYING" && (
										<>
											<br />
											<span className="help_text">( You will receive this )</span>
										</>
									)}
								</td>
								<td>{order.counterpartyName}</td>
								<td>{order.sellingOrBuying}</td>
								<td>{order.date}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
export default OpenOrders;
