import React, { createContext, useContext, useEffect, useState } from "react";
import _ from "lodash";
import { useMutation } from "@tanstack/react-query";
import api from "../api";
import { useInterval } from "../usehooks/usehooks";
const AuthContext = createContext();
const AuthDispatchContext = createContext();

export const AuthProvider = ({ children }) => {
	const emptyAuthTokens = {
		AccessToken: "",
		ExpiresIn: -1,
		IdToken: "",
		RefreshToken: "",
		TokenType: "",
	};
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [authTokens, setAuthTokens] = useState(emptyAuthTokens);
	const verifyToken = useMutation({
		mutationFn: ({ token }) => {
			return api.verifyToken({ token });
		},
		onSuccess: (data, variables, context) => {
			setIsLoggedIn(true);
		},
		onError: (error, variables, context) => {
			//Only log user out if backend returned an unauthorized error
			if(error.response?.status == 401){
				setIsLoggedIn(false);
			}
		},
	});

	//check if token is valid every 30 seconds
	const TOKEN_VALIDITY_CHECK_INTERVAL = 30 * 1000;
	useInterval(() => {
		verifyToken.mutate({ token: authTokens.IdToken });
	}, TOKEN_VALIDITY_CHECK_INTERVAL);

	//check for auth tokens in localstorage
	useEffect(() => {
		let _authTokens = localStorage.getItem("authTokens");
		if (!_.isEmpty(_authTokens)) {
			try {
				_authTokens = JSON.parse(_authTokens);
				setAuthTokens(_authTokens);
				//verify token
				verifyToken.mutate({ token: _authTokens.IdToken });
			} catch (err) {}
		}
	}, []);

	return <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, authTokens, setAuthTokens }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	return useContext(AuthContext);
};
