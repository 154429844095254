import React from "react";
import "./resetPassword.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import api from "../api";
import cn from "classnames";
import Header from "../header/header";
import { useMutation } from "@tanstack/react-query";
import { Loader2, Info } from "lucide-react";
import { useState } from "react";


const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,
    formState: {  errors },
  } = useForm();

  const resetPassword = useMutation({
    mutationFn: ({confirmationCode, newPassword, email}) => {
      return api.resetPassword({confirmationCode, newPassword, email});
    },
    onError: (error, variables, context) => {
		setError(error.response.data.errors[0].detail);
		console.log(error)
	},
    onSuccess: (data, variables, context) => {
      navigate(`/login`);
    },
  });
  const onSubmit = (data) => {
   const email = searchParams.get("email");

    resetPassword.mutate({
      confirmationCode: data.confirmationCode,
      newPassword: data.newPassword,
      email: email,
    });
  };

  return (
    <>
      <Header></Header>
      <div className="reset_password_page">
        <div className="reset_password_body_container">
          <div className="reset_password_body">
            <div className="reset_password_form">
              <div className="reset_password_header">Reset Password</div>
              {/* <div className="reset_password_input_group email">
                <label className="reset_password_input">
                  <span className="reset_password_input_label">
                    Email <span className="required"></span>
                  </span>
                  <input
                    placeholder="Enter your email"
                    name="email"
                    className={cn({
                      error:
                        errors.email?.type == "required" ||
                        resetPassword.isError,
                    })}
                    {...register("email", { required: true })}
                  />
                  {errors.email?.type === "required" && (
                    <span className="reset_password_input_error">
                      <Info className="reset_password_input_error_icon" /> Email
                      is required
                    </span>
                  )}
                </label>
              </div>
              <div className="spacer"></div> */}
              <div className="reset_password_input_group code">
                <label className="reset_password_input">
                  <span className="reset_password_input_label">
                    Confirmation Code <span className="required"></span>
                  </span>
				  <input
                    placeholder="Enter the code from your email"
                    name="confirmationCode"
                    className={cn({
                      error:
                        errors.confirmationCode?.type == "required" ||
                        resetPassword.isError,
                    })}
                    {...register("confirmationCode", { required: true })}
                  />
                  {errors.confirmationCode?.type === "required" && (
                    <span className="reset_password_input_error">
                      <Info className="reset_opassword_input_error_icon" /> Code
                      is required
                    </span>
                  )}
				  {error && (
                    <span className="reset_password_input_error">
                      <Info className="reset_password_input_error_icon" />{" "}
                      {error}
                    </span>
                  )}
                </label>
              </div>
              <div className="spacer"></div>
              <div className="reset_password_input_group password">
                <label className="reset_password_input">
                  <span className="reset_password_input_label">
                    New Password <span className="required"></span>
                  </span>
                  <input
                    type="password"
                    placeholder="Enter your new password"
                    name="newPassword"
                    className={cn({
                      error:
                        errors.newPassword?.type == "required" ||
                        resetPassword.isError,
                    })}
                    {...register("newPassword", { required: true })}
                  />
                  {errors.newPassword?.type === "required" && (
                    <span className="reset_password_input_error">
                      <Info className="reset_password_input_error_icon" />{" "}
                      New Password is required
                    </span>
                  )}
                </label>
              </div>
              <div className="reset_password_forgot_password">
                <span className="reset_password_forgot_password_label">
                  Login?
                </span>
              </div>
              <div className="spacer"></div>
              <div className="spacer"></div>

              <div className="spacer"></div>
              <div className="reset_password_btn_box">
                <div
                  className={cn("reset_password_btn", {
                    is_reseting_password: resetPassword.isPending,
                  })}
                  onClick={handleSubmit(onSubmit)}
                >
                  {resetPassword.isPending && (
                    <Loader2 className="reset_password_loader" />
                  )}
                  {!resetPassword.isPending && <span>Reset Password</span>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
