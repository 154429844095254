import React, { useState } from "react";
import "./settings.scss";
import bellIcon from "../../assets/notification_icon.svg";
import editIcon from "../../assets/edit_icon.svg";
import profileImage from "../../assets/profile-pic.png";
import VerifyIdentity from "./identityVerification/identityVerification";
import { useNavigate } from "react-router-dom";
import MyCompany from "./myCompany/myCompany";
import Paymentmethods from "./paymentnethods/paymentmethods";
const Settings = () => {
	const navigate = useNavigate();
	const [selectedMenuItem, setSelectedMenuItem] = useState("My Account");

	const handleMenuItemClick = (menuItem) => {
		setSelectedMenuItem(menuItem);
	};

	const goToCreateCompanyPage = () => {
		navigate("/create_company");
	};

	const renderSettingsWidgetContent = () => {
		switch (selectedMenuItem) {
			case "My Account":
				return (
					<>
						<div className="general">
							<div className="tab_header">
								<span className="tab_header_text">General</span>
								<img src={editIcon} alt="" />
							</div>
							<div className="data_display">
								<div className="data_group">
									<div className="inner_data_group">
										<div className="profile-picture">
											<img src={profileImage} alt="profile" />
										</div>
										<span className="change_photo">Change photo</span>
									</div>
								</div>
								<div className="data_group">
									<div className="inner_data_group">
										<h4>Username</h4>
										<p>Sarah M.</p>
									</div>
									<div className="inner_data_group">
										<h4>Email</h4>
										<p>sarah.melbourne@gmail.com</p>
									</div>
								</div>
								<div className="data_group">
									<div className="inner_data_group">
										<h4>Name</h4>
										<p>Sarah Melbourne</p>
									</div>
									<div className="inner_data_group">
										<h4>Phone</h4>
										<p>+441234567890</p>
									</div>
								</div>
								<div className="data_group">
									<div className="inner_data_group">
										<h4>Gender</h4>
										<p>Female</p>
									</div>
									<div className="inner_data_group">
										<h4>Date of birth</h4>
										<p>11/11/1987</p>
									</div>
								</div>
							</div>
						</div>
						<div className="location">
							<div className="location_container">
								<div className="tab_header">
									<span className="tab_header_text">Location</span>
									<img src={editIcon} alt="" />
								</div>
								<div className="data_display">
									<div className="inner_data_group">
										<h4>Address</h4>
										<p>Canada water str. 194/1</p>
										<p>London, SE16 7ET</p>
										<p>United Kingdom</p>
									</div>
									<div className="inner_data_group">
										<h4>Time zone</h4>
										<p>UTC+02:00 Berlin, Stockholm, Rome, Bern, Brussels</p>
									</div>
								</div>
							</div>
							<div className="additional_accounts">
								<div className="tab_header">
									<span className="tab_header_text">Additional accounts</span>
								</div>
								<div className="data_display">
									<div className="data_group">
										<p>
											Creating company account allows you to use Muda in different ways, while still
											<br />
											having just one login. Manage teams and company transfers from one common <br /> company account.
										</p>
									</div>
									<div className="create_acct_btn_box">
										<div className="create_acct_btn" onClick={goToCreateCompanyPage}>
											Create company account
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="widget_footer">
							<span className="widget_footer_text">Close my account</span>
						</div>
					</>
				);
			case "My teams":
				return <MyCompany />;
			case "Identity verification":
				return <VerifyIdentity onMyAccountClick={() => handleMenuItemClick("My Account")} />;
			case "Payment methods":
				return <Paymentmethods></Paymentmethods>;
			// Add cases for other menu items as needed
			default:
				return null;
		}
	};
	return (
		<div className="settings_page">
			<div className="settings_header">
				<div className="left_section">
					<span>Settings</span>
				</div>
				<div className="right_section">
					<img src={bellIcon} alt="" />
				</div>
			</div>
			<div className="settings_body">
				<div className="settings_menu">
					<ul className="settings_list">
						<li className={`menu_item ${selectedMenuItem === "My Account" ? "active" : ""}`} onClick={() => handleMenuItemClick("My Account")}>
							<a href="#my-account">My Account</a>
						</li>
						<li className={`menu_item ${selectedMenuItem === "My teams" ? "active" : ""}`} onClick={() => handleMenuItemClick("My teams")}>
							<a href="#my-teams">My teams</a>
						</li>
						<li
							className={`menu_item ${selectedMenuItem === "Identity verification" ? "active" : ""}`}
							onClick={() => handleMenuItemClick("Identity verification")}>
							<a href="#identity-verification">Identity verification</a>
						</li>
						<li className={`menu_item ${selectedMenuItem === "Trading settings" ? "active" : ""}`} onClick={() => handleMenuItemClick("Trading settings")}>
							<a href="#trading-settings">Trading settings</a>
						</li>
						<li className={`menu_item ${selectedMenuItem === "Payment methods" ? "active" : ""}`} onClick={() => handleMenuItemClick("Payment methods")}>
							<a href="#payment-methods">Payment methods</a>
						</li>
						<li
							className={`menu_item ${selectedMenuItem === "Password and security" ? "active" : ""}`}
							onClick={() => handleMenuItemClick("Password and security")}>
							<a href="#password-and-security">Password and security</a>
						</li>
					</ul>
				</div>

				<div className="settings_widget">{renderSettingsWidgetContent()}</div>
			</div>
		</div>
	);
};
export default Settings;
