import Big from "big.js";
import moment from "moment/moment";
export const getProcessedOrder = ({ order }) => {
    let maxPurchaseAmount = Big(order.volumeTypeAmount).mul(Big(order.sellAmount)).toString();
    const locale = Intl.NumberFormat().resolvedOptions().locale;
    let amount = parseFloat(maxPurchaseAmount);
    amount = `${amount.toLocaleString(locale)} ${order.buyCurrency}`;

    const balanceAmount = Big(order.balance).toNumber();
    const balance = `${balanceAmount.toLocaleString(locale)} ${order.buyCurrency}`;

    const totalVolume = Big(order.balance).div(Big(order.volumeTypeAmount)).toString();

    const date = moment(order.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a");

    const buyPrice = `1 ${order.buyCurrency} = ${Big(1).div(Big(order.volumeTypeAmount)).toString()} ${order.sellCurrency}`
    const sellPrice = `1 ${order.sellCurrency} = ${order.volumeTypeAmount} ${order.buyCurrency}`

    const procesedOrder = {
        id: order.id,
        pair: `${order.buyCurrency} - ${order.sellCurrency}`,
        price: `${order.volumeTypeAmount} ${order.buyCurrency}`,
        buyPrice,
        sellPrice,
        amount,
        balance,
        balanceAmount,
        maxPurchaseAmount,
        username: `${order.createdByName}`,
        createdBy: `${order.createdBy}`,
        expiry: `TBD`,
        totalVolume,
        date,
        minOrderVolume: `${order.volumeMin}`,
        windowOrderAmount: `${order.windowOrderAmount}`,
        windowOrderType: `${order.windowOrderType}`,
        windowPaymentAmount: `${order.windowPaymentAmount}`,
        windowPaymentType: `${order.windowPaymentType}`,
        paymentMethods: order.paymentMethods,
        depositAmounts: order.depositAmounts,
    };

    switch (order.orderStatus) {
        case "PENDING":
            procesedOrder.status = "OPEN"
            break
        case "CANCELLED":
            procesedOrder.status = "CANCELLED"
            break
        case "FULFILLED":
            procesedOrder.status = "FULFILLED"
            break
        default:
            procesedOrder.status = `N/A :: ${order.orderStatus}`
            break;
    }

    return procesedOrder;
};