import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useReducer } from "react";
import { useAuth } from "../../../../../providers/AuthProvider";
import _ from "lodash";
import api from "../../../../../api";
import Big from "big.js";
import { formatAmount } from "../../../../../common/formatAmount";
import moment from "moment";

const OutstandingBalanceReportContext = createContext();
const OutstandingBalanceReportDispatchContext = createContext();

export const OUTSTANDING_BALANCE_REPORT_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
};

export const OutstandingBalanceReportProvider = ({ children }) => {
	const { authTokens } = useAuth();

	const getOutstandingBalanceReport = useQuery({
		queryKey: ["outstandingBalanceReport"],
		queryFn: () => {
			return api.getOutstandingBalanceReport({ token: authTokens.IdToken });
		},
		enabled: false,
	});

	const fetchOutstandingBalanceReport = async () => {
		outstandingBalanceReportDispatch({ type: OUTSTANDING_BALANCE_REPORT_ACTIONS.updateState, key: "isFetchingOutstandingBalanceReport", value: true });
		const outstandingBalanceReportRefetchResult = await getOutstandingBalanceReport.refetch();
		outstandingBalanceReportDispatch({ type: OUTSTANDING_BALANCE_REPORT_ACTIONS.updateState, key: "isFetchingOutstandingBalanceReport", value: false });
		if (outstandingBalanceReportRefetchResult.status == "success") {
			let outstandingBalanceReport = outstandingBalanceReportRefetchResult.data.data.data;
			outstandingBalanceReport = outstandingBalanceReport.map((_) => {
				const order = _.attributes;
				const txn = order._rawTxn;
				order.transactionID = txn.id.split("-")[0];
				order.pair = `${txn.order.buyCurrency} - ${txn.order.sellCurrency}`;
				order.price = `1 ${txn.order.sellCurrency} / ${formatAmount(txn.order.volumeTypeAmount)} ${txn.order.buyCurrency}`;
				order.amount = `${formatAmount(txn.desiredPurchaseAmount)} ${txn.order.buyCurrency}`;
				const total = Big(txn.desiredPurchaseAmount).div(Big(txn.order.volumeTypeAmount)).toString();
				order.total = `${formatAmount(total)} ${txn.order.sellCurrency}`;
				order.date = moment(txn.createdAt).format("DD.MM.YYYY / h:mm A");
				
				return order;
				
			});
			outstandingBalanceReportDispatch({ type: OUTSTANDING_BALANCE_REPORT_ACTIONS.updateState, key: "outstandingBalanceReport", value: outstandingBalanceReport });
		}
	};

	const [outstandingBalanceReportState, outstandingBalanceReportDispatch] = useReducer(OutstandingBalanceReportReducer, {
		outstandingBalanceReport: [],
		isFetchingOutstandingBalanceReport: false,
		fetchOutstandingBalanceReport,
		getOutstandingBalanceReport,
	});

	return (
		<OutstandingBalanceReportContext.Provider value={outstandingBalanceReportState}>
			<OutstandingBalanceReportDispatchContext.Provider value={outstandingBalanceReportDispatch}>{children}</OutstandingBalanceReportDispatchContext.Provider>
		</OutstandingBalanceReportContext.Provider>
	);
};

export const useOutstandingBalanceReport = () => {
	return useContext(OutstandingBalanceReportContext);
};

export const useOutstandingBalanceReportDispatch = () => {
	return useContext(OutstandingBalanceReportDispatchContext);
};

const OutstandingBalanceReportReducer = (outstandingBalanceReportState, action) => {
	switch (action.type) {
		case OUTSTANDING_BALANCE_REPORT_ACTIONS.updateState: {
			const newState = { ...outstandingBalanceReportState };
			newState[action.key] = action.value;
			return newState;
		}
		case OUTSTANDING_BALANCE_REPORT_ACTIONS.updateStates: {
			const newState = { ...outstandingBalanceReportState };
			action.states.forEach(({ key, value }) => {
				newState[key] = value;
			});
			return newState;
		}
		default: {
			throw Error("[OutstandingBalanceReportReducer] Unknown action: " + action.type);
		}
	}
};
