/* 
Nested routes added as children to workspace route
ref : https://reactrouter.com/en/main/start/tutorial#nested-routes
*/
import Dashboard from "./dashboard/dashboard";
import Dealrooms from "./dealrooms/dealrooms";
import Kyc from "./kyc/kyc";
import Marketplace from "./marketplace/marketplace";
import MyActivity from "./myactivity/myactivity";
import Reports from "./reports/reports";
import Wallets from "./wallets/wallets";
import Settings from "./settings/settings";
import CreateCompany from "./create_company_account/createCompany";
import VerifyCompanyAccount from "./verify_company_account/verifyCompanyAccount";
import TransactionDetails from "./transactionDetails/transactionDetails";
import RequestTransactionDetails from "./transactionDetails/requestTransactionDetails/requestTransactionDetails";
import Rates from "./rates/rates";
const workspaceRoutes = [
	{ index: true, element: <Dashboard /> },
	{
		path: "kyc",
		element: <Kyc />,
	},
	{
		path: "wallets",
		element: <Wallets />,
	},
	{
		path: "myactivity",
		element: <MyActivity />,
	},
	{
		path: "marketplace",
		element: <Marketplace />,
	},
	{
		path: "transactiondetails",
		element: <TransactionDetails />,
	},
	{
		path: "transactiondetails/:orderId",
		element: <TransactionDetails />,
	},
	{
		path: "transactiondetails/request/:requestId",
		element: <RequestTransactionDetails />,
	},
	{
		path: "dealrooms",
		element: <Dealrooms />,
	},
	{
		path: "reports",
		element: <Reports />,
	},
	{
		path: "settings",
		element: <Settings />,
	},
	{
		path: "create_company",
		element: <CreateCompany />,
	},
	{
		path: "verify_company_account",
		element: <VerifyCompanyAccount />,
	},
	{
		path: "rates",
		element: <Rates />,
	},
];

export default workspaceRoutes;
