import React, { createContext, useContext, useState, useCallback } from 'react';

// Create context
const ReportFiltersContext = createContext();

// Create provider
export const ReportFiltersProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    searchQuery: '',
    startDate: null,
    endDate: null,
    buyCurrency: null,
    sellCurrency: null,
  });

  const updateFilters = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  return (
    <ReportFiltersContext.Provider value={{ filters, updateFilters }}>
      {children}
    </ReportFiltersContext.Provider>
  );
};

// Custom hook to use filters
export const useReportFilters = () => {
  return useContext(ReportFiltersContext);
};
