export const currencies = [
	{
		code: "USD",
		symbol: "$",
	},
	{
		code: "ETH",
		symbol: "Ξ",
	},
	{
		code: "BTC",
		symbol: "฿",
	},
	{
		code: "NGN",
		symbol: "₦",
	},
	{
		code: "USDT",
		symbol: "₮",
	},
	{
		code: "USDC",
		symbol: "USDC",
	},
	{
		code: "CUSD",
		symbol: "CUSD",
	},
	{
		code: "TRX",
		symbol: "TRX",
	},
	{
		code: "CELO",
		symbol: "CELO",
	},
	{
		code: "UGX",
		symbol: "ush",
	},
	{
		code: "KES",
		symbol: "KSh",
	},
	{
		code: "ALL",
		symbol: "Lek",
	},
	{
		code: "AFN",
		symbol: "؋",
	},
	{
		code: "ARS",
		symbol: "$",
	},
	{
		code: "AWG",
		symbol: "ƒ",
	},
	{
		code: "AUD",
		symbol: "	$",
	},
	{
		code: "AZN",
		symbol: "₼",
	},
	{
		code: "	BSD",
		symbol: "	$",
	},
	{
		code: "BYN",
		symbol: "Br",
	},
	{
		code: "BZD",
		symbol: "BZ$",
	},

	{
		code: "	BMD",
		symbol: "$",
	},
	{
		code: "	BOB",
		symbol: "$b",
	},
	{
		code: "	BAM",
		symbol: "KM",
	},
	{
		code: "	BWP",
		symbol: "	P",
	},
	{
		code: "BGN",
		symbol: "	лв",
	},
	{
		code: "BYN",
		symbol: "Br",
	},
	{
		code: "BRL",
		symbol: "R$",
	},

	{
		code: "BND",
		symbol: "$",
	},
	{
		code: "KHR",
		symbol: "៛",
	},
	{
		code: "CAD",
		symbol: "$",
	},
	{
		code: "KYD",
		symbol: "$",
	},
	{
		code: "CLP",
		symbol: "$",
	},
	{
		code: "CNY",
		symbol: "¥",
	},
	{
		code: "COP",
		symbol: "$",
	},

	{
		code: "CRC",
		symbol: "₡",
	},
	{
		code: "HRK",
		symbol: "kn",
	},
	{
		code: "CUP",
		symbol: "₱",
	},
	{
		code: "CZK",
		symbol: "Kč",
	},
	{
		code: "DKK",
		symbol: "kr",
	},
	{
		code: "DOP",
		symbol: "RD$",
	},
	{
		code: "XCD",
		symbol: "$",
	},

	{
		code: "EGP",
		symbol: "£",
	},
	{
		code: "SVC",
		symbol: "$",
	},
	{
		code: "EUR",
		symbol: "€",
	},
	{
		code: "FKP",
		symbol: "£",
	},
	{
		code: "FJD",
		symbol: "$",
	},
	{
		code: "GHS",
		symbol: "¢",
	},
	{
		code: "GIP",
		symbol: "£",
	},

	{
		code: "GTQ",
		symbol: "Q",
	},
	{
		code: "GGP",
		symbol: "£",
	},
	{
		code: "GYD",
		symbol: "$",
	},
	{
		code: "HNL",
		symbol: "L",
	},
	{
		code: "HKD",
		symbol: "$",
	},
	{
		code: "HUF",
		symbol: "Ft",
	},
	{
		code: "ISK",
		symbol: "kr",
	},

	{
		code: "INR",
		symbol: "₹",
	},
	{
		code: "IDR",
		symbol: "Rp",
	},
	{
		code: "IRR",
		symbol: "﷼",
	},
	{
		code: "IMP",
		symbol: "£",
	},
	{
		code: "ILS",
		symbol: "₪",
	},
	{
		code: "JMD",
		symbol: "J$",
	},
	{
		code: "JPY",
		symbol: "¥",
	},

	{
		code: "JEP",
		symbol: "£",
	},
	{
		code: "KZT",
		symbol: "лв",
	},
	{
		code: "KPW",
		symbol: "₩",
	},
	{
		code: "KRW",
		symbol: "₩",
	},
	{
		code: "KGS",
		symbol: "лв",
	},
	{
		code: "LAK",
		symbol: "₭",
	},
	{
		code: "LBP",
		symbol: "£",
	},

	{
		code: "LRD",
		symbol: "$",
	},
	{
		code: "MKD",
		symbol: "ден",
	},
	{
		code: "MYR",
		symbol: "RM",
	},
	{
		code: "MUR",
		symbol: "₨",
	},
	{
		code: "MXN",
		symbol: "$",
	},
	{
		code: "MNT",
		symbol: "₮",
	},
	{
		code: "MNT",
		symbol: "د.إ",
	},

	{
		code: "MZN",
		symbol: "MT",
	},
	{
		code: "NAD",
		symbol: "$",
	},
	{
		code: "NPR",
		symbol: "₨",
	},
	{
		code: "ANG",
		symbol: "ƒ",
	},
	{
		code: "NZD",
		symbol: "$",
	},
	{
		code: "NIO",
		symbol: "C$",
	},
	{
		code: "NOK",
		symbol: "kr",
	},

	{
		code: "OMR",
		symbol: "﷼",
	},
	{
		code: "PKR",
		symbol: "₨",
	},
	{
		code: "PAB",
		symbol: "B/.",
	},
	{
		code: "PYG",
		symbol: "Gs",
	},
	{
		code: "PEN",
		symbol: "S/.",
	},
	{
		code: "PHP",
		symbol: "₱",
	},
	{
		code: "PLN",
		symbol: "zł",
	},

	{
		code: "QAR",
		symbol: "﷼",
	},
	{
		code: "RON",
		symbol: "lei",
	},
	{
		code: "RUB",
		symbol: "₽",
	},
	{
		code: "SHP",
		symbol: "£",
	},
	{
		code: "SAR",
		symbol: "﷼",
	},
	{
		code: "RSD",
		symbol: "Дин.",
	},
	{
		code: "SCR",
		symbol: "₨",
	},

	{
		code: "SGD",
		symbol: "$",
	},
	{
		code: "SBD",
		symbol: "$",
	},
	{
		code: "SOS",
		symbol: "S",
	},
	{
		code: "KRW",
		symbol: "₩",
	},
	{
		code: "ZAR",
		symbol: "R",
	},
	{
		code: "LKR",
		symbol: "₨",
	},
	{
		code: "SEK",
		symbol: "kr",
	},

	{
		code: "CHF",
		symbol: "CHF",
	},
	{
		code: "SRD",
		symbol: "$",
	},
	{
		code: "SYP",
		symbol: "£",
	},
	{
		code: "TWD",
		symbol: "NT$",
	},
	{
		code: "THB",
		symbol: "฿",
	},
	{
		code: "TTD",
		symbol: "TT$",
	},
	{
		code: "TRY",
		symbol: "₺",
	},

	{
		code: "TVD",
		symbol: "$",
	},
	{
		code: "UAH",
		symbol: "₴",
	},
	{
		code: "AED",
		symbol: "د.إ",
	},
	{
		code: "GBP",
		symbol: "£",
	},
	{
		code: "UYU",
		symbol: "$U",
	},
	{
		code: "UZS",
		symbol: "лв",
	},

	{
		code: "VEF",
		symbol: "Bs",
	},
	{
		code: "VND",
		symbol: "₫",
	},
	{
		code: "YER",
		symbol: "﷼",
	},
	{
		code: "ZWD",
		symbol: "Z$",
	},
	{
		code: "UYU",
		symbol: "$U",
	},
	{
		code: "UZS",
		symbol: "лв",
	},
	{
		code: "TZS",
		symbol: "tzs",
	},
];
