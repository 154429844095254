import React, { useState } from "react";
import "./confirm_email.scss";
import logo from "../assets/logo.svg";
import { Loader2, Info } from "lucide-react";
import VerificationCodeInput from "./verification_code_input/verification_code_input";
import { useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";
import api from "../api";
import { useMutation } from "@tanstack/react-query";

const ConfirmEmail = () => {
	const [code, setCode] = useState(["", "", "", "", "", ""]);
	const [isResendDisabled, setIsResendDisabled] = useState(false);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const confirmEmail = useMutation({
		mutationFn: (creds) => {
			return api.confirmEmail(creds);
		},
		onError: (error, variables, context) => {},
		onSuccess: (data, variables, context) => {
			//Go to login
			navigate(`/login`);
		},
	});

	const onConfirmEmail = (confirmationCode) => {
		const email = searchParams.get("email");
		confirmEmail.mutate({ email, confirmationCode });
	};

	const resendConfirmationCode = useMutation({
		mutationFn: (email) => {
			return api.resendConfirmationCode({ email });
		},
		onError: (error, variables, context) => {},
		onSuccess: (data, variables, context) => {
			// Handle successful resend if needed
			alert("A new code has been sent to your email.");
			setIsResendDisabled(true);
			setTimeout(() => setIsResendDisabled(false), 600000);

		},
	}) 

	const handleResendCode = () => {
		const email = searchParams.get("email");
		if (email) {
			resendConfirmationCode.mutate(email);
		} else {
			alert("Email not found. Please try again.");
		}
	};

	return (
		<div className="confirm-email-page">
			<div className="top-bar">
				<div className="logo">
					<img src={logo} alt="" />
				</div>
			</div>

			<div className="confirm_email_body_container">
				<div className="confirm_email_body">
					<div className="confirm_email_heading">Check your email for a code</div>
					<div className="confirm_email_subheading">
						We hate to be a bother, but we need to do our due diligence to keep your account safe. Please enter the code we've sent to{" "}
						<strong>
							{_.isEmpty(searchParams.get("email")) && "..."}
							{!_.isEmpty(searchParams.get("email")) && searchParams.get("email")}
						</strong>{" "}
						below to complete your sign-in.
					</div>

					<VerificationCodeInput confirmEmail={onConfirmEmail} code={code} setCode={setCode} />
					<div className="spacer"></div>
					<div className="resend_code">No email received? Check your spam folder or 
					<span
							className={`resend_code_link ${isResendDisabled ? "disabled" : ""}`}
							onClick={!isResendDisabled ? handleResendCode : null}
						>
							Request a new code
						</span>
					</div>

					{confirmEmail.isPending && (
						<div className="checking_code_container">
							<Loader2 className="checking_code_loader" />
							<span className="checking_code_msg">Checking your code</span>
						</div>
					)}

					{confirmEmail.isError && (
						<div className="checking_code_error">
							<Info className="checking_code_error_icon" />
							<span className="checking_code_error_label">That code wasn't valid. try again!</span>
						</div>
					)}

					<div className="page-footer">
						<span>Privacy & Terms</span>
						<div className="spacer"></div>
						<span>Contact Us</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConfirmEmail;
