import ReactSelect from "react-select";
import { useRates } from "../../../providers/Rates/RatesProvider";
import "./myOrders.scss";
import { useAuth } from "../../../providers/AuthProvider";
import { useEffect, useState } from "react";
import { getProcessedOrder } from "../../../common/getProcessedOrder";
import Big from "big.js";
import moment from "moment/moment";
import { Loader2 } from "lucide-react";
import _ from "lodash";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { useTransactionDetailsDispatch } from "../../transactionDetails/providers/transactionDetailsProvider";
import { useMyOrders } from "./providers/myOrdersProvider";

const reactSelectStyleMyOrders = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: ".9rem",
    fontWeight: "600",
    padding: ".2rem",
    borderRadius: "8px",
    width: "9rem",
    color: "#172c50",
    backgroundColor: "#ffffff",
    borderColor: "#3E9CF3",
  }),
};

const MyOrders = () => {
  const { currencies } = useRates();
  const [myOrdersState, setMyOrdersState] = useState({
    currencyOptions: [],
    buyCurrencyFilter: [],
    sellCurrencyFilter: [],
  });
  const { isLoggedIn } = useAuth();
  const transactionDetailsDispatch = useTransactionDetailsDispatch();
  const navigate = useNavigate();

  const { fetchMyOrders, isFetchingMyOrders, myOrders } = useMyOrders();

  //Get my orders
  useEffect(() => {
    const newMyOrdersState = { ...myOrdersState };
    const currencyOptions = currencies.map((currency) => ({
      value: currency.code,
      label: currency.code,
    }));
    newMyOrdersState.currencyOptions = currencyOptions;

    setMyOrdersState(newMyOrdersState);

    (async () => {
      // fetch my orders if logged in
      if (isLoggedIn) {
        await fetchMyOrders();
      }
    })();
  }, [isLoggedIn]);

  const processedOrders = () => {
    const orders = myOrders;

    const buyCurrencyFilter = myOrdersState.buyCurrencyFilter;
    const sellCurrencyFilter = myOrdersState.sellCurrencyFilter;
    let filterMode = "NONE";
    if (buyCurrencyFilter.length > 0 && sellCurrencyFilter.length > 0) {
      filterMode = "BOTH";
    } else if (buyCurrencyFilter.length > 0) {
      filterMode = "BUY";
    } else if (sellCurrencyFilter.length > 0) {
      filterMode = "SELL";
    }

    const filteredOrders = orders.filter((order) => {
      let showOrder = true;
      const buyCurrency = order.pair.split("-")[0].trim();
      const sellCurrency = order.pair.split("-")[1].trim();

      switch (filterMode) {
        case "BUY":
          showOrder = buyCurrencyFilter.includes(buyCurrency);
          break;
        case "SELL":
          showOrder = sellCurrencyFilter.includes(sellCurrency);
          break;
        case "BOTH":
          showOrder =
            buyCurrencyFilter.includes(buyCurrency) &&
            sellCurrencyFilter.includes(sellCurrency);
          break;
        case "NONE":
        default:
          showOrder = true;
          break;
      }

      return showOrder;
    });

    return filteredOrders;
  };

  const viewOrder = ({ order: _order }) => {
    switch (_order._rawTxnType) {
      case "order":
        const order = _order._rawTxn;
        const processedOrder = getProcessedOrder({ order });
        transactionDetailsDispatch({
          type: "open",
          order: processedOrder,
          back: "/myactivity",
        });
        navigate(`/transactiondetails/${order.id}`);
        break;
      case "request":
        const request = _order._rawTxn;
        transactionDetailsDispatch({
          type: "updateState",
          key: "request",
          value: request,
        });
        navigate(`/transactiondetails/request/${request.id}`);
        break;
    }
  };

  const buyCurrencyFilterChanged = (evt) => {
    let buyCurrencyFilter = [];
    if (!_.isEmpty(evt)) {
      const currency = evt.value;
      buyCurrencyFilter = [`${currency}`];
    }

    const newMyOrdersState = { ...myOrdersState };
    newMyOrdersState.buyCurrencyFilter = buyCurrencyFilter;
    setMyOrdersState(newMyOrdersState);
  };

  const sellCurrencyFilterChanged = (evt) => {
    let sellCurrencyFilter = [];
    if (!_.isEmpty(evt)) {
      const currency = evt.value;
      sellCurrencyFilter = [`${currency}`];
    }

    const newMyOrdersState = { ...myOrdersState };
    newMyOrdersState.sellCurrencyFilter = sellCurrencyFilter;
    setMyOrdersState(newMyOrdersState);
  };

  return (
    <div id="myorders_component">
      <div className="heading">
        <div className="input currency">
          <ReactSelect
		    styles={reactSelectStyleMyOrders}
            className="currency_picker"
            options={myOrdersState.currencyOptions}
            onChange={buyCurrencyFilterChanged}
            isClearable={true}
          ></ReactSelect>
          <ReactSelect
		  styles={reactSelectStyleMyOrders}
		   className="currency_picker"
            options={myOrdersState.currencyOptions}
            onChange={sellCurrencyFilterChanged}
            isClearable={true}
          ></ReactSelect>
        </div>
      </div>

      {isFetchingMyOrders && (
        <div className="myorders_loader_container">
          Loading my orders...
          <Loader2 className="myorders_loader" />
        </div>
      )}

      {!isFetchingMyOrders && (
        <div className="body">
          <table className="my_orders_table">
            <thead>
              <tr>
                <th>Transaction ID</th>
                <th>
                  Pair
                  <br />
                  <span className="help_text">( Buy - Sell )</span>
                </th>
                <th>Price</th>
                <th>
                  Amount
                  <br />
                  <span className="help_text">( You receive this )</span>
                </th>
                <th>
                  Total
                  <br />
                  <span className="help_text">( You pay this )</span>
                </th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {processedOrders().map((order) => (
                <tr key={order.id} onClick={() => viewOrder({ order })}>
                  <td>
                    {order.id}
                    <br />
                    <span className="help_text">( {order.type} )</span>
                  </td>
                  <td>{order.pair}</td>
                  <td>{order.price}</td>
                  <td>{order.amount}</td>
                  <td>{order.total}</td>
                  <td>{order.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default MyOrders;
