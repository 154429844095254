import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  useTransact,
  useTransactDispatch,
} from "../../../providers/TransactProvider";
import "./confirmotctrade.scss";
import { useAuth } from "../../../../../providers/AuthProvider";
import api from "../../../../../api";
import { Loader2 } from "lucide-react";
import { useCreateRequest } from "../../../../transactionDetails/createRequest/providers/createRequestProvider";
import { useMarketplace } from "../../../../marketplace/providers/MarketplaceProvider";
import { useNavigate } from "react-router-dom";
import { PAYMENT_METHOD_TYPES } from "../../../paymentmethods/configurepaymentmethods/configurepaymentmethods";

const Confirmotctrade = () => {
  const { authTokens } = useAuth();
  const { otcTradeState } = useTransact();
  const { request } = useCreateRequest();

  const transactDispatch = useTransactDispatch();
  const queryClient = useQueryClient();
  const { reloadMarketPlaceOrders } = useMarketplace();
  const navigate = useNavigate();

  const placeOtcTrade = useMutation({
    mutationFn: ({ order, token }) => api.placeOtcTrade({ order, token }),
    onError: () => {},
    onSuccess: async (data, variables, context) => {
      transactDispatch({ type: "tradeConfirmed" });
      await reloadMarketPlaceOrders();
      const orderId = data.data.data.attributes.id;
      navigate(`/transactiondetails/${orderId}`);
    },
  });

  const goToConfigurePaymentMethods = () => {
    transactDispatch({
      type: "goToConfigurePaymentMethods",
    });
  };

  const renderPaymentMethod = (paymentMethod) => (
    <div className="payment_method" key={paymentMethod.id}>
      {paymentMethod.type == PAYMENT_METHOD_TYPES.BANK_ACCOUNT && (
        <div className="name">{paymentMethod.bankName}</div>
      )}
      {paymentMethod.type == PAYMENT_METHOD_TYPES.PHONE_NUMBER && (
        <div className="name">{paymentMethod.phonenumberLabel}</div>
      )}
      {paymentMethod.type == PAYMENT_METHOD_TYPES.WALLET_ADDRESS && (
        <div className="name">{paymentMethod.walletAddressLabel}</div>
      )}
      <div className="details">
        {paymentMethod.type == PAYMENT_METHOD_TYPES.BANK_ACCOUNT && (
          <div className="name">{paymentMethod.accountNumber}</div>
        )}
        {paymentMethod.type == PAYMENT_METHOD_TYPES.PHONE_NUMBER && (
          <div className="name">{paymentMethod.phonenumber}</div>
        )}
        {paymentMethod.type == PAYMENT_METHOD_TYPES.WALLET_ADDRESS && (
          <div className="name">{paymentMethod.walletAddress}</div>
        )}
      </div>
    </div>
  );

  const renderDepositAmount = (deposit) => (
    <div className="payment_method" key={deposit.paymentMethod.id}>
      {deposit.paymentMethod.value.type ==
        PAYMENT_METHOD_TYPES.BANK_ACCOUNT && (
        <div className="name">{deposit.paymentMethod.label}</div>
      )}
      {deposit.paymentMethod.value.type ==
        PAYMENT_METHOD_TYPES.PHONE_NUMBER && (
        <div className="name">{deposit.paymentMethod.label}</div>
      )}
      {deposit.paymentMethod.value.type ==
        PAYMENT_METHOD_TYPES.WALLET_ADDRESS && (
        <div className="name">{deposit.paymentMethod.label}</div>
      )}
      <div className="details">
        {deposit.instruction}
        {deposit.paymentMethod.value.currency}
      </div>
    </div>
  );

  const renderPaymentMethods = () => {
    if (!request.depositAmounts || request.depositAmounts.length === 0) {
      return request.paymentMethods.map(renderPaymentMethod);
    } else {
      return request.depositAmounts.map(renderDepositAmount);
    }
  };

  return (
    <div id="confirmotctrade_component">
      <div className="confirmotctrade_controls">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="confirmotctrade_controls_back"
          onClick={goToConfigurePaymentMethods}
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M16 4L8 12L16 20"
            stroke="#172C50"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="confirmotctrade_header">
        <p>PLACE NEW ORDER</p>
      </div>
      <div className="confirmotctrade_summary">
        <div className="confirm_summary">
          <div className="confirm_header">Confirmation Page</div>
          <div className="confirm_info">
            <div className="confirm_sell">
              <div className="left_label">Selling</div>
              <div className="right_label">
                {otcTradeState.sell.amount} {otcTradeState.sell.currency}
              </div>
            </div>
            <div className="confirm_buy">
              <div className="left_label">Buying</div>
              <div className="right_label">
                {otcTradeState.buy.amount} {otcTradeState.buy.currency}
              </div>
            </div>
            <div className="confirm_exchange_rate">
              <div className="left_label">Exchange rate</div>
              <div>
                <div className="right_label">
                  1 {otcTradeState.sell.currency} ={" "}
                  {otcTradeState.volume.typeAmount} {otcTradeState.buy.currency}
                </div>
                <div className="right_label">
                  1 {otcTradeState.buy.currency} ={" "}
                  {(1 / otcTradeState.volume.typeAmount).toFixed(5)}{" "}
                  {otcTradeState.sell.currency}
                </div>
              </div>
            </div>
          </div>
          <div className="confirm_info">
            <div className="confirm_order">
              <div className="left_label">Min. Order Volume</div>
              <div className="right_label">
                {otcTradeState.volume.min} {otcTradeState.sell.currency}
              </div>
            </div>
            <div className="confirm_pay_window">
              <div className="left_label">Payment Window</div>
              <div className="right_label">
                {otcTradeState.window.payment.amount}{" "}
                {otcTradeState.window.payment.type}
              </div>
            </div>
            <div className="confirm_order_window">
              <div className="left_label">Order Window</div>
              <div className="right_label">
                {otcTradeState.window.order.amount}{" "}
                {otcTradeState.window.order.type}
              </div>
            </div>
          </div>
          <hr />
          <div className="confirm_info">
            <div className="payment_method_info">
              <div className="payment_method_label">Payment method</div>
              {renderPaymentMethods()}
            </div>
          </div>
        </div>
        <div className="summary">
          {!placeOtcTrade.isPending && (
            <div
              onClick={() => {
                const order = {
                  buyCurrency: otcTradeState.buy.currency,
                  buyAmount: otcTradeState.buy.amount,
                  sellCurrency: otcTradeState.sell.currency,
                  sellAmount: otcTradeState.sell.amount,
                  volumeType: otcTradeState.volume.type,
                  volumeTypeAmount: otcTradeState.volume.typeAmount,
                  volumeMin: otcTradeState.volume.min,
                  windowPaymentType: otcTradeState.window.payment.type,
                  windowPaymentAmount: otcTradeState.window.payment.amount,
                  windowOrderType: otcTradeState.window.order.type,
                  windowOrderAmount: otcTradeState.window.order.amount,
                  paymentMethods: request.paymentMethods,
                  depositAmounts: request.depositAmounts || [],
                };
                placeOtcTrade.mutate({
                  order,
                  token: authTokens.IdToken,
                });
              }}
              className="place_otctrade"
            >
              Submit
            </div>
          )}

          {placeOtcTrade.isPending && (
            <div className="place_otctrade">
              <Loader2 className="place_otctrade_loader" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Confirmotctrade;
