import { ChevronLeft, Loader2, RefreshCw } from "lucide-react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "./requestTransactionDetails.scss";
import { useEffect, useState } from "react";
import cn from "classnames";
import Pops from "./pops/pops";
import { useProfile } from "../../../providers/ProfileProvider";
import { useTransactionDetails, useTransactionDetailsDispatch } from "../providers/transactionDetailsProvider";
import { REQUESTS_ACTIONS, useRequests, useRequestsDispatch } from "../../../providers/RequestsProvider";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import api from "../../../api";
import { useAuth } from "../../../providers/AuthProvider";
import Big from "big.js";
import { PAYMENT_METHOD_TYPES } from "../../transact/paymentmethods/configurepaymentmethods/configurepaymentmethods";

const REQUEST_TRANSACITON_DETAILS_TABS = {
	DETAILS: "DETAILS",
	POPS: "POPS",
};

const emptyRequestTransactionDetailsState = {
	activeTab: REQUEST_TRANSACITON_DETAILS_TABS.DETAILS,
	loadingPops: false,
	buyerProofOfPayments: [],
	buyerUploadedAmountVerifed: 0,
	buyerUploadedAmountTotal: 0,
	sellerProofOfPayments: [],
	sellerUploadedAmountVerifed: 0,
	sellerUploadedAmountTotal: 0,
};

const RequestTransactionDetails = () => {
	const { authTokens } = useAuth();
	const [requestTransactionDetailsState, setRequestTransactionDetailsState] = useState(emptyRequestTransactionDetailsState);
	const { receivedRequests, isFetchingReceivedRequests, getReceivedRequests, getSentRequests, sentRequests, isFetchingSentRequests } = useRequests();
	const navigate = useNavigate();
	const { profile } = useProfile();
	const { request } = useTransactionDetails();
	const params = useParams();
	const requestsDispatch = useRequestsDispatch();
	const transactionDetailsDispatch = useTransactionDetailsDispatch();

	const updateState = ({ key, value }) => {
		const newState = { ...requestTransactionDetailsState };
		newState[key] = value;
		setRequestTransactionDetailsState(newState);
	};

	const updateStates = (states) => {
		const newState = { ...requestTransactionDetailsState };
		states.forEach(({ key, value }) => {
			newState[key] = value;
		});
		setRequestTransactionDetailsState(newState);
	};

	const loadingRequests = () => {
		return isFetchingReceivedRequests || isFetchingSentRequests || requestTransactionDetailsState.loadingPops;
	};

	const getPops = async (request) => {
		const domain = api.getApiDomain();
		updateState({ key: "loadingPops", value: true });

		const order = request.order;
		const result = await api.getProofOfPayments({
			token: authTokens.IdToken,
			request,
			order,
		});
		const proofOfPayments = result.data.data.map((datum) => {
			const pop = datum.attributes;
			const fileUrl = `${domain}/orders/${order.id}/requests/${request.id}/proofofpayments/${pop.id}/files/${pop.file}?token=${authTokens.IdToken}`;
			pop.fileUrl = fileUrl;
			return pop;
		});
		const buyerProofOfPayments = proofOfPayments.filter((pop) => pop.popType == "BUYER_POP");
		const sellerProofOfPayments = proofOfPayments.filter((pop) => pop.popType == "SELLER_POP");

		const buyerUploadedAmountVerifed = buyerProofOfPayments.filter((pop) => pop.verified == "true").reduce((acc, curr) => acc + parseFloat(curr.amount), 0);
		const buyerUploadedAmountTotal = request.desiredPurchaseAmount;

		const sellerUploadedAmountVerifed = sellerProofOfPayments.filter((pop) => pop.verified == "true").reduce((acc, curr) => acc + parseFloat(curr.amount), 0);
		const sellerUploadedAmountTotal = request.desiredPurchaseAmountConverted;

		updateStates([
			{ key: "loadingPops", value: false },
			{ key: "buyerProofOfPayments", value: buyerProofOfPayments },
			{ key: "buyerUploadedAmountTotal", value: buyerUploadedAmountTotal },
			{ key: "buyerUploadedAmountVerifed", value: buyerUploadedAmountVerifed },
			{ key: "sellerProofOfPayments", value: sellerProofOfPayments },
			{ key: "sellerUploadedAmountTotal", value: sellerUploadedAmountTotal },
			{
				key: "sellerUploadedAmountVerifed",
				value: sellerUploadedAmountVerifed,
			},
		]);
	};

	const reloadRequest = async () => {
		//Clear any previous state to prevent stale cache issues
		transactionDetailsDispatch({
			type: "updateState",
			key: "request",
			value: {},
		});

		requestsDispatch({
			type: REQUESTS_ACTIONS.updateStates,
			states: {
				isFetchingReceivedRequests: true,
				isFetchingSentRequests: true,
			},
		});

		const myRequests = _.flattenDeep(
			(await Promise.allSettled([getReceivedRequests.refetch(), getSentRequests.refetch()])).map((result) => {
				let request = {};
				if (result.status == "fulfilled") {
					request = result.value.data.data.data.map((datum) => datum.attributes);
				}
				return request;
			})
		);

		requestsDispatch({
			type: REQUESTS_ACTIONS.updateStates,
			states: {
				isFetchingReceivedRequests: false,
				isFetchingSentRequests: false,
			},
		});

		const requestId = params.requestId;
		const foundRequest = _.find(myRequests, { id: requestId });
		if (!_.isEmpty(foundRequest)) {
			transactionDetailsDispatch({
				type: "updateState",
				key: "request",
				value: foundRequest,
			});

			const desiredPurchaseAmountFormatted = formatAmounts(foundRequest.desiredPurchaseAmount);
			foundRequest.desiredPurchaseAmountFormatted = desiredPurchaseAmountFormatted;

			const desiredPurchaseAmountConverted = Big(foundRequest.desiredPurchaseAmount).div(Big(foundRequest.order.volumeTypeAmount)).toString();
			foundRequest.desiredPurchaseAmountConverted = desiredPurchaseAmountConverted;

			const desiredPurchaseAmountConvertedFormatted = formatAmounts(desiredPurchaseAmountConverted);
			foundRequest.desiredPurchaseAmountConvertedFormatted = desiredPurchaseAmountConvertedFormatted;

			//Get Pops
			getPops(foundRequest);
		} else {
			// Either request doesn't exist or user doesn't have permission to view the request
		}
	};

	useEffect(() => {
		reloadRequest();
	}, []);

	const goBack = () => {
		navigate("/myactivity");
	};

	const userIsOwnerOfOrderRequest = () => {
		const isOwner = request.createdBy == profile.id;
		return isOwner;
	};
	const userIsBuyer = userIsOwnerOfOrderRequest;

	const userIsOwnerOfOrder = () => {
		const isOwner = request.order.createdBy == profile.id;
		return isOwner;
	};
	const userIsSeller = userIsOwnerOfOrder;

	const orderRequestIsPendingAcceptance = () => {
		const isPendingAcceptance = request.requestState == "CREATED";
		return isPendingAcceptance;
	};

	const isPendingAcceptance = orderRequestIsPendingAcceptance();

	const switchToTab = (tab) => {
		const newRequestTransactionDetailsState = {
			...requestTransactionDetailsState,
		};
		newRequestTransactionDetailsState.activeTab = tab;
		setRequestTransactionDetailsState(newRequestTransactionDetailsState);
	};

	const onUploadProofOfPayment = () => {
		switchToTab(REQUEST_TRANSACITON_DETAILS_TABS.POPS);
	};

	const formatAmounts = (amount) => {
		const locale = Intl.NumberFormat().resolvedOptions().locale;
		let formatedAmount = parseFloat(amount);
		formatedAmount = `${formatedAmount.toLocaleString(locale)}`;
		return formatedAmount;
	};

	const buyerPopCompleted = () => {
		return requestTransactionDetailsState.buyerUploadedAmountVerifed >= requestTransactionDetailsState.buyerUploadedAmountTotal;
	};

	const sellerPopCompleted = () => {
		return requestTransactionDetailsState.sellerUploadedAmountVerifed >= requestTransactionDetailsState.sellerUploadedAmountTotal;
	};

	const transactionCompleted = () => {
		return buyerPopCompleted() && sellerPopCompleted();
	};

	return (
		<div id="request_transaction_details_page">
			<div className="back_container">
				<ChevronLeft className="back_icon" onClick={goBack} />
			</div>
			<div className="heading">
				<div className="title">Transaction details</div>
				<RefreshCw onClick={reloadRequest} className={cn("refresh_icon", { rotating: loadingRequests() })} />
			</div>

			{loadingRequests() && (
				<div className="request_transaction_details_orders_loader_container">
					Loading transaction...
					<Loader2 className="request_transaction_details_orders_loader" />
				</div>
			)}

			{!loadingRequests() && (
				<div className="details">
					<div className="heading">
						<div
							onClick={() => {
								switchToTab(REQUEST_TRANSACITON_DETAILS_TABS.DETAILS);
							}}
							className={cn("title", {
								active: requestTransactionDetailsState.activeTab == REQUEST_TRANSACITON_DETAILS_TABS.DETAILS,
							})}>
							Details
						</div>

						{!isPendingAcceptance && (
							<div
								onClick={() => {
									switchToTab(REQUEST_TRANSACITON_DETAILS_TABS.POPS);
								}}
								className={cn("title", {
									active: requestTransactionDetailsState.activeTab == REQUEST_TRANSACITON_DETAILS_TABS.POPS,
								})}>
								POPs
							</div>
						)}
					</div>

					{requestTransactionDetailsState.activeTab == REQUEST_TRANSACITON_DETAILS_TABS.DETAILS && !_.isEmpty(request) && (
						<div className="body">
							<div className="top">
								<div className="row">
									<div className="col">
										<div className="lhs">Selling</div>
										<div className="rhs">
											{userIsOwnerOfOrderRequest() && (
												<>
													{request.desiredPurchaseAmountConvertedFormatted} {request.order.sellCurrency}
												</>
											)}
											{userIsOwnerOfOrder() && (
												<>
													{request.desiredPurchaseAmountConvertedFormatted} {request.order.sellCurrency}
												</>
											)}
										</div>
									</div>
									<div className="col">
										<div className="lhs">Buying</div>
										<div className="rhs">
											{userIsOwnerOfOrderRequest() && (
												<>
													{request.desiredPurchaseAmountFormatted} {request.order.buyCurrency}
												</>
											)}
											{userIsOwnerOfOrder() && (
												<>
													{request.desiredPurchaseAmountFormatted} {request.order.buyCurrency}
												</>
											)}
										</div>
									</div>
									<div className="col">
										<div className="lhs">Exchange rate</div>
										<div className="rhs">
											<p>
												1 {request.order.sellCurrency} = {request.order.volumeTypeAmount} {request.order.buyCurrency}
											</p>
											<p>
												1 {request.order.buyCurrency} = {formatAmounts(1 / request.order.volumeTypeAmount)} {request.order.sellCurrency}
											</p>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div className="lhs">Status</div>
										<div className="rhs">{request.requestState}</div>
									</div>
									<div className="col">
										{userIsOwnerOfOrder() && (
											<>
												<div className="lhs">Buyer</div>
												<div className="rhs">{request.createdByName}</div>
											</>
										)}
										{userIsOwnerOfOrderRequest() && (
											<>
												<div className="lhs">Seller</div>
												<div className="rhs">{request.order.createdByName}</div>
											</>
										)}
									</div>
									<div className="col">
										<div className="lhs"></div>
										<div className="rhs"></div>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div className="lhs">Account holder name</div>
										<div className="rhs">
											{userIsOwnerOfOrder() && <>{request.createdByName}</>}
											{userIsOwnerOfOrderRequest() && <>{request.order.createdByName}</>}
										</div>
									</div>
									<div className="col">
										<div className="lhs">Email</div>
										{userIsOwnerOfOrderRequest() && (
											<>
												{(request.order.paymentMethods || []).map((method, index) => (
													<div key={index}>
														<div className="rhs">{method.email ? method.email : ""}</div>
													</div>
												))}
											</>
										)}
										{userIsOwnerOfOrder() && (
											<>
												{(request.paymentMethods || []).map((method, index) => (
													<div key={index}>
														<div className="rhs">{method.email ? method.email : ""}</div>
													</div>
												))}
											</>
										)}
									</div>
									<div className="col">
										<div className="lhs">Payment method</div>
										<div className="rhs">
											{userIsOwnerOfOrderRequest() && (
												<>
													{!request.order.depositAmounts || request.order.depositAmounts.length === 0
														? (request.order.paymentMethods || []).map((paymentMethod) => (
																<div className="payment_method" key={paymentMethod.id}>
																	{paymentMethod.type == PAYMENT_METHOD_TYPES.BANK_ACCOUNT && <div className="name">{paymentMethod.bankName}</div>}
																	{paymentMethod.type == PAYMENT_METHOD_TYPES.PHONE_NUMBER && <div className="name">{paymentMethod.phonenumberLabel}</div>}
																	{paymentMethod.type == PAYMENT_METHOD_TYPES.WALLET_ADDRESS && <div className="name">{paymentMethod.walletAddressLabel}</div>}
																	<div className="details"> {request.desiredPurchaseAmountFormatted} </div>
																</div>
														  ))
														: (request.order.depositAmounts || []).map((deposit) => (
																<div className="payment_method" key={deposit.paymentMethod.id}>
																	{deposit.paymentMethod.type == PAYMENT_METHOD_TYPES.BANK_ACCOUNT && (
																		<div className="name">{deposit.paymentMethod.bankName}</div>
																	)}
																	{deposit.paymentMethod.type == PAYMENT_METHOD_TYPES.PHONE_NUMBER && (
																		<div className="name">{deposit.paymentMethod.phonenumberLabel}</div>
																	)}
																	{deposit.paymentMethod.type == PAYMENT_METHOD_TYPES.WALLET_ADDRESS && (
																		<div className="name">{deposit.paymentMethod.walletAddressLabel}</div>
																	)}
																	<div className="details">
																		{deposit.instruction} {deposit.paymentMethod.value.currency}
																	</div>
																</div>
														  ))}
												</>
											)}
											{userIsOwnerOfOrder() && (
												<>
													{!request.depositAmounts || request.depositAmounts.length === 0
														? (request.paymentMethods || []).map((paymentMethod) => (
																<div className="payment_method" key={paymentMethod.id}>
																	<div className="name">{paymentMethod.type === "BANK_ACCOUNT" ? paymentMethod.bankName : paymentMethod.phonenumberLabel}</div>
																	<div className="details"> {request.desiredPurchaseAmountConvertedFormatted} </div>
																</div>
														  ))
														: (request.depositAmounts || []).map((deposit) => (
																<div className="payment_method" key={deposit.paymentMethod.id}>
																	<div className="name">
																		{deposit.paymentMethod.type === "BANK_ACCOUNT"
																			? deposit.paymentMethod.bankName
																			: deposit.paymentMethod.phonenumberLabel || deposit.paymentMethod.label}
																	</div>
																	<div className="details">
																		{deposit.instruction} {deposit.paymentMethod.value.currency}
																	</div>
																</div>
														  ))}
												</>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="bottom">
								<div className="timeline">
									<div className="event">
										<div className="indicator completed">
											<div className="dot_container">
												<div className="dot"></div>
											</div>
											<div className="line"></div>
										</div>
										<div className="info">
											<div className="event_header">Order placed</div>

											{userIsSeller() && (
												<div className="event_body">
													The buyer placed an order for {request.desiredPurchaseAmountFormatted} {request.order.buyCurrency} at a rate of{" "}
													{request.order.volumeTypeAmount} {request.order.buyCurrency}
												</div>
											)}

											{userIsBuyer() && (
												<div className="event_body">
													You placed an order for {request.desiredPurchaseAmountFormatted} {request.order.buyCurrency} at a rate of{" "}
													{request.order.volumeTypeAmount} {request.order.buyCurrency}
												</div>
											)}
										</div>
									</div>

									{isPendingAcceptance && (
										<>
											<div className="event">
												<div
													className={cn("indicator", {
														completed: isPendingAcceptance,
													})}>
													<div className="dot_container">
														<div className="dot"></div>
													</div>
													<div className="line"></div>
												</div>

												<div className="info">
													<div className="event_header">Waiting for Acceptance</div>

													{userIsSeller() && <div className="event_body">Waiting for you to accept the request</div>}
													{userIsBuyer() && <div className="event_body">Waiting for the seller to accept the request</div>}
												</div>
											</div>
										</>
									)}

									{!isPendingAcceptance && (
										<>
											<div className="event">
												<div
													className={cn("indicator", {
														completed: !isPendingAcceptance,
													})}>
													<div className="dot_container">
														<div className="dot"></div>
													</div>
													<div className="line"></div>
												</div>

												<div className="info">
													<div className="event_header">Order Accepted</div>

													{userIsSeller() && <div className="event_body">You accepted the order</div>}
													{userIsBuyer() && <div className="event_body">The seller accepted the order</div>}
												</div>
											</div>
											<div className="event">
												<div
													className={cn("indicator", {
														completed: buyerPopCompleted(),
													})}>
													<div className="dot_container">
														<div className="dot"></div>
													</div>
													<div className="line"></div>
												</div>
												<div className="info">
													<div className="event_header">Buyer's Proof of Payment</div>

													{userIsSeller() && (
														<>
															<div className="event_body">
																The buyer will pay {request.desiredPurchaseAmount} {request.order.buyCurrency} to your account and upload proof of payment
															</div>

															{requestTransactionDetailsState.buyerProofOfPayments.length > 0 && !buyerPopCompleted() && (
																<div className="actions">
																	<div className="view_verify_container">
																		<div className="view action" onClick={onUploadProofOfPayment}>
																			View
																		</div>
																		<div className="verify action" onClick={onUploadProofOfPayment}>
																			Verify
																		</div>
																	</div>
																</div>
															)}
														</>
													)}

													{userIsBuyer() && (
														<>
															<div className="event_body">
																Pay {request.desiredPurchaseAmount} {request.order.buyCurrency} to the seller's account and upload proof of payment
															</div>
															<div className="actions">
																{requestTransactionDetailsState.buyerProofOfPayments.length == 0 && (
																	<div className="start_container">
																		<div className="upload action" onClick={onUploadProofOfPayment}>
																			Upload proof of payment
																		</div>
																	</div>
																)}

																{requestTransactionDetailsState.buyerProofOfPayments.length > 0 && !buyerPopCompleted() && (
																	<div className="view_verify_container">
																		<div className="view action" onClick={onUploadProofOfPayment}>
																			View{" "}
																		</div>
																		<div className="upload action" onClick={onUploadProofOfPayment}>
																			Upload
																		</div>
																	</div>
																)}
															</div>
														</>
													)}

													{requestTransactionDetailsState.buyerProofOfPayments.length > 0 && (
														<div className="upload_status">
															<div className="info">
																<div className="header">Uploaded</div>
																<div className="body">
																	{formatAmounts(requestTransactionDetailsState.buyerUploadedAmountVerifed)} /{" "}
																	{formatAmounts(requestTransactionDetailsState.buyerUploadedAmountTotal)}
																</div>
															</div>
															<div className="upload_progress">
																<CircularProgressbar
																	value={
																		(requestTransactionDetailsState.buyerUploadedAmountVerifed / requestTransactionDetailsState.buyerUploadedAmountTotal) * 100
																	}
																	strokeWidth={14}
																	styles={buildStyles({
																		pathColor: `#3E9CF3`,
																		trailColor: "#fff",
																	})}
																/>
															</div>
														</div>
													)}

													{requestTransactionDetailsState.buyerProofOfPayments.length > 0 && buyerPopCompleted() && (
														<div className="verified_container">
															<span className="label">Verified</span>
															{/* <span className="amount">10,000 / 10,000</span> */}
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="24"
																height="24"
																viewBox="0 0 24 24"
																fill="none"
																stroke="currentColor"
																stroke-width="2"
																stroke-linecap="round"
																stroke-linejoin="round"
																class="lucide lucide-circle-check-big checkmark">
																<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
																<path d="m9 11 3 3L22 4" />
															</svg>
														</div>
													)}
												</div>
											</div>
											<div className="event">
												<div
													className={cn("indicator", {
														completed: sellerPopCompleted(),
													})}>
													<div className="dot_container">
														<div className="dot"></div>
													</div>
													<div className="line"></div>
												</div>
												<div className="info">
													<div className="event_header">Seller's Proof of Payment verification</div>

													{userIsBuyer() && (
														<>
															<div className="event_body">
																<p>
																	The seller is going to pay {request.desiredPurchaseAmountConvertedFormatted} {request.order.sellCurrency} to your account and
																	upload proof of payment
																</p>
															</div>
															<div className="actions">
																{requestTransactionDetailsState.sellerProofOfPayments.length > 0 && !sellerPopCompleted() && (
																	<div className="view_verify_container">
																		<div className="view action" onClick={onUploadProofOfPayment}>
																			View
																		</div>
																		<div className="verify action" onClick={onUploadProofOfPayment}>
																			Verify
																		</div>
																	</div>
																)}
															</div>
														</>
													)}

													{userIsSeller() && (
														<>
															<div className="event_body">
																<p>
																	Pay {request.desiredPurchaseAmountConvertedFormatted} {request.order.sellCurrency} to the buyer's account and upload proof of
																	payment
																</p>
															</div>
															<div className="actions">
																{requestTransactionDetailsState.sellerProofOfPayments.length == 0 && (
																	<div className="start_container">
																		<div className="upload action" onClick={onUploadProofOfPayment}>
																			Upload proof of payment
																		</div>
																	</div>
																)}

																{requestTransactionDetailsState.sellerProofOfPayments.length > 0 && !sellerPopCompleted() && (
																	<div className="view_verify_container">
																		<div className="view action" onClick={onUploadProofOfPayment}>
																			View
																		</div>
																		<div className="upload action" onClick={onUploadProofOfPayment}>
																			Upload
																		</div>
																	</div>
																)}
															</div>
														</>
													)}

													{requestTransactionDetailsState.sellerProofOfPayments.length > 0 && (
														<div className="upload_status">
															<div className="info">
																<div className="header">Uploaded</div>
																<div className="body">
																	{formatAmounts(requestTransactionDetailsState.sellerUploadedAmountVerifed)} /{" "}
																	{formatAmounts(requestTransactionDetailsState.sellerUploadedAmountTotal)}
																</div>
															</div>
															<div className="upload_progress">
																<CircularProgressbar
																	value={
																		(requestTransactionDetailsState.sellerUploadedAmountVerifed / requestTransactionDetailsState.sellerUploadedAmountTotal) *
																		100
																	}
																	strokeWidth={14}
																	styles={buildStyles({
																		pathColor: `#3E9CF3`,
																		trailColor: "#fff",
																	})}
																/>
															</div>
														</div>
													)}

													{requestTransactionDetailsState.sellerProofOfPayments.length > 0 && sellerPopCompleted() && (
														<div className="verified_container">
															<span className="label">Verified</span>
															{/* <span className="amount">10,000 / 10,000</span> */}
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="24"
																height="24"
																viewBox="0 0 24 24"
																fill="none"
																stroke="currentColor"
																stroke-width="2"
																stroke-linecap="round"
																stroke-linejoin="round"
																class="lucide lucide-circle-check-big checkmark">
																<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
																<path d="m9 11 3 3L22 4" />
															</svg>
														</div>
													)}
												</div>
											</div>
											<div className="event">
												<div
													className={cn("indicator", {
														completed: transactionCompleted(),
													})}>
													<div className="dot_container">
														<div className="dot"></div>
													</div>
													<div className="line"></div>
												</div>
												<div className="info">
													<div className="event_header">Transaction completed</div>
													{transactionCompleted() && (
														<div className="completed_container">
															<span className="label">Completed</span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="24"
																height="24"
																viewBox="0 0 24 24"
																fill="none"
																stroke="currentColor"
																stroke-width="2"
																stroke-linecap="round"
																stroke-linejoin="round"
																class="lucide lucide-circle-check-big checkmark">
																<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
																<path d="m9 11 3 3L22 4" />
															</svg>
														</div>
													)}
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					)}

					{requestTransactionDetailsState.activeTab == REQUEST_TRANSACITON_DETAILS_TABS.POPS && <Pops />}

					<div className="bottom_spacer"></div>
				</div>
			)}
		</div>
	);
};
export default RequestTransactionDetails;
