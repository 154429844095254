import "./dealrooms.scss";
import { useRef } from "react";
import NewThread from "./newthread/newthread";
import Messages from "./messages/messages";
import Threads from "./threads/threads";
import { ChatProvider } from "./providers/ChatProvider";

const Dealrooms = () => {
  const newThreadBtn = useRef(null);
  const newThreadPopover = useRef(null);

  const positionNewThreadPopover = () => {
    const btn = newThreadBtn.current;
    const window = newThreadPopover.current;

    window.style.top = `${btn.offsetTop + btn.offsetHeight + 5}px`;
    window.style.left = `${btn.offsetLeft - (300 / 2 - btn.offsetWidth / 2)}px`;
  };

  return (
    <div className="dealrooms_page">
      <div className="dealrooms_heading">Deal rooms</div>
      <div className="chatbox">
        <div className="chat_threads">
          <div id="chat_threads_heading">
            <div className="title">Chats</div>
            <button ref={newThreadBtn} onClick={positionNewThreadPopover} id="new_thread_control" popovertarget="new_thread_popover" popovertargetaction="show">
              +
            </button>
            <div ref={newThreadPopover} id="new_thread_popover" popover="auto">
              <NewThread />
            </div>
          </div>
          <Threads />
        </div>
        <Messages />
      </div>
    </div>
  );
};
export default Dealrooms;
