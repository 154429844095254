import { X } from "lucide-react";
import "./addpaymentmethodsuccess.scss";
import MudaCheckIcon from "../../../kyc/common/MudaCheckIcon/MudaCheckIcon";
import { useTransact, useTransactDispatch } from "../../providers/TransactProvider";
import { useCreateRequestDispatch , useCreateRequest} from "../../../transactionDetails/createRequest/providers/createRequestProvider";
const AddPaymentMethodSuccess = () => {
  const { addPaymentMethodSuccessNext } = useTransact();
  const transactDispatch = useTransactDispatch();
  const createRequestDispatch = useCreateRequestDispatch();
  const {addPaymentMethodSuccessNextRequestPage} = useCreateRequest()

  const goToNext = () => {
    transactDispatch({
      type: addPaymentMethodSuccessNext
    });
    createRequestDispatch({
      type: addPaymentMethodSuccessNextRequestPage
    })
  };

  return (
    <div id="addpaymentmethodsuccess_component">
      <div className="addpaymentmethodsuccess_controls">
        <X className="addpaymentmethodsuccess_controls_close" onClick={goToNext} />
      </div>
      <div className="addpaymentmethodsuccess_details">
        <div className="addpaymentmethodsuccess_details_title">Success!</div>
        <div className="addpaymentmethodsuccess_details_subtitle">Your new payment method has been added</div>
        <div className="addpaymentmethodsuccess_details_icon">
          <MudaCheckIcon />
        </div>
        <div className="addpaymentmethodsuccess_details_ok" onClick={goToNext}>
          ok
        </div>
      </div>
    </div>
  );
};
export default AddPaymentMethodSuccess;
