import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { useAuth } from "./AuthProvider";
import api from "../api";

const WalletsContext = createContext();
const WalletsDispatchContext = createContext();

export const WalletsProvider = ({ children }) => {
  const { authTokens, isLoggedIn } = useAuth();

  const getWallets = useQuery({
    queryKey: ["wallets"],
    queryFn: () => {
      walletsDispatch({
        type: "fetchingWallets"
      });
      return api.getWallets({ token: authTokens.IdToken });
    },
    enabled: false
  });

  const [walletsState, walletsDispatch] = useReducer(walletsReducer, {
    wallets: [],
    isLoadingWallets: false
  });

  const getWallet = ({currency}) => {
    const wallet = walletsState.wallets.find((w) => w.currency === currency);
    return wallet;
  };

  //get wallets
  useEffect(() => {
    (async () => {
      // fetch wallets if logged in
      if (isLoggedIn) {
        const walletsRefetchResult = await getWallets.refetch();
        if (walletsRefetchResult.status == "success") {
          const wallets = walletsRefetchResult.data.data.data.map((datum) => datum.attributes);
          walletsDispatch({
            type: "fetchedWallets",
            wallets
          });
        }
      }
    })();
  }, [isLoggedIn]);

  return (
    <WalletsContext.Provider value={{ ...walletsState, getWallet }}>
      <WalletsDispatchContext.Provider value={walletsDispatch}>{children}</WalletsDispatchContext.Provider>
    </WalletsContext.Provider>
  );
};

export const useWallets = () => {
  return useContext(WalletsContext);
};

export const useWalletsDispatch = () => {
  return useContext(WalletsDispatchContext);
};

const walletsReducer = (walletsState, action) => {
  switch (action.type) {
    case "fetchedWallets":
      return { ...walletsState, wallets: action.wallets, isLoadingWallets: false };
    case "fetchingWallets":
      return { ...walletsState, isLoadingWallets: true };
    default: {
      throw Error("[walletsReducer] Unknown action: " + action.type);
    }
  }
};
