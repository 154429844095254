import { useEffect } from "react";
import _ from "lodash";
import { Camera, ChevronRight, Search, User, Users, X, Loader2, CheckIcon } from "lucide-react";
import "./newthread.scss";
import { useDebounce } from "../../../usehooks/usehooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../api";
import { useAuth } from "../../../providers/AuthProvider";
import { useProfile } from "../../../providers/ProfileProvider";
import { useChat, useChatDispatch } from "../providers/ChatProvider";

const NewThread = () => {
	const { authTokens } = useAuth();
	const { profiles, criteria, getThreads, selectedProfiles, checked, name, addNewDeal, addParticipants } = useChat();
	const chatDispatch = useChatDispatch();
	const queryClient = useQueryClient();

	const debouncedSearchTerm = useDebounce(criteria, 300);
	const { profile: myProfile } = useProfile();

	// mutation for searching a profile
	const searchForProfile = useMutation({
		mutationFn: ({ criteria, token }) => {
			return api.searchForProfile({ criteria, token });
		},
		onError: (error, variables, context) => {},
		onSuccess: async (data) => {
			const newProfiles = data.data.data.map((data) => data.attributes);
			chatDispatch({ type: "setProfiles", profiles: newProfiles });
		},
	});

	// create inidividual thread
	const createIndividualThread = useMutation({
		mutationFn: ({ name, selectedProfileId, token }) =>
			api.createIndividualThread({
				name,
				selectedProfileId,
				token,
			}),
		onError: (error, variables, context) => {},
		onSuccess: async (data, variables, context) => {
			// Fetch new threads
			chatDispatch({ type: "setIsFetchingThreads", isFetchingThreads: true });
			const threadsRefetchResult = await getThreads.refetch();
			const _threads = threadsRefetchResult.data.data.data.map((datum) => datum.attributes);

			// set new threads
			chatDispatch({ type: "setThreads", threads: _threads });

			// set selected thread
			const thread = data.data.data.attributes;
			chatDispatch({ type: "setSelectedThread", thread });

			// set fetching threads to done
			chatDispatch({ type: "setIsFetchingThreads", isFetchingThreads: false });

			// set creating thread to done
			chatDispatch({ type: "setIsCreatingThread", isCreatingThread: false });

			// close new thread popover
			document.getElementById("new_thread_popover").hidePopover();
		},
	});

	// creating a new thread function
	const createThread = useMutation({
		mutationFn: async ({ name, selectedProfiles, token }) =>
			api.createThread({
				name,
				participants: selectedProfiles.map((profile) => profile.id),
				token,
			}),
		onError: (error, variables, context) => {},
		onSuccess: async (data) => {
			document.getElementById("new_thread_popover").hidePopover();
			chatDispatch({ type: "setAddNewDeal", addNewDeal: false });
			chatDispatch({ type: "setAddParticipants", addParticipants: false });
			document.getElementById("new_thread_popover").hidePopover();
			chatDispatch({ type: "setIsFetchingThreads", isFetchingThreads: true });
			const threadsRefetchResult = await getThreads.refetch();
			const thread = data.data.data.attributes;
			const _threads = threadsRefetchResult.data.data.data.map((datum) => datum.attributes);
			chatDispatch({ type: "setThreads", threads: _threads });
			chatDispatch({ type: "setIsFetchingThreads", isFetchingThreads: false });
			chatDispatch({ type: "setSelectedThread", thread });
			chatDispatch({ type: "setIsCreatingThread", isCreatingThread: false });
		},
	});

	useEffect(() => {
		if (_.isEmpty(criteria)) {
			return;
		}

		searchForProfile.mutate({ criteria, token: authTokens.IdToken });
	}, [debouncedSearchTerm]);

	/*
    Search For Profile plan
    1. Get criteria
    2. Search for profile using criteria
    3. Display profiles returned by backend

    nice to haves
    1. is loading state
    2. no profile found state
  */

	const onSearchTermUpdated = (evt) => {
		chatDispatch({ type: "setSearchTerm", criteria: evt.target.value });
	};

	// state variable handling the deal room name
	const onDealRoomName = (evt) => {
		chatDispatch({ type: "setDealRoomName", name: evt.target.value });
	};

	// adds a selected individual to the selected profiles array and turns check to true
	const handleProfileInterestedIn = (profile) => {
		chatDispatch({
			type: "setSelectedProfiles",
			selectedProfile: profile,
		});
	};
	// removes and unchecks the selected individual from the selected profiles array
	const removeSelectedProfiles = (id) => {
		if (id) {
			chatDispatch({
				type: "removeSelectedProfile",
				id: id,
			});
			chatDispatch({ type: "setChecked", checked: false });
		}
	};

	// create individual thread
	const onCreateIndividualThread = (selectedProfile) => {
		const name = `${myProfile.username} <> ${selectedProfile.username}`;
		const selectedProfileId = selectedProfile.id;
		const token = authTokens.IdToken;
		createIndividualThread.mutate({ name, selectedProfileId, token });
	};

	return (
		<div>
			{/* If we want to create a new deal chat */}
			{!addNewDeal && (
				<>
					<div className="heading">New Chat</div>
					<div className="search">
						<Search />
						<input type="text" placeholder="Search" onChange={onSearchTermUpdated} />
					</div>

					{searchForProfile.isPending && (
						<div className="search_for_profile_loader_container">
							<Loader2 className="search_for_profile_loader" />
						</div>
					)}

					{!searchForProfile.isPending && (
						<>
							{/* If something has been searched but there are not results */}
							{!_.isEmpty(criteria) && _.isEmpty(profiles) && <div className="no_result_found">no results found</div>}

							{/* If something has been searched and there are results */}
							{!_.isEmpty(criteria) && !_.isEmpty(profiles) && (
								<div className="search_results">
									<div className="contact_list">
										<div className="heading">Contacts on Muda</div>

										{createIndividualThread.isPending && <Loader2 className="create_individual_thread_loader" />}

										{!createIndividualThread.isPending && (
											<>
												{profiles.map((profile) => (
													<div key={profile.id} className="contact" onClick={() => onCreateIndividualThread(profile)}>
														<div className="icon">
															<User />
														</div>
														<div className="name">
															{profile.firstName} {profile.lastName} {profile.id == myProfile.id && `(Me)`}
														</div>
													</div>
												))}
											</>
										)}
									</div>
								</div>
							)}

							{/* If nothing has been searched */}
							{_.isEmpty(criteria) && (
								<>
									<div onClick={() => chatDispatch({ type: "setAddNewDeal", addNewDeal: true })} className="new_dealroom">
										<div className="icon">
											<Users />
										</div>
										<span className="label">New Deal Room</span>
									</div>

									{/* <div className="frequently_contacted_list">
                    <div className="heading">Frequently Contacted</div>
                    <div className="contact">
                      <div className="icon">
                        <User />
                      </div>
                      <div className="name">Hall enoch asanda</div>
                    </div>
                    <div className="contact">
                      <div className="icon">
                        <User />
                      </div>
                      <div className="name">Hall enoch asanda</div>
                    </div>
                  </div>

                  <div className="contacts_list">
                    <div className="heading">Contacts on Muda</div>
                    <div className="contact">
                      <div className="icon">
                        <User />
                      </div>
                      <div className="name">Hall enoch asanda</div>
                    </div>
                    <div className="contact">
                      <div className="icon">
                        <User />
                      </div>
                      <div className="name">Hall enoch asanda</div>
                    </div>
                  </div> */}
								</>
							)}
						</>
					)}
				</>
			)}

			{/* If we want to add participants */}
			{addNewDeal && (
				<>
					{/* if we want to add participants to the new deal room */}
					{!addParticipants && (
						<>
							<div className="add_participants">
								<div className="new_deal_participant">
									<div onClick={() => chatDispatch({ type: "setAddNewDeal", addNewDeal: false })} className="back_text">
										Back
									</div>
									<div className="add_participate_heading">
										<div className="participate_heading">Add Participants</div>
										<div className="participate_subtitle">1/1000</div>
									</div>
									<div
										onClick={() =>
											chatDispatch({
												type: "setAddParticipants",
												addParticipants: true,
											})
										}
										className={`${selectedProfiles.length > 0 ? "next_text" : "next_text_disabled"} `}>
										Next
									</div>
								</div>

								<div className="search">
									<Search />
									<input type="text" placeholder="Search name or contact" onChange={onSearchTermUpdated} />
								</div>
								{/* loading to fetch searched profiles */}
								{searchForProfile.isPending && (
									<div className="search_for_profile_loader_container">
										<Loader2 className="search_for_profile_loader" />
									</div>
								)}
								{!searchForProfile.isPending && (
									<>
										{/* If something has been searched but there are not results */}
										{!_.isEmpty(criteria) && _.isEmpty(profiles) && <div className="no_result_found">no results found</div>}

										{/* individual selected profile to be add to the group from the selected profiles array */}
										<div className="selected_participant_profiles">
											{selectedProfiles.length !== 0 &&
												selectedProfiles?.map((profile) => (
													<div className="participant_profiles" key={profile?.id}>
														<div className="participant_profile">
															<div className="participant">
																<div className="icon">
																	<User />
																</div>
																<div className="icon_x" onClick={() => removeSelectedProfiles(profile.id)}>
																	<X />
																</div>
															</div>
															<div className="user_name">
																{profile.firstName} {profile.lastName}
															</div>
														</div>
													</div>
												))}
										</div>

										{/* If something has been searched and there are results */}
										{!_.isEmpty(criteria) && !_.isEmpty(profiles) && (
											<div className="search_results">
												<div className="contact_list">
													<div className="heading">Contacts on Muda</div>

													{profiles.map((profile) => (
														<div key={profile.id} className="contact">
															<div className="icon">
																<User />
															</div>
															<div className="right">
																<div className="name">
																	{profile.firstName} {profile.lastName} {profile.id == myProfile.id && `(Me)`}
																</div>
																<div className="check_box" onClick={() => handleProfileInterestedIn(profile)}>
																	{selectedProfiles.some((selectedProfile) => selectedProfile.id === profile.id) && <CheckIcon />}
																</div>
															</div>
														</div>
													))}
												</div>
											</div>
										)}
									</>
								)}
							</div>
						</>
					)}

					{/* create a deal with participants */}
					{addParticipants && (
						<>
							<div className="new_deal_heading">
								<div
									onClick={() =>
										chatDispatch({
											type: "setAddParticipants",
											addParticipants: false,
										})
									}
									className="back_text">
									Back
								</div>
								<div className="heading_text">New Deal Room</div>
								<div
									onClick={() =>
										createThread.mutate({
											name,
											selectedProfiles,
											token: authTokens.IdToken,
										})
									}
									className="create_group_button">
									{createThread.isPending ? (
										<div className="create_group_loader_container">
											<Loader2 className="create_group_loader" />
										</div>
									) : (
										" Create"
									)}
								</div>
							</div>
							<div className="input_div">
								<div className="icon">
									<Camera />
								</div>
								<input type="text" placeholder="Deal Room Name (optional)" onChange={onDealRoomName} />
							</div>
							<div className="message_div">
								<div className="message_one">
									<div className="text">Disappearing Messages</div>
									<div className="right">
										<div className="text">Off</div>
										<ChevronRight />
									</div>
								</div>
								<div className="message_two">
									<div className="text">Deal Permissions</div>
									<ChevronRight />
								</div>
							</div>
							<div className="participant">
								<div className="heading">PARTICIPANTS: 1 of {selectedProfiles.length}</div>
								{checked &&
									selectedProfiles.length !== 0 &&
									selectedProfiles?.map((profile) => (
										<div key={profile.id} className="parent_user_div">
											<div className="user_div">
												<div className="icon">
													<User />
												</div>
												<div className="icon_x">
													<X />
												</div>
											</div>
											<div className="user_name">
												{profile.firstName} {profile.lastName}
											</div>
										</div>
									))}
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default NewThread;
