import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useReducer } from "react";
import { useAuth } from "../../../../../providers/AuthProvider";
import _ from "lodash";
import api from "../../../../../api";
import { formatAmount } from "../../../../../common/formatAmount";
import Big from "big.js";
import moment from "moment";

const CompletedOrdersReportContext = createContext();
const CompletedOrdersReportDispatchContext = createContext();

export const COMPLETED_ORDERS_REPORT_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
};

export const CompletedOrdersReportProvider = ({ children }) => {
	const { authTokens } = useAuth();

	const getCompletedOrdersReport = useQuery({
		queryKey: ["completedOrdersReport"],
		queryFn: () => {
			return api.getCompletedOrdersReport({ token: authTokens.IdToken });
		},
		enabled: false,
	});

	const fetchCompletedOrdersReport = async () => {
		completedOrdersReportDispatch({ type: COMPLETED_ORDERS_REPORT_ACTIONS.updateState, key: "isFetchingCompletedOrdersReport", value: true });
		const completedOrdersReportRefetchResult = await getCompletedOrdersReport.refetch();
		completedOrdersReportDispatch({ type: COMPLETED_ORDERS_REPORT_ACTIONS.updateState, key: "isFetchingCompletedOrdersReport", value: false });
		if (completedOrdersReportRefetchResult.status == "success") {
			let completedOrdersReport = completedOrdersReportRefetchResult.data.data.data;
			completedOrdersReport = completedOrdersReport.map((_) => {
				const order = _.attributes;
				const txn = order._rawTxn;
				const txnType = order._rawTxnType;
				order.transactionID = order.transactionID.split("-")[0];

				//Clean up manual transaction
				if (txnType == "manual") {
					order.price = `1 ${txn.sellCurrency} / ${formatAmount(txn.sellRate)} ${txn.buyCurrency}`;
					order.amount = `${formatAmount(txn.sellVolume)} ${txn.buyCurrency}`;
					order.total = `${formatAmount(txn.deliveredVolume)} ${txn.sellCurrency}`;
					order.date = moment(txn.date).format("DD/MM/YYYY - h:mm A");
				}

				//Clean up otctrade transaction
				if (txnType == "otctrade") {
					order.pair = `${txn.order.buyCurrency} - ${txn.order.sellCurrency}`;
					order.price = `1 ${txn.order.sellCurrency} / ${formatAmount(txn.order.volumeTypeAmount)} ${txn.order.buyCurrency}`;
					order.amount = `${formatAmount(txn.desiredPurchaseAmount)} ${txn.order.buyCurrency}`;
					const total = Big(txn.desiredPurchaseAmount).div(Big(txn.order.volumeTypeAmount)).toString();
					order.total = `${formatAmount(total)} ${txn.order.sellCurrency}`;
					order.date = moment(txn.createdAt).format("DD/MM/YYYY - h:mm A");
				}

				return order;
			});

			completedOrdersReportDispatch({ type: COMPLETED_ORDERS_REPORT_ACTIONS.updateState, key: "completedOrdersReport", value: completedOrdersReport });
		}
	};

	const [completedOrdersReportState, completedOrdersReportDispatch] = useReducer(CompletedOrdersReportReducer, {
		completedOrdersReport: [],
		isFetchingCompletedOrdersReport: false,
		fetchCompletedOrdersReport,
		getCompletedOrdersReport,
	});

	return (
		<CompletedOrdersReportContext.Provider value={completedOrdersReportState}>
			<CompletedOrdersReportDispatchContext.Provider value={completedOrdersReportDispatch}>{children}</CompletedOrdersReportDispatchContext.Provider>
		</CompletedOrdersReportContext.Provider>
	);
};

export const useCompletedOrdersReport = () => {
	return useContext(CompletedOrdersReportContext);
};

export const useCompletedOrdersReportDispatch = () => {
	return useContext(CompletedOrdersReportDispatchContext);
};

const CompletedOrdersReportReducer = (completedOrdersReportState, action) => {
	switch (action.type) {
		case COMPLETED_ORDERS_REPORT_ACTIONS.updateState: {
			const newState = { ...completedOrdersReportState };
			newState[action.key] = action.value;
			return newState;
		}
		case COMPLETED_ORDERS_REPORT_ACTIONS.updateStates: {
			const newState = { ...completedOrdersReportState };
			action.states.forEach(({ key, value }) => {
				newState[key] = value;
			});
			return newState;
		}
		default: {
			throw Error("[CompletedOrdersReportReducer] Unknown action: " + action.type);
		}
	}
};
