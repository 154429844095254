import "./paymentmethods.scss";
const Paymentmethods = () => {
	return (
		<div className="accountsettings_paymentmethods_page">
			<div className="paymentmethods_heading">Payment methods</div>
			<div className="paymentmethods_tabs">
				<div className="payment_methods_tabs_header">
					<div className="payment_methods_tabs_header_tab active">My accounts</div>
					<div className="payment_methods_tabs_header_tab">3rd party account</div>
				</div>
				<div className="payment_methods_tabs_body"></div>
			</div>
		</div>
	);
};
export default Paymentmethods;
