import React from 'react';
import './startResetPassword.scss';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import cn from "classnames";
import api from "../api";
import Header from "../header/header";
import { useMutation } from "@tanstack/react-query";
import { Loader2, Info } from "lucide-react";

const StartResetPassword = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();


	const startResetPassword = useMutation({
		mutationFn: (data) => {
			return api.startResetPassword({email:data.email});
		},
		onError: (error, variables, context) => {
		},
		onSuccess: (data, variables, context) => {
			const email = variables.email
			navigate(`/reset/password?email=${email}`);
		},
	});
    const onSubmit = (data) => {
        
		startResetPassword.mutate(data);
	};


  return (
    <>
    <Header></Header>
    <div className="start_reset_password_page">
        <div className="start_reset_password_body_container">
            <div className="start_reset_password_body">
            <div className="start_reset_password_form">
					<div className="start_reset_password_header"> Enter your email</div>
					<div className="start_reset_password_subheading">A code will be sent to your email for verification purposes</div>
					<div className="start_reset_password_input_group email">
						<label className="start_reset_password_input">
							<span className="start_reset_password_input_label">
								Email <span className="required"></span>
							</span>
							<input
								placeholder="Enter your email"
								name="email"
								className={cn({
									error: errors.email?.type == "required" || startResetPassword.isError,
								})}
								{...register("email", { required: true })}
							/>
							{errors.email?.type === "required" && (
								<span className="start_reset_password_input_error">
									<Info className="start_reset_password_input_error_icon" /> Email is required
								</span>
							)}
						</label>
					</div>
					<div className="spacer"></div>
					<div className="spacer"></div>
					<div className="start_reset_password_btn_box">
						<div className={cn("start_reset_password_btn", { is_reseting_password: startResetPassword.isPending })} onClick={handleSubmit(onSubmit)}>
							{startResetPassword.isPending && <Loader2 className="start_reset_password_loader" />}
							{!startResetPassword.isPending && <span>Send Code</span>}
						</div>
					</div>
					
				</div>
            </div>
        </div>
      
    </div></>
    
  );
};

export default StartResetPassword;