import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "./identityVerification.scss";
import { useProfile } from "../../../providers/ProfileProvider";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../../providers/AuthProvider";
import api from "../../../api";
const VerifyIdentity = ({ onMyAccountClick }) => {
	const { authTokens } = useAuth();
	const token = authTokens.IdToken;
	const phonenumberVerificationForm = useForm();
	const verifyPhoneNumberDialogRef = useRef(null);

	const startVerifyPhoneNumber = useMutation({
		mutationFn: ({ token }) => {
			return api.startVerifyPhonenumber({ token });
		},
		onError: (error, variables, context) => {},
		onSuccess: (data, variables, context) => {
			verifyPhoneNumberDialogRef.current.showModal();
		},
	});

	const verifyPhonenumber = useMutation({
		mutationFn: ({ token, code }) => {
			return api.verifyPhonenumber({ token, code });
		},
		onError: (error, variables, context) => {},
		onSuccess: async (data, variables, context) => {
			await getProfile.refetch();
			setProfile({ ...profile, phonenumberVerified: "true" });
			verifyPhoneNumberDialogRef.current.close();
			phonenumberVerfiedDialogRef.current.showModal();
		},
	});

	const onStartVerifyPhoneNumber = async () => {
		startVerifyPhoneNumber.mutate({ token });
	};

	const onVerifyPhonenumber = async ({ code }) => {
		verifyPhonenumber.mutate({ code, token });
	};

	const onPhonenumberVerifiedNext = async ({ code }) => {
		phonenumberVerfiedDialogRef.current.close();
	};

	return (
		<div className="identity_verification_page">
			<div className="identity_verification_heading">Verify identity</div>
			<div className="identity_verification_sub_heading">
				Please complete your Account details in
				<span className="my_acct_link" onClick={onMyAccountClick}>
					<a href="#my-account"> "My account" </a>
				</span>
				section first to proceed with the KYC process.
			</div>
			<div className="identity_verification_levels">
				<div className="identity_verification_level level_1">
					<div className="identity_verification_level_heading level_1_heading">Level 1</div>
					<div className="identity_verification_level_info level_1_info">
						<p>
							Buy and sell crypto <span className="bold">up to USD worth</span> of cryptocurrency each month
						</p>
						<p>
							Deposit and receive an <span className="bold">unlimited</span> amount of USD and any other coins
						</p>
						<p>
							Withdraw <span className="bold">up to 50000 USD</span> each month
						</p>
					</div>
					<div className="identity_verification_level_controls level_1_controls">
						{/*{profile.phonenumberVerified != "true" && (*/}
						<div className="identity_verification_level_control level_1_control verify_phone_number" onClick={onStartVerifyPhoneNumber}>
							Verify Mobile Number
							{/*{!startVerifyPhoneNumber.isPending &&
                      "Verify Mobile Number"}
                    {startVerifyPhoneNumber.isPending && (
                      <>
                        <Loader2 className="updating_profile_loader" />
                      </>
                    )}*/}
						</div>
						{/* )}*/}

						{/*{profile.phonenumberVerified == "true" && (
                  <div className="identity_verification_level_indicator level_1_indicator phone_number_verifed">
                    <MudaCheckIcon /> Verified
                  </div>
                )}*/}

						<dialog className="verify_phonenumber_dialog_container" ref={verifyPhoneNumberDialogRef}>
							<div className="verify_phonenumber_dialog_heading">Verify mobile number</div>
							<div className="verify_phonenumber_dialog_sub_heading">Please enter the code that has been sent to your mobile number</div>
							<input
								type="text"
								name="code"
								placeholder="Enter the code"
								className="verify_phonenumber_dialog_input code"
								{...phonenumberVerificationForm.register("code", {
									required: true,
								})}
							/>
							<div className="verify_phonenumber_dialog_controls">
								{(phonenumberVerificationForm.formState.errors.code?.type === "required" || verifyPhonenumber.isError) && (
									<span className="verify_phonenumber_dialog_error">
										<Info className="verify_phonenumber_dialog_error_icon" />
										Code is invalid
									</span>
								)}

								<div className="verify_phonenumber_dialog_control verify" onClick={phonenumberVerificationForm.handleSubmit(onVerifyPhonenumber)}>
									{!verifyPhonenumber.isPending && "Verify"}
									{verifyPhonenumber.isPending && (
										<>
											<Loader2 className="verify_phonenumber_dialog_loader" /> ...verifying
										</>
									)}
								</div>
							</div>
						</dialog>

						{/*<dialog
                  className="phonenumber_verified_dialog_container"
                  ref={phonenumberVerfiedDialogRef}>
                  <div className="phonenumber_verified_dialog_heading">
                    Your number has been verified
                  </div>

                  <MudaCheckIcon />

                  <div className="phonenumber_verified_dialog_controls">
                    <div
                      className="phonenumber_verified_dialog_control next"
                      onClick={onPhonenumberVerifiedNext}>
                      Next
                    </div>
                  </div>
                      </dialog>*/}
					</div>
				</div>
				<div className="level_divider"></div>
				<div className="identity_verification_level level_2">
					{/*cn("identity_verification_level level_2", {
                disabled: profile.phonenumberVerified != "true",
              })*/}
					<div className="identity_verification_level_heading level_2_heading">Level 2</div>
					<div className="identity_verification_level_info level_2_info">
						<p>Buy and sell an amount of cryptocurrency each month</p>
						<p>Deposit, withdraw, send and receive an unlimited amount of cryptocurrency each month</p>
						<p>Withdraw up to unlimited USD each month</p>
					</div>
					<div className="identity_verification_level_controls level_2_controls">
						<div className="identity_verification_level_control level_2_control verify_with_unifid">Verify with UNIFID</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default VerifyIdentity;
