import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './fileUpload.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Info } from "lucide-react";

function FileUpload({ allowedFileTypes }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileChange = (file) => {
    // Check if the selected file type is allowed
    if (file && allowedFileTypes && allowedFileTypes.length > 0) {
      const fileType = file.type.split('/')[1];
      if (!allowedFileTypes.includes(fileType)) {
        // Handle invalid file type
        setErrorMessage('Invalid file type. Please select a valid file.');
        setSelectedFile(null); // Clear selected file
        return;
      }
    }
    setErrorMessage('');
    setSelectedFile(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileChange(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInputClick = (e) => {
    // Prevent the drop area's click event from firing when the file input is clicked
    e.stopPropagation();
  };

  const handleResetFile = () => {
    // Reset the selected file when the drop area is clicked again
    setSelectedFile(null);
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className={`drop-area ${selectedFile ? 'has-file' : ''}`}
    >
      {selectedFile ? (
        <div className="selected-file-container" onClick={handleResetFile}>
          <p>Selected file: {selectedFile.name}</p>
        </div>
      ) : (
        <div className="file-input-container">
          <div className="init">
            <p>Drag & Drop or browse the file</p>
            <label htmlFor="file-input" className="file-input-label">
              <input
                type="file"
                id="file-input"
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e.target.files[0])}
                accept={allowedFileTypes && allowedFileTypes.length > 0 ? allowedFileTypes.join(',') : undefined}
              />
              <span className="file-input-icon">
                <FontAwesomeIcon icon={faFileArrowUp} onClick={handleFileInputClick}/>
              </span>
            </label>
          </div>
          {errorMessage && (
            <span className="error-message">
              <Info className="signup_input_error_icon" />{errorMessage}
            </span>
          )}
        </div>
      )}
    </div>
  );
}

FileUpload.propTypes = {
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
};

export default FileUpload;
