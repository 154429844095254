import { useState } from "react";
import cn from "classnames";
import "./myactivity.scss";
import MyOrders from "./myOrders/myOrders";
import Transactions from "./transactions/transactions";

const MyActivity = () => {
	const MY_ACTIVITY_TABS = {
		MY_ORDERS: "MY_ORDERS",
		TRANSACTIONS: "TRANSACTIONS",
	};
	const emptyMyActivityState = {
		activeTab: MY_ACTIVITY_TABS.MY_ORDERS,
	};
	const [myActivityState, setMyActivityState] = useState(emptyMyActivityState);

	const switchToTab = (tab) => {
		const newMyActivityState = { ...myActivityState };
		newMyActivityState.activeTab = tab;
		setMyActivityState(newMyActivityState);
	};

	return (
		<div id="myactivity_page">
			<div className="heading">
				<div className="title">My Activity</div>
			</div>
			<div className="body">
				<div className="myactivity_tab_list">
					<div
						onClick={() => {
							switchToTab(MY_ACTIVITY_TABS.MY_ORDERS);
						}}
						className={cn("tab", { active: myActivityState.activeTab == MY_ACTIVITY_TABS.MY_ORDERS })}>
						My Orders
					</div>
					<div
						onClick={() => {
							switchToTab(MY_ACTIVITY_TABS.TRANSACTIONS);
						}}
						className={cn("tab", { active: myActivityState.activeTab == MY_ACTIVITY_TABS.TRANSACTIONS })}>
						Transactions
					</div>
				</div>
				<div className="tab_content">
					{myActivityState.activeTab == MY_ACTIVITY_TABS.MY_ORDERS && <MyOrders />}
					{myActivityState.activeTab == MY_ACTIVITY_TABS.TRANSACTIONS && <Transactions />}
				</div>
			</div>
		</div>
	);
};
export default MyActivity;
