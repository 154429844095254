import { useEffect } from "react";
import "./outstandingBalance.scss";
import { Loader2 } from "lucide-react";
import { useOutstandingBalanceReport } from "./providers/outstandingBalanceReportProvider";
import { useAuth } from "../../../../providers/AuthProvider";
import { useTransactionDetailsDispatch } from "../../../transactionDetails/providers/transactionDetailsProvider";
import { useNavigate } from "react-router-dom";

const OutstandingBalance = () => {
	const { isLoggedIn } = useAuth();
	const { fetchOutstandingBalanceReport, isFetchingOutstandingBalanceReport, outstandingBalanceReport } = useOutstandingBalanceReport();

	const transactionDetailsDispatch = useTransactionDetailsDispatch();
	const navigate = useNavigate();

	//Get outstandingBalance report
	useEffect(() => {
		(async () => {
			// fetch outstandingBalance report if logged in
			if (isLoggedIn) {
				await fetchOutstandingBalanceReport();
			}
		})();
	}, [isLoggedIn]);

	const viewTxn = ({ txn }) => {
		const id = txn._rawTxn.id;
		switch (txn._rawTxnType) {
			case "request":
				//clear state
				transactionDetailsDispatch({
					type: "updateState",
					key: "request",
					value: {},
				});
				navigate(`/transactiondetails/request/${id}`);
				break;
			case "otctrade":
				//clear state
				transactionDetailsDispatch({
					type: "updateState",
					key: "order",
					value: {},
				});
				navigate(`/transactiondetails/${id}`);
				break;
		}
	};

	return (
		<div className="outstanding_balance_report_body">
			{isFetchingOutstandingBalanceReport && (
				<div className="outstanding_balance_report_loader_container">
					Loading Outstanding Balance report...
					<Loader2 className="outstanding_balance_report_loader" />
				</div>
			)}

			{!isFetchingOutstandingBalanceReport && (
				<table className="report_table outstanding_balance_report_table">
					<thead>
						<tr>
							<th>Transaction ID</th>
							<th>
								Pair <span className="help_text">( Buy - Sell )</span>
							</th>
							<th>Price</th>
							<th>Amount</th>
							<th>Amount Due </th>
							<th>Total</th>
							<th>Username</th>
							<th>Type</th>
							<th>Date</th>
						</tr>
					</thead>
					<tbody>
						{outstandingBalanceReport.map((order) => (
							<tr key={order.transactionID} onClick={() => viewTxn({ txn: order })}>
								<td>
									{order.transactionID}
									<br />
									<span className="help_text">( {order._rawTxnType} )</span>
								</td>
								<td>{order.pair}</td>
								<td>{order.price}</td>
								<td>
									{order.amount}
									{order.sellingOrBuying == "SELLING" && (
										<>
											<br />
											<span className="help_text">( You will receive this )</span>
										</>
									)}
								</td>
								<td>
									{order.amountDue}
									<>
										<br />
										<span className="help_text">( Amount you owe )</span>
									</>
								</td>
								<td>
									{order.total}
									{order.sellingOrBuying == "BUYING" && (
										<>
											<br />
											<span className="help_text">( You will receive this )</span>
										</>
									)}
								</td>
								<td>{order.userName}</td>
								<td>{order.sellingOrBuying}</td>
								<td>{order.date}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
export default OutstandingBalance;
