import { AllOrdersReportProvider } from "./counterpartyTransactions/allOrders/providers/allOrdersReportProvider";
import { CompletedOrdersReportProvider } from "./counterpartyTransactions/completedOrders/providers/completedOrdersReportProvider";
import { OpenOrdersReportProvider } from "./counterpartyTransactions/openOrders/providers/openOrdersReportProvider";
import { OutstandingBalanceReportProvider } from "./counterpartyTransactions/outstandingBalance/providers/outstandingBalanceReportProvider";
import { ProfitAndLossReportProvider } from "./profitAndLoss/providers/profitAndLossReportProvider";
import { SwapsReportProvider } from "./transactionsHistory/swaps/providers/swapsReportProvider";
import { VolumeReportProvider } from "./volume/providers/volumeReportProvider";
import { ReportFiltersProvider } from "./reportsFilterProvider";

const ReportsProvider = ({ children }) => {
	return (
		<ReportFiltersProvider>
		<SwapsReportProvider>
			<AllOrdersReportProvider>
				<CompletedOrdersReportProvider>
					<OpenOrdersReportProvider>
						<OutstandingBalanceReportProvider>
							<ProfitAndLossReportProvider>
								<VolumeReportProvider>{children}</VolumeReportProvider>
							</ProfitAndLossReportProvider>
						</OutstandingBalanceReportProvider>
					</OpenOrdersReportProvider>
				</CompletedOrdersReportProvider>
			</AllOrdersReportProvider>
		</SwapsReportProvider>
		</ReportFiltersProvider>
	);
};
export default ReportsProvider;
