import { useEffect } from "react";
import "./completedOrders.scss";
import { Loader2 } from "lucide-react";
import { useCompletedOrdersReport } from "./providers/completedOrdersReportProvider";
import { useAuth } from "../../../../providers/AuthProvider";
import { useReportFilters } from "../../reportsFilterProvider";
import { useNavigate } from "react-router-dom";
import { useTransactionDetailsDispatch } from "../../../transactionDetails/providers/transactionDetailsProvider";

const CompletedOrders = () => {
	const { isLoggedIn } = useAuth();
	const { filters } = useReportFilters();
	const { fetchCompletedOrdersReport, isFetchingCompletedOrdersReport, completedOrdersReport } = useCompletedOrdersReport();
	const transactionDetailsDispatch = useTransactionDetailsDispatch();
	const navigate = useNavigate();

	//Get completedOrders report
	useEffect(() => {
		(async () => {
			// fetch completedOrders report if logged in
			if (isLoggedIn) {
				await fetchCompletedOrdersReport();
			}
		})();
	}, [isLoggedIn]);

	const parseDate = (dateStr) => {
		if (!dateStr || typeof dateStr !== "string" || dateStr == "Invalid date") return null;

		const [datePart, timePart] = dateStr.split(" - ");
		const [day, month, year] = datePart.split("/").map((num) => parseInt(num, 10));
		const [time, period] = timePart.split(" ");
		const [hours, minutes] = time.split(":").map((num) => parseInt(num, 10));

		let hours24 = hours;
		if (period === "PM" && hours !== 12) hours24 = hours + 12;
		if (period === "AM" && hours === 12) hours24 = 0;

		return new Date(year, month - 1, day, hours24, minutes);
	};
	const applyFilters = (completedOrders) => {
		let filteredCompletedOrders = completedOrders;

		if (filters.searchQuery) {
			filteredCompletedOrders = filteredCompletedOrders.filter(
				(completedOrder) =>
					completedOrder.transactionID.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					completedOrder.pair.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					completedOrder.price.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					completedOrder.amount.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					completedOrder.total.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
					completedOrder.counterpartyName.toLowerCase().includes(filters.searchQuery.toLowerCase())
			);
		}

		if (filters.startDate && filters.endDate) {
			const start = new Date(filters.startDate);
			const end = new Date(filters.endDate);

			if (!isNaN(start) && !isNaN(end)) {
				filteredCompletedOrders = filteredCompletedOrders.filter((completedOrder) => {
					const completedOrderDate = parseDate(completedOrder.date);
					return completedOrderDate && completedOrderDate >= start && completedOrderDate <= end;
				});
			}
		}

		// Define filter mode based on buyCurrency and sellCurrency
		const buyCurrencyFilter = filters.buyCurrency ? [filters.buyCurrency] : [];
		const sellCurrencyFilter = filters.sellCurrency ? [filters.sellCurrency] : [];

		let filterMode = "NONE";
		if (buyCurrencyFilter.length > 0 && sellCurrencyFilter.length > 0) {
			filterMode = "BOTH";
		} else if (buyCurrencyFilter.length > 0) {
			filterMode = "BUY";
		} else if (sellCurrencyFilter.length > 0) {
			filterMode = "SELL";
		}

		filteredCompletedOrders = filteredCompletedOrders.filter((completedOrder) => {
			let showcompletedOrder = true;
			const [completedOrderBuyCurrency, completedOrdersellCurrency] = completedOrder.pair.split(" - ").map((currency) => currency.trim());

			switch (filterMode) {
				case "BUY":
					showcompletedOrder = buyCurrencyFilter.includes(completedOrderBuyCurrency);
					break;
				case "SELL":
					showcompletedOrder = sellCurrencyFilter.includes(completedOrdersellCurrency);
					break;
				case "BOTH":
					showcompletedOrder = buyCurrencyFilter.includes(completedOrderBuyCurrency) && sellCurrencyFilter.includes(completedOrdersellCurrency);
					break;
				case "NONE":
				default:
					showcompletedOrder = true;
					break;
			}

			return showcompletedOrder;
		});

		return filteredCompletedOrders;
	};

	const filteredCompletedOrdersReport = applyFilters(completedOrdersReport);

	const viewTxn = ({ txn }) => {
		const id = txn._rawTxn.id;
		switch (txn._rawTxnType) {
			case "request":
				//clear state
				transactionDetailsDispatch({
					type: "updateState",
					key: "request",
					value: {},
				});
				navigate(`/transactiondetails/request/${id}`);
				break;
			case "otctrade":
				//clear state
				transactionDetailsDispatch({
					type: "updateState",
					key: "order",
					value: {},
				});
				navigate(`/transactiondetails/${id}`);
				break;
		}
	};

	return (
		<div className="completed_orders_report_body">
			{isFetchingCompletedOrdersReport && (
				<div className="completed_orders_report_loader_container">
					Loading Completed Orders report...
					<Loader2 className="completed_orders_report_loader" />
				</div>
			)}

			{!isFetchingCompletedOrdersReport && (
				<table className="report_table completed_orders_report_table">
					<thead>
						<tr>
							<th>Transaction ID</th>
							<th>
								Pair <span className="help_text">( Buy - Sell )</span>
							</th>
							<th>Price</th>
							<th>Amount</th>
							<th>Total</th>
							<th>Username</th>
							<th>Type</th>
							<th>Date</th>
						</tr>
					</thead>
					<tbody>
						{filteredCompletedOrdersReport.map((order) => (
							<tr key={order.transactionID} onClick={() => viewTxn({ txn: order })}>
								<td>
									{order.transactionID}
									<br />
									<span className="help_text">( {order._rawTxnType} )</span>
								</td>
								<td>{order.pair}</td>
								<td>{order.price}</td>
								<td>
									{order.amount}
									{order.sellingOrBuying == "SELLING" && (
										<>
											<br />
											<span className="help_text">( You received this )</span>
										</>
									)}
								</td>
								<td>
									{order.total}
									{order.sellingOrBuying == "BUYING" && (
										<>
											<br />
											<span className="help_text">( You received this )</span>
										</>
									)}
								</td>
								<td>{order.counterpartyName}</td>
								<td>{order.sellingOrBuying}</td>
								<td>{order.date}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
export default CompletedOrders;
