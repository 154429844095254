import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useReducer } from "react";
import _ from "lodash";
import api from "../../api";
import FeatureFlags from "./FeaureFlags";

const FeatureFlagsContext = createContext();
const FeatureFlagsDispatchContext = createContext();

export const FEATURE_FLAGS_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
};

export const FeatureFlagsProvider = ({ children }) => {
	const getFeatureFlags = useQuery({
		queryKey: ["featureFlags"],
		queryFn: () => {
			return api.getFeatureFlags();
		},
		enabled: false,
	});

	const [featureFlagsState, featureFlagsDispatch] = useReducer(FeatureFlagsReducer, {
		isFeatureFlagEnabled: () => false,
	});

	const getEnvironment = () => {
		const origin = location.origin;
		let environment = "n/a";
		switch (origin) {
			case "https://dev.muda-v2.com":
			case "http://localhost:5173":
				environment = "staging";
				break;
			case "https://staging.muda-v2.com":
				environment = "staging";
				break;
			case "https://muda-v2.com":
				environment = "production";
				break;
			default:
				environment = "production";
				break;
		}
		return environment;
	};

	const fetchFeatureFlags = async () => {
		featureFlagsDispatch({ type: FEATURE_FLAGS_ACTIONS.updateState, key: "isFetchingFeatureFlags", value: true });
		const featureFlagsRefetchResult = await getFeatureFlags.refetch();
		featureFlagsDispatch({ type: FEATURE_FLAGS_ACTIONS.updateState, key: "isFetchingFeatureFlags", value: false });
		if (featureFlagsRefetchResult.status == "success") {
			const featureFlagsConfig = featureFlagsRefetchResult.data.data.data.attributes;
			const featureFlags = new FeatureFlags({ featureFlagsConfig });
			const isFeatureFlagEnabled = (featureName) => {
				const environment = getEnvironment();
				const enabled = featureFlags.isEnabled({ name: featureName, environment });
				return enabled;
			};
			featureFlagsDispatch({ type: FEATURE_FLAGS_ACTIONS.updateState, key: "isFeatureFlagEnabled", value: isFeatureFlagEnabled });
		}
	};

	//Get feaure flags
	useEffect(() => {
		(async () => {
			await fetchFeatureFlags();
		})();
	}, []);

	return (
		<FeatureFlagsContext.Provider value={featureFlagsState}>
			<FeatureFlagsDispatchContext.Provider value={featureFlagsDispatch}>{children}</FeatureFlagsDispatchContext.Provider>
		</FeatureFlagsContext.Provider>
	);
};

export const useFeatureFlags = () => {
	return useContext(FeatureFlagsContext);
};

export const useFeatureFlagsDispatch = () => {
	return useContext(FeatureFlagsDispatchContext);
};

const FeatureFlagsReducer = (featureFlagsState, action) => {
	switch (action.type) {
		case FEATURE_FLAGS_ACTIONS.updateState: {
			const newState = { ...featureFlagsState };
			newState[action.key] = action.value;
			return newState;
		}
		case FEATURE_FLAGS_ACTIONS.updateStates: {
			const newState = { ...featureFlagsState };
			action.states.forEach(({ key, value }) => {
				newState[key] = value;
			});
			return newState;
		}
		default: {
			throw Error("[FeatureFlagsReducer] Unknown action: " + action.type);
		}
	}
};
