import { useState } from "react";
import cn from "classnames";
import "./counterpartyTransactions.scss";
import AllOrders from "./allOrders/allOrders";
import CompletedOrders from "./completedOrders/completedOrders";
import OpenOrders from "./openOrders/openOrders";
import OutstandingBalance from "./outstandingBalance/outstandingBalance";

const CounterpartyTransactions = () => {
	const COUNTERPARTY_TRANSACTIONS_TABS = {
		ALL_ORDERS: "ALL_ORDERS",
		COMPLETED_ORDERS: "COMPLETED_ORDERS",
		OPEN_ORDERS: "OPEN_ORDERS",
		OUTSTANDING_BALANCE: "OUTSTANDING_BALANCE",
	};

	const emptyCounterpartyTransactionsState = {
		activeTab: COUNTERPARTY_TRANSACTIONS_TABS.ALL_ORDERS,
	};

	const [counterpartyTransactionsState, setCounterpartyTransactionsState] = useState(emptyCounterpartyTransactionsState);

	const switchToTab = (tab) => {
		const newState = { ...counterpartyTransactionsState };
		newState.activeTab = tab;
		setCounterpartyTransactionsState(newState);
	};

	return (
		<div id="counterparty_transactions_report_component">
			<div className="counterparty_transactions_report_tab_list">
				<div
					className={cn("tab all_orders", { active: counterpartyTransactionsState.activeTab == COUNTERPARTY_TRANSACTIONS_TABS.ALL_ORDERS })}
					onClick={() => {
						switchToTab(COUNTERPARTY_TRANSACTIONS_TABS.ALL_ORDERS);
					}}>
					All Orders
				</div>
				<div
					className={cn("tab completed_orders", { active: counterpartyTransactionsState.activeTab == COUNTERPARTY_TRANSACTIONS_TABS.COMPLETED_ORDERS })}
					onClick={() => {
						switchToTab(COUNTERPARTY_TRANSACTIONS_TABS.COMPLETED_ORDERS);
					}}>
					Completed Orders
				</div>
				<div
					className={cn("tab open_orders", { active: counterpartyTransactionsState.activeTab == COUNTERPARTY_TRANSACTIONS_TABS.OPEN_ORDERS })}
					onClick={() => {
						switchToTab(COUNTERPARTY_TRANSACTIONS_TABS.OPEN_ORDERS);
					}}>
					Open Orders
				</div>
				<div
					className={cn("tab outstanding_balance", { active: counterpartyTransactionsState.activeTab == COUNTERPARTY_TRANSACTIONS_TABS.OUTSTANDING_BALANCE })}
					onClick={() => {
						switchToTab(COUNTERPARTY_TRANSACTIONS_TABS.OUTSTANDING_BALANCE);
					}}>
					Outstanding Balance
				</div>
			</div>
			{counterpartyTransactionsState.activeTab == COUNTERPARTY_TRANSACTIONS_TABS.ALL_ORDERS && <AllOrders />}
			{counterpartyTransactionsState.activeTab == COUNTERPARTY_TRANSACTIONS_TABS.COMPLETED_ORDERS && <CompletedOrders />}
			{counterpartyTransactionsState.activeTab == COUNTERPARTY_TRANSACTIONS_TABS.OPEN_ORDERS && <OpenOrders />}
			{counterpartyTransactionsState.activeTab == COUNTERPARTY_TRANSACTIONS_TABS.OUTSTANDING_BALANCE && <OutstandingBalance />}
		</div>
	);
};

export default CounterpartyTransactions;
