import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useReducer } from "react";
import { useAuth } from "./AuthProvider";
import api from "../api";

const ProfileContext = createContext();
const ProfileDispatchContext = createContext();

export const PROFILE_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
};
export const ProfileProvider = ({ children }) => {
  const { authTokens, isLoggedIn } = useAuth();

  const getProfile = useQuery({
    queryKey: ["profile"],
    queryFn: () => {
      return api.getMyProfile({ token: authTokens.IdToken });
    },
    enabled: false
  });

  const [profileState, profileDispatch] = useReducer(profileReducer, {
    profile: {},
    isFetchingProfile: false,
    getProfile
  });

  //get profile
  useEffect(() => {
    (async () => {
      // fetch profile if logged in
      if (isLoggedIn) {
        profileDispatch({ type: PROFILE_ACTIONS.updateState, key: "isFetchingProfile", value: true });
        const profileRefetchResult = await getProfile.refetch();
        profileDispatch({ type: PROFILE_ACTIONS.updateState, key: "isFetchingProfile", value: false });
        if (profileRefetchResult.status == "success") {
          const newProfile = profileRefetchResult.data.data.data.attributes;
          profileDispatch({
            type: "fetchedProfile",
            profile: newProfile
          });
        }
      }
    })();
  }, [isLoggedIn]);

  return (
    <ProfileContext.Provider value={profileState}>
      <ProfileDispatchContext.Provider value={profileDispatch}>{children}</ProfileDispatchContext.Provider>
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  return useContext(ProfileContext);
};

export const useProfileDispatch = () => {
  return useContext(ProfileDispatchContext);
};

const profileReducer = (profileState, action) => {
  switch (action.type) {
    case PROFILE_ACTIONS.updateState: {
			const newState = { ...profileState };
			newState[action.key] = action.value;
			return newState;
		}
		case PROFILE_ACTIONS.updateStates: {
			const newState = { ...profileState };
			action.states.forEach(({ key, value }) => {
				newState[key] = value;
			});
			return newState;
		}
    case "fetchedProfile":
      return { ...profileState, profile: action.profile };
    default: {
      throw Error("[profileReducer] Unknown action: " + action.type);
    }
  }
};
