import { Link, useNavigate } from "react-router-dom";
import Header from "../header/header";
import "./signup.scss";
import { Loader2, Info } from "lucide-react";
import { useForm } from "react-hook-form";
import cn from "classnames";
import api from "../api";
import { useMutation } from "@tanstack/react-query";

const Signup = () => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();

	const password = watch("password", "");

	const signup = useMutation({
		mutationFn: (creds) => {
			return api.signup(creds);
		},
		onSuccess: (data, variables, context) => {
			//Go to confirm email
			const email = variables.email;
			navigate(`/confirm/email?email=${email}`);
		},
	});
	const onSignup = (creds) => {
		signup.mutate(creds);
	};

	return (
		<>
			<Header></Header>
			<div className="signup_page">
				<div className="signup_form">
					<div className="signup_header">Sign up</div>
					<div className="signup_input_group">
						<label className="signup_input">
							<span className="signup_input_label">
								First name <span className="required">*</span>
							</span>
							<input
								placeholder="Enter your first name"
								name="firstName"
								className={cn({ error: errors.firstName?.type == "required" })}
								{...register("firstName", { required: true })}
							/>
							{errors.firstName?.type === "required" && (
								<span className="signup_input_error">
									<Info className="signup_input_error_icon" /> Please fill in your first name
								</span>
							)}
						</label>
						<div className="spacer"></div>
						<label className="signup_input">
							<span className="signup_input_label">
								Last name <span className="required">*</span>
							</span>
							<input
								placeholder="Enter your last name"
								name="lasttName"
								className={cn({ error: errors.lastName?.type == "required" })}
								{...register("lastName", { required: true })}
							/>
							{errors.lastName?.type === "required" && (
								<span className="signup_input_error">
									<Info className="signup_input_error_icon" /> Please fill in your last name
								</span>
							)}
						</label>
					</div>
					<div className="spacer"></div>
					<div className="signup_input_group">
						<label className="signup_input">
							<span className="signup_input_label">
								Email <span className="required">*</span>
							</span>
							<input
								placeholder="Enter your email"
								name="email"
								className={cn({ error: errors.email?.type == "required" })}
								{...register("email", { required: true })}
							/>
							{errors.email?.type === "required" && (
								<span className="signup_input_error">
									<Info className="signup_input_error_icon" /> Please fill in your email
								</span>
							)}
						</label>
						<div className="spacer"></div>
						<label className="signup_input">
							<span className="signup_input_label">Referral Code</span>
							<input placeholder="Enter your last name" name="referral" {...register("referral")} />
						</label>
					</div>
					<div className="spacer"></div>
					<div className="signup_input_group">
						<label className="signup_input">
							<span className="signup_input_label">
								Password <span className="required">*</span>
							</span>
							<input
								type="password"
								placeholder="Create your password"
								name="password"
								className={cn({ error: errors.password?.type == "required" })}
								{...register("password", { required: true })}
							/>
							{errors.password?.type === "required" && (
								<span className="signup_input_error">
									<Info className="signup_input_error_icon" /> Password is required
								</span>
							)}
						</label>
						<div className="spacer"></div>
						<label className="signup_input">
							<span className="signup_input_label">
								Confirm password <span className="required">*</span>
							</span>
							<input
								type="password"
								placeholder="Confirm password"
								name="confirmPassword"
								{...register("confirmPassword", {
									validate: (value) => value === password || "The passwords do not match",
								})}
							/>
							{errors.confirmPassword && (
								<span className="signup_input_error">
									<Info className="signup_input_error_icon" /> {errors.confirmPassword.message}
								</span>
							)}
						</label>
					</div>
					<div className="spacer"></div>
					<div className="spacer"></div>
					<div className="signup_error_box">
						{signup.isError && ( //check logic here
							<span className="signup_input_error">
								<Info className="signup_input_error_icon" /> The email you entered is already registered with another account
							</span>
						)}
					</div>
					<div className="spacer"></div>
					<div className="signup_btn_box">
						<div className={cn("signup_btn", { is_signing_up: signup.isPending })} onClick={handleSubmit(onSignup)}>
							{signup.isPending && <Loader2 className="signup_loader" />}
							{!signup.isPending && <span>Sign up</span>}
						</div>
					</div>
					<div className="spacer"></div>
					<div className="or_box">
						<hr /> <span className="or">or</span> <hr />
					</div>
					<div className="spacer"></div>
					<div className="already_have_account_box">
						Already have an account? <Link to="/login">Log in</Link>
					</div>
					<div className="spacer"></div>
					<div className="tocs">
						By clicking on Sign up, you agree to our{" "}
						<a href="https://muda.tech/#/terms-and-conditions" target="_blank" className="highlight">
							terms & conditions
						</a>{" "}
						and{" "}
						<a href="https://muda.tech/#/privacy-policy" target="_blank" className="highlight">
							privacy policy
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default Signup;
