import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { Info, CalendarDays, Loader2 } from "lucide-react";
import cn from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import "./kyc.scss";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../providers/AuthProvider";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import { useProfile, useProfileDispatch } from "../../providers/ProfileProvider";
import MudaCheckIcon from "./common/MudaCheckIcon/MudaCheckIcon";
const Kyc = () => {
	const { authTokens } = useAuth();
	const {
		register,
		handleSubmit,
		setValue,
		trigger,
		formState: { errors },
		control,
	} = useForm();
	const phonenumberVerificationForm = useForm();
	const verifyPhoneNumberDialogRef = useRef(null);
	const phonenumberVerfiedDialogRef = useRef(null);
	const navigate = useNavigate();
	const { profile, getProfile } = useProfile();
	const profileDispatch = useProfileDispatch();

	const Pages = {
		account_details: "account_details_page",
		identity_verification: "identity_verification_page",
	};
	const [page, setPage] = useState(Pages.account_details);

	const token = authTokens.IdToken;
	const updateMyKycProfile = useMutation({
		mutationFn: ({ profile, token }) => {
			return api.updateMyKycProfile({ profile, token });
		},
		onError: (error, variables, context) => {},
		onSuccess: async (data, variables, context) => {
			const profileRefetchResult = await getProfile.refetch();
			if (profileRefetchResult.status == "success") {
				const newProfile = profileRefetchResult.data.data.data.attributes;
				profileDispatch({
					type: "fetchedProfile",
					profile: newProfile,
				});
			}

			switch (variables.source) {
				case "saveAndClose":
					// navigate to dashboard
					navigate(`/`);
					break;
				case "next":
					// go to identity verification page
					setPage(Pages.identity_verification);
					break;
			}
		},
	});

	const startVerifyPhoneNumber = useMutation({
		mutationFn: ({ token }) => {
			return api.startVerifyPhonenumber({ token });
		},
		onError: (error, variables, context) => {},
		onSuccess: (data, variables, context) => {
			verifyPhoneNumberDialogRef.current.showModal();
		},
	});

	const verifyPhonenumber = useMutation({
		mutationFn: ({ token, code }) => {
			return api.verifyPhonenumber({ token, code });
		},
		onError: (error, variables, context) => {},
		onSuccess: async (data, variables, context) => {
			const profileRefetchResult = await getProfile.refetch();
			if (profileRefetchResult.status == "success") {
				const newProfile = profileRefetchResult.data.data.data.attributes;
				profileDispatch({
					type: "fetchedProfile",
					profile: newProfile,
				});
			}
			verifyPhoneNumberDialogRef.current.close();
			phonenumberVerfiedDialogRef.current.showModal();
		},
	});

	const onSaveAndClose = async (profile) => {
		updateMyKycProfile.mutate({ profile, token, source: "saveAndClose" });
	};

	const onNext = async (profile) => {
		updateMyKycProfile.mutate({ profile, token, source: "next" });
	};

	const onStartVerifyPhoneNumber = async () => {
		startVerifyPhoneNumber.mutate({ token });
	};

	const onVerifyPhonenumber = async ({ code }) => {
		verifyPhonenumber.mutate({ code, token });
	};

	const onPhonenumberVerifiedNext = async ({ code }) => {
		phonenumberVerfiedDialogRef.current.close();
	};

	return (
		<div className="kyc_page">
			{page == Pages.account_details && (
				<div className="kyc_account_details_page">
					<div className="kyc_account_details_heading">
						<div className="title">Account details</div>
						<div className="notifications"></div>
					</div>
					<div className="kyc_account_details_sub_heading">Please complete your Account details first to proceed with the KYC process.</div>
					<div className="kyc_account_details_form">
						<div className="lhs">
							<div className="kyc_account_details_input_group username">
								<label>
									<span className="kyc_account_details_input_label">
										Username<span className="required">*</span>
									</span>
									<input
										className="kyc_account_details_input"
										placeholder="Enter your username"
										name="username"
										{...register("username", { required: true })}
									/>
									{errors.username?.type === "required" && (
										<span className="kyc_account_details_input_error">
											<Info className="signup_input_error_icon" />
											Username is required
										</span>
									)}
								</label>
							</div>
							<div className="kyc_account_details_input_group gender">
								<label className="kyc_account_details_input_group gender">
									<span className="kyc_account_details_input_label">Gender</span>
									<select className="kyc_account_details_input" name="gender" {...register("gender", { required: true })}>
										<option disabled value="" className="placeholderOption">
											Select gender
										</option>
										<option value={"male"}>Male</option>
										<option value={"female"}>Female</option>
										<option value={"other"}>Other</option>
									</select>
								</label>
							</div>
							<div className="kyc_account_details_input_group date_of_birth">
								<label className="kyc_account_details_input_group date_of_birth">
									<span className="kyc_account_details_input_label">
										Date of birth<span className="required">*</span>
									</span>
									<input
										id="kyc_datepicker"
										type="date"
										className="kyc_account_details_input"
										name="dateOfBirth"
										{...register("dateOfBirth", { required: true })}
									/>
									<CalendarDays
										className="kyc_date_picker_icon"
										onClick={() => {
											document.getElementById("kyc_datepicker").showPicker();
										}}
									/>
									{errors.dateOfBirth && (
										<span className="kyc_account_details_input_error">
											<Info className="signup_input_error_icon" />
											Date of birth is required
										</span>
									)}
								</label>
							</div>
							{/* <div className="kyc_account_details_input_group profile_pic">
                <label>
                  <span className="kyc_account_details_input_label">
                    Upload profile picture
                  </span>
                </label>
              </div> */}
						</div>
						<div className="rhs">
							<div className="kyc_account_details_input_group country_of_residence">
								<label>
									<span className="kyc_account_details_input_label">
										Country of residence<span className="required">*</span>
									</span>
									<input type="text" name="countryOfResidence" className="kyc_account_details_input" {...register("countryOfResidence")} />
									{errors.countryOfResidence && (
										<span className="kyc_account_details_input_error">
											<Info className="signup_input_error_icon" />
											Country of residence is required
										</span>
									)}
								</label>
							</div>
							<div className="kyc_account_details_input_group city">
								<label>
									<span className="kyc_account_details_input_label">City</span>
									<input type="text" name="city" className="kyc_account_details_input" {...register("city")} />
								</label>
							</div>
							<div className="kyc_account_details_input_group postal_code">
								<label>
									<span className="kyc_account_details_input_label">Zip/Postal code</span>
									<input type="text" name="postal_code" className="kyc_account_details_input" {...register("postal_code")} />
								</label>
							</div>
							<div className="kyc_account_details_input_group profileTimezone">
								<label>
									<span className="kyc_account_details_input_label">Time zone</span>
									<input type="text" name="profileTimezone" className="kyc_account_details_input" {...register("profileTimezone")} />
								</label>
							</div>
							<div className="kyc_account_details_input_group phone_number">
								<label className="kyc_account_details_input_group phone_number">
									<span className="kyc_account_details_input_label">
										Mobile number<span className="required">*</span>
									</span>
									<Controller
										name="phonenumber"
										control={control}
										rules={{
											required: true,
											validate: (value) => isPossiblePhoneNumber(value),
										}}
										render={({ field: { onChange, value } }) => (
											<PhoneInput
												className="kyc_account_details_input phone_number"
												international
												value={value}
												onChange={(val) => {
													onChange(val);
													setValue("phonenumber", val);
													trigger("phonenumber");
												}}
												defaultCountry="UG"
												id="phonenumber"
											/>
										)}
									/>
									{errors.phonenumber && (
										<span className="kyc_account_details_input_error">
											<Info className="signup_input_error_icon" />
											Please enter a valid phone number
										</span>
									)}
								</label>
							</div>
						</div>
					</div>
					<div className="kyc_account_details_controls">
						<div className="kyc_account_details_control save_and_close" onClick={handleSubmit(onSaveAndClose)}>
							{!updateMyKycProfile.isPending && "Save & Close"}

							{updateMyKycProfile.isPending && (
								<>
									<Loader2 className="updating_kyc_profile_loader" /> ...saving
								</>
							)}
						</div>

						<div className="kyc_account_details_control next" onClick={handleSubmit(onNext)}>
							{!updateMyKycProfile.isPending && "Next"}
							{updateMyKycProfile.isPending && (
								<>
									<Loader2 className="updating_kyc_profile_loader" /> ...saving
								</>
							)}
						</div>
					</div>
				</div>
			)}

			{page == Pages.identity_verification && (
				<div className="kyc_identity_verification_page">
					<div className="kyc_identity_verification_heading">
						<div className="title">Identity Verification</div>
						<div className="notifications"></div>
					</div>
					<div className="kyc_identity_verification_sub_heading">
						In order to complete verification you will be asked to verify your phone number and go through UNIFID verification process
					</div>
					<div className="kyc_identity_verification_levels">
						<div className="kyc_identity_verification_level level_1">
							<div className="kyc_identity_verification_level_heading level_1_heading">Level 1</div>
							<div className="kyc_identity_verification_level_info level_1_info">
								<p>
									Buy and sell crypto <span className="bold">up to USD worth</span> of cryptocurrency each month
								</p>
								<p>
									Deposit and receive an <span className="bold">unlimited</span> amount of USD and any other coins
								</p>
								<p>
									Withdraw <span className="bold">up to 50000 USD</span> each month
								</p>
							</div>
							<div className="kyc_identity_verification_level_controls level_1_controls">
								{profile.phonenumberVerified != "true" && (
									<div className="kyc_identity_verification_level_control level_1_control verify_phone_number" onClick={onStartVerifyPhoneNumber}>
										{!startVerifyPhoneNumber.isPending && "Verify Mobile Number"}
										{startVerifyPhoneNumber.isPending && (
											<>
												<Loader2 className="updating_kyc_profile_loader" />
											</>
										)}
									</div>
								)}

								{profile.phonenumberVerified == "true" && (
									<div className="kyc_identity_verification_level_indicator level_1_indicator phone_number_verifed">
										<MudaCheckIcon /> Verified
									</div>
								)}

								<dialog className="verify_phonenumber_dialog_container" ref={verifyPhoneNumberDialogRef}>
									<div className="verify_phonenumber_dialog_heading">Verify mobile number</div>
									<div className="verify_phonenumber_dialog_sub_heading">Please enter the code that has been sent to your mobile number</div>
									<input
										type="text"
										name="code"
										placeholder="Enter the code"
										className="verify_phonenumber_dialog_input code"
										{...phonenumberVerificationForm.register("code", {
											required: true,
										})}
									/>
									<div className="verify_phonenumber_dialog_controls">
										{(phonenumberVerificationForm.formState.errors.code?.type === "required" || verifyPhonenumber.isError) && (
											<span className="verify_phonenumber_dialog_error">
												<Info className="verify_phonenumber_dialog_error_icon" />
												Code is invalid
											</span>
										)}

										<div className="verify_phonenumber_dialog_control verify" onClick={phonenumberVerificationForm.handleSubmit(onVerifyPhonenumber)}>
											{!verifyPhonenumber.isPending && "Verify"}
											{verifyPhonenumber.isPending && (
												<>
													<Loader2 className="verify_phonenumber_dialog_loader" /> ...verifying
												</>
											)}
										</div>
									</div>
								</dialog>

								<dialog className="phonenumber_verified_dialog_container" ref={phonenumberVerfiedDialogRef}>
									<div className="phonenumber_verified_dialog_heading">Your number has been verified</div>

									<MudaCheckIcon />

									<div className="phonenumber_verified_dialog_controls">
										<div className="phonenumber_verified_dialog_control next" onClick={onPhonenumberVerifiedNext}>
											Next
										</div>
									</div>
								</dialog>
							</div>
						</div>
						<div
							className={cn("kyc_identity_verification_level level_2", {
								disabled: profile.phonenumberVerified != "true",
							})}>
							<div className="kyc_identity_verification_level_heading level_2_heading">Level 2</div>
							<div className="kyc_identity_verification_level_info level_2_info">
								<p>Buy and sell an amount of cryptocurrency each month</p>
								<p>Deposit, withdraw, send and receive an unlimited amount of cryptocurrency each month</p>
								<p>Withdraw up to unlimited USD each month</p>
							</div>
							<div className="kyc_identity_verification_level_controls level_2_controls">
								<div className="kyc_identity_verification_level_control level_2_control verify_with_unifid">Verify with UNIFID</div>
							</div>
						</div>
						<div className="kyc_identity_verification_level level_3 disabled">
							<div className="kyc_identity_verification_level_heading level_3_heading">Level 3</div>
							<div className="kyc_identity_verification_level_info level_3_info">
								<p>Company account</p>
								<p>Use Muda in different ways, while still having just one login </p>
								<p>Manage teams and company transfers from one common company account</p>
							</div>
							<div className="kyc_identity_verification_level_controls level_3_controls">
								<div className="kyc_identity_verification_level_control level_3_control create_company_account">Create company account</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
export default Kyc;
