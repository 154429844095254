import { useState } from "react";
import ProcessingRequests from "./processingRequests/processingRequests";
import ReceivedRequests from "./receivedRequests/receivedRequests";
import CompleteRequests from "./completeRequests/completeRequests";
import cn from "classnames";
import "./transactions.scss";
import AllRequests from "./allRequests/allRequests";
const Transactions = () => {
	const TRANSACTIONS_TABS = {
		REQUESTS: "REQUESTS",
		PROCESSING: "PROCESSING",
		COMPLETE:  "COMPLETE",
		ALL:"ALL"
	};
	const emptyTransactionsState = {
		activeTab: TRANSACTIONS_TABS.REQUESTS,
	};
	const [transactionsState, setTransactionsState] = useState(emptyTransactionsState);

	const switchToTab = (tab) => {
		const newTransactionsState = { ...transactionsState };
		newTransactionsState.activeTab = tab;
		setTransactionsState(newTransactionsState);
	};

	return (
		<div id="transactions_component">
			<div className="transactions_tab_list">
				<div
					className={cn("tab requests", { active: transactionsState.activeTab == TRANSACTIONS_TABS.REQUESTS })}
					onClick={() => {
						switchToTab(TRANSACTIONS_TABS.REQUESTS);
					}}>
					Requests
				</div>
				<div
					className={cn("tab processing", { active: transactionsState.activeTab == TRANSACTIONS_TABS.PROCESSING })}
					onClick={() => {
						switchToTab(TRANSACTIONS_TABS.PROCESSING);
					}}>
					Processing
				</div>
				<div className={cn("tab complete", { active: transactionsState.activeTab == TRANSACTIONS_TABS.COMPLETE })}
					onClick={() => {
						switchToTab(TRANSACTIONS_TABS.COMPLETE);
					}}>Complete</div>
				<div className={cn("tab all", { active: transactionsState.activeTab == TRANSACTIONS_TABS.ALL })}
					onClick={() => {
						switchToTab(TRANSACTIONS_TABS.ALL);
					}}>All</div>
			</div>
			{transactionsState.activeTab == TRANSACTIONS_TABS.REQUESTS && <ReceivedRequests />}
			{transactionsState.activeTab == TRANSACTIONS_TABS.PROCESSING && <ProcessingRequests />}
			{transactionsState.activeTab == TRANSACTIONS_TABS.COMPLETE && <CompleteRequests />}
			{transactionsState.activeTab == TRANSACTIONS_TABS.ALL && <AllRequests />}
		</div>
	);
};
export default Transactions;
