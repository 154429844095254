import { useState } from "react";
import cn from "classnames";
import Trade from "./trade/trade";
import "./transact.scss";
import { TRANSACT_PAGES, useTransact, useTransactDispatch } from "./providers/TransactProvider";
import Confirmmarkettrade from "./trade/markettrade/confirmmarkettrade/confirmmarkettrade";
import Successtrade from "./trade/tradeconfirmed/tradeconfirmed";
import Confirmotctrade from "./trade/otctrade/confirmotctrade/confirmotctrade";
import Tradeconfirmed from "./trade/tradeconfirmed/tradeconfirmed";
import ConfigurePaymentMethods from "./paymentmethods/configurepaymentmethods/configurepaymentmethods";
import AddPaymentMethod from "./paymentmethods/addpaymentmethod/addpaymentmethod";
import AddPaymentMethodSuccess from "./paymentmethods/addpaymentmethodsuccess/addpaymentmethodsuccess";
import { useFeatureFlags } from "../../providers/FeatureFlags/FeatureFlagsProvider";
const TRANSACT_OPTIONS = {
	trade: "trade",
	deposit: "deposit",
	withdraw: "withdraw",
};
const Transact = () => {
	const { isFeatureFlagEnabled } = useFeatureFlags();
	const [transactState, setTransactState] = useState({
		activeOption: TRANSACT_OPTIONS.trade,
	});
	const { page } = useTransact();
	const transactDispatch = useTransactDispatch();
	return (
		<div id="transact_component">
			{page == TRANSACT_PAGES.TRANSACT && (
				<>
					<div className="transact_controls">
						<svg className="transact_control close" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
							<path d="M19 19L5 5M19 5L5 19" stroke="#172C50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
					<div className="transact_header">
						<div className="transact_option trade active">TRADE</div>
						{isFeatureFlagEnabled("deposits") && <div className="transact_option deposit">DEPOSIT</div>}
						{isFeatureFlagEnabled("withdrawals") && <div className="transact_option withdraw">WITHDRAW</div>}
					</div>
					<div className="transact_body">
						<Trade />
					</div>
				</>
			)}
			{page == TRANSACT_PAGES.CONFIRM_OTC_TRADE && <Confirmotctrade />}
			{page == TRANSACT_PAGES.CONFIRM_MARKET_TRADE && <Confirmmarkettrade />}
			{page == TRANSACT_PAGES.TRADE_CONFIRMED && <Tradeconfirmed />}
			{page == TRANSACT_PAGES.CONFIGURE_PAYMENT_METHODS && <ConfigurePaymentMethods />}
			{page == TRANSACT_PAGES.ADD_PAYMENT_METHOD && <AddPaymentMethod />}
			{page == TRANSACT_PAGES.ADD_PAYMENT_METHOD_SUCCESS && <AddPaymentMethodSuccess />}
		</div>
	);
};
export default Transact;
