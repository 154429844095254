import { createContext, useContext, useEffect, useReducer } from "react";
import api from "../../../api";
import { useMutation } from "@tanstack/react-query";

const TransactionDetailsContext = createContext();
const TransactionDetailsDispatchContext = createContext();

export const TRANSACTION_DETAILS_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
};

export const TransactionDetailsProvider = ({ children }) => {
	const cancelOrder = useMutation({
		mutationFn: ({ order, token }) => {
			transactionDetailsDispatch({ type: TRANSACTION_DETAILS_ACTIONS.updateState, key: "isCancellingOrder", value: true });
			return api.cancelOrder({ order, token });
		},
		onError: (error, variables, context) => {
			console.log(error);
			transactionDetailsDispatch({ type: TRANSACTION_DETAILS_ACTIONS.updateState, key: "isCancellingOrder", value: false });
		},
		onSuccess: async () => {
			transactionDetailsDispatch({ type: TRANSACTION_DETAILS_ACTIONS.updateState, key: "isCancellingOrder", value: false });
			// REFRESH ORDERS IN THE SYSTEM
		},
	});

	const [transactionDetailsState, transactionDetailsDispatch] = useReducer(transactionDetailsReducer, {
		order: {},
		request: {},
		back: "",
		isCancellingOrder: false,
		cancelOrder: ({ order, token }) => cancelOrder.mutate({ order, token }),
	});

	return (
		<TransactionDetailsContext.Provider value={transactionDetailsState}>
			<TransactionDetailsDispatchContext.Provider value={transactionDetailsDispatch}>{children}</TransactionDetailsDispatchContext.Provider>
		</TransactionDetailsContext.Provider>
	);
};

export const useTransactionDetails = () => {
	return useContext(TransactionDetailsContext);
};

export const useTransactionDetailsDispatch = () => {
	return useContext(TransactionDetailsDispatchContext);
};

const transactionDetailsReducer = (transactionDetailsState, action) => {
	switch (action.type) {
		case "open": {
			const newTransactionDetailsState = { ...transactionDetailsState };
			newTransactionDetailsState.order = action.order;
			newTransactionDetailsState.back = action.back;
			return newTransactionDetailsState;
		}
		case "updateState":
			const newTransactionDetailsState = { ...transactionDetailsState };
			newTransactionDetailsState[action.key] = action.value;
			return newTransactionDetailsState;
		case "clearState":
			const emptyTransactionDetailsState = {
				isFetchingTransactionDetailsOrders: false,
				transactionDetailsOrders: [],
			};
			return { ...transactionDetailsState, ...emptyTransactionDetailsState };
		default: {
			throw Error("[transactionDetailsReducer] Unknown action: " + action.type);
		}
	}
};
