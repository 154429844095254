import { useCreateRequest } from "../providers/createRequestProvider";
import "./createRequestConfirmed.scss";
const CreateRequestConfirmed = () => {
	const { createReqeustPopoverRef } = useCreateRequest();
	const closeRequestConfirmed = () => {
		createReqeustPopoverRef.current.hidePopover();
	};

	return (
		<div id="create_request_confirmed_component">
			<div className="create_request_confirmed_controls">
				<svg onClick={closeRequestConfirmed} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
					<path d="M19 19L5 5M19 5L5 19" stroke="#172C50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			</div>
			<div className="create_request_confirmed_container">
				<div className="create_request_confirmed_summary">
					<div className="success_title">Success!</div>
					<div className="success_subtitle">Your request has been created</div>
					<div className="success_icon">
						<svg xmlns="http://www.w3.org/2000/svg" width="56" height="55" viewBox="0 0 56 55" fill="none">
							<path
								d="M55.5 27.5C55.5 42.6878 43.1878 55 28 55C12.8122 55 0.5 42.6878 0.5 27.5C0.5 12.3122 12.8122 0 28 0C43.1878 0 55.5 12.3122 55.5 27.5Z"
								fill="#3E9CF3"
							/>
							<path
								d="M47.5769 27.0385C47.5769 37.5531 39.0531 46.0769 28.5385 46.0769C18.0238 46.0769 9.5 37.5531 9.5 27.0385C9.5 16.5238 18.0238 8 28.5385 8C39.0531 8 47.5769 16.5238 47.5769 27.0385Z"
								fill="#2A64A1"
							/>
							<path d="M36.5427 22L26.2009 32.3419L21.5 27.641" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
					<div onClick={closeRequestConfirmed} className="summary">
						<div className="close_request_confirmed">Ok</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default CreateRequestConfirmed;
