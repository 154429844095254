class FeatureFlags {
    features = new Set();
    environments = {};

    constructor(opts = {}) {
        const { featureFlagsConfig } = opts;
        if (featureFlagsConfig) {
            const { features } = featureFlagsConfig
            if (Array.isArray(features)) {
                for (let featureIdx = 0; featureIdx < features.length; featureIdx++) {

                    const feature = features[featureIdx];
                    const { name, enabled } = feature;
                    if (enabled) {
                        this.features.add(name);
                    }

                    const environments = feature.environments;
                    if (Array.isArray(environments)) {
                        for (let environmentIdx = 0; environmentIdx < environments.length; environmentIdx++) {
                            const environment = environments[environmentIdx];
                            const { name, enabled } = environment;
                            if (name) {
                                this.environments[name] = this.environments[name] || new Set();
                            }
                            if (enabled) {
                                this.environments[name].add(feature.name);
                            }
                        }
                    }
                }
            }
        }
    }

    isEnabled(opts = {}) {
        const { name, environment } = opts;

        if (!name) return false;

        if (!environment) return this.features.has(name);

        if (!this.environments[environment]) return this.features.has(name);

        return this.environments[environment].has(name);
    }
}
export default FeatureFlags;