import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useReducer } from "react";
import _ from "lodash";
import moment from "moment";
import { formatAmount } from "../../../../common/formatAmount";
import Big from "big.js";
import { useAuth } from "../../../../providers/AuthProvider";
import api from "../../../../api";

const VolumeReportContext = createContext();
const VolumeReportDispatchContext = createContext();

export const VOLUME_REPORT_ACTIONS = {
  updateState: "updateState",
  updateStates: "updateStates",
};

export const VolumeReportProvider = ({ children }) => {
  const { authTokens } = useAuth();

  const getVolumeReport = useQuery({
    queryKey: ["volumeReport"],
    queryFn: () => {
      return api.getVolumeReport({ token: authTokens.IdToken });
    },
    enabled: false,
  });

  const fetchVolumeReport = async () => {
    volumeReportDispatch({
      type: VOLUME_REPORT_ACTIONS.updateState,
      key: "isFetchingVolumeReport",
      value: true,
    });
    const volumeReportRefetchResult = await getVolumeReport.refetch();
    volumeReportDispatch({
      type: VOLUME_REPORT_ACTIONS.updateState,
      key: "isFetchingVolumeReport",
      value: false,
    });
    if (volumeReportRefetchResult.status == "success") {
      let volumeReport = volumeReportRefetchResult.data.data.data;

      volumeReport.orders = volumeReport.orders.map((txn) => {
        const order = txn.attributes ?? txn;
        order.transactionID = order.transactionID.split("-")[0];

        const rawTxn = order._rawTxn;
        if (order._rawTxnType == "manual") {
          const rawTxn = order._rawTxn;
          order.price = `1 ${rawTxn.sellCurrency} / ${formatAmount(
            rawTxn.sellRate
          )} ${rawTxn.buyCurrency}`;
          order.amount = `${formatAmount(rawTxn.sellVolume)} ${
            rawTxn.buyCurrency
          }`;
          order.total = `${formatAmount(rawTxn.deliveredVolume)} ${
            rawTxn.sellCurrency
          }`;
          order.date = moment(rawTxn.date).format("DD/MM/YYYY - h:mm A");
        }

        if (order._rawTxnType == "otctrade") {
          const rawTxn = order._rawTxn;
          order.pair = `${rawTxn.order.buyCurrency} - ${rawTxn.order.sellCurrency}`;
          order.price = `1 ${rawTxn.order.sellCurrency} / ${formatAmount(
            rawTxn.order.volumeTypeAmount
          )} ${rawTxn.order.buyCurrency}`;
          order.amount = `${formatAmount(rawTxn.desiredPurchaseAmount)} ${
            rawTxn.order.buyCurrency
          }`;
          const total = Big(rawTxn.desiredPurchaseAmount)
            .div(Big(rawTxn.order.volumeTypeAmount))
            .toString();
          order.total = `${formatAmount(total)} ${rawTxn.order.sellCurrency}`;
          order.date = moment(rawTxn.createdAt).format("DD/MM/YYYY - h:mm A");
        }

        return order;
      });

      volumeReportDispatch({
        type: VOLUME_REPORT_ACTIONS.updateState,
        key: "volumeReport",
        value: volumeReport,
      });
    }
  };

  const [volumeReportState, volumeReportDispatch] = useReducer(
    VolumeReportReducer,
    {
      volumeReport: [],
      isFetchingVolumeReport: false,
      fetchVolumeReport,
      getVolumeReport,
    }
  );

  return (
    <VolumeReportContext.Provider value={volumeReportState}>
      <VolumeReportDispatchContext.Provider value={volumeReportDispatch}>
        {children}
      </VolumeReportDispatchContext.Provider>
    </VolumeReportContext.Provider>
  );
};

export const useVolumeReport = () => {
  return useContext(VolumeReportContext);
};

export const useVolumeReportDispatch = () => {
  return useContext(VolumeReportDispatchContext);
};

const VolumeReportReducer = (volumeReportState, action) => {
  switch (action.type) {
    case VOLUME_REPORT_ACTIONS.updateState: {
      const newState = { ...volumeReportState };
      newState[action.key] = action.value;
      return newState;
    }
    case VOLUME_REPORT_ACTIONS.updateStates: {
      const newState = { ...volumeReportState };
      action.states.forEach(({ key, value }) => {
        newState[key] = value;
      });
      return newState;
    }
    default: {
      throw Error("[VolumeReportReducer] Unknown action: " + action.type);
    }
  }
};
