import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useReducer } from "react";
import { useAuth } from "../../../../../providers/AuthProvider";
import api from "../../../../../api";
import _ from "lodash";
import moment from "moment";
import Big from "big.js";
import { formatAmount } from "../../../../../common/formatAmount";

const SwapsReportContext = createContext();
const SwapsReportDispatchContext = createContext();

export const SWAPS_REPORT_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
};

export const SwapsReportProvider = ({ children }) => {
	const { authTokens } = useAuth();

	const getSwapsReport = useQuery({
		queryKey: ["swapsReport"],
		queryFn: () => {
			return api.getSwapsReport({ token: authTokens.IdToken });
		},
		enabled: false,
	});

	const fetchSwapsReport = async () => {
		swapsReportDispatch({ type: SWAPS_REPORT_ACTIONS.updateState, key: "isFetchingSwapsReport", value: true });
		const swapsReportRefetchResult = await getSwapsReport.refetch();
		swapsReportDispatch({ type: SWAPS_REPORT_ACTIONS.updateState, key: "isFetchingSwapsReport", value: false });
		if (swapsReportRefetchResult.status == "success") {
			let swapsReport = swapsReportRefetchResult.data.data.data;
			swapsReport = swapsReport.map((_) => {
				const swap = _.attributes;

				swap.transactionID = swap.transactionID.split("-")[0];

				return swap;
			});

			swapsReportDispatch({ type: SWAPS_REPORT_ACTIONS.updateState, key: "swapsReport", value: swapsReport });
		}
	};

	const [swapsReportState, swapsReportDispatch] = useReducer(SwapsReportReducer, {
		swapsReport: [],
		isFetchingSwapsReport: false,
		fetchSwapsReport,
		getSwapsReport,
	});

	return (
		<SwapsReportContext.Provider value={swapsReportState}>
			<SwapsReportDispatchContext.Provider value={swapsReportDispatch}>{children}</SwapsReportDispatchContext.Provider>
		</SwapsReportContext.Provider>
	);
};

export const useSwapsReport = () => {
	return useContext(SwapsReportContext);
};

export const useSwapsReportDispatch = () => {
	return useContext(SwapsReportDispatchContext);
};

const SwapsReportReducer = (swapsReportState, action) => {
	switch (action.type) {
		case SWAPS_REPORT_ACTIONS.updateState: {
			const newState = { ...swapsReportState };
			newState[action.key] = action.value;
			return newState;
		}
		case SWAPS_REPORT_ACTIONS.updateStates: {
			const newState = { ...swapsReportState };
			action.states.forEach(({ key, value }) => {
				newState[key] = value;
			});
			return newState;
		}
		default: {
			throw Error("[SwapsReportReducer] Unknown action: " + action.type);
		}
	}
};
