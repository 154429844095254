import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useReducer } from "react";
import { useAuth } from "../../../../../providers/AuthProvider";
import _ from "lodash";
import api from "../../../../../api";
import Big from "big.js";
import { formatAmount } from "../../../../../common/formatAmount";
import moment from "moment";

const OpenOrdersReportContext = createContext();
const OpenOrdersReportDispatchContext = createContext();

export const OPEN_ORDERS_REPORT_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
};

export const OpenOrdersReportProvider = ({ children }) => {
	const { authTokens } = useAuth();

	const getOpenOrdersReport = useQuery({
		queryKey: ["openOrdersReport"],
		queryFn: () => {
			return api.getOpenOrdersReport({ token: authTokens.IdToken });
		},
		enabled: false,
	});

	const fetchOpenOrdersReport = async () => {
		openOrdersReportDispatch({ type: OPEN_ORDERS_REPORT_ACTIONS.updateState, key: "isFetchingOpenOrdersReport", value: true });
		const openOrdersReportRefetchResult = await getOpenOrdersReport.refetch();
		openOrdersReportDispatch({ type: OPEN_ORDERS_REPORT_ACTIONS.updateState, key: "isFetchingOpenOrdersReport", value: false });
		if (openOrdersReportRefetchResult.status == "success") {
			let openOrdersReport = openOrdersReportRefetchResult.data.data.data;
			openOrdersReport = openOrdersReport.map((_) => {
				const order = _.attributes;
				const txn = order._rawTxn;
				const txnType = order._rawTxnType;
				order.transactionID = order.transactionID.split("-")[0];

				//Clean up manual transaction
				if (txnType == "manual") {
					order.price = `1 ${txn.sellCurrency} / ${formatAmount(txn.sellRate)} ${txn.buyCurrency}`;
					order.amount = `${formatAmount(txn.sellVolume)} ${txn.buyCurrency}`;
					order.total = `${formatAmount(txn.deliveredVolume)} ${txn.sellCurrency}`;
					order.date = moment(txn.date).format("DD/MM/YYYY - h:mm A");
				}

				//Clean up otctrade transaction
				if (txnType == "otctrade") {
					order.pair = `${txn.order.buyCurrency} - ${txn.order.sellCurrency}`;
					order.price = `1 ${txn.order.sellCurrency} / ${formatAmount(txn.order.volumeTypeAmount)} ${txn.order.buyCurrency}`;
					order.amount = `${formatAmount(txn.desiredPurchaseAmount)} ${txn.order.buyCurrency}`;
					const total = Big(txn.desiredPurchaseAmount).div(Big(txn.order.volumeTypeAmount)).toString();
					order.total = `${formatAmount(total)} ${txn.order.sellCurrency}`;
					order.date = moment(txn.createdAt).format("DD/MM/YYYY - h:mm A");
				}

				return order;
			});
			openOrdersReportDispatch({ type: OPEN_ORDERS_REPORT_ACTIONS.updateState, key: "openOrdersReport", value: openOrdersReport });
		}
	};

	const [openOrdersReportState, openOrdersReportDispatch] = useReducer(OpenOrdersReportReducer, {
		openOrdersReport: [],
		isFetchingOpenOrdersReport: false,
		fetchOpenOrdersReport,
		getOpenOrdersReport,
	});

	return (
		<OpenOrdersReportContext.Provider value={openOrdersReportState}>
			<OpenOrdersReportDispatchContext.Provider value={openOrdersReportDispatch}>{children}</OpenOrdersReportDispatchContext.Provider>
		</OpenOrdersReportContext.Provider>
	);
};

export const useOpenOrdersReport = () => {
	return useContext(OpenOrdersReportContext);
};

export const useOpenOrdersReportDispatch = () => {
	return useContext(OpenOrdersReportDispatchContext);
};

const OpenOrdersReportReducer = (openOrdersReportState, action) => {
	switch (action.type) {
		case OPEN_ORDERS_REPORT_ACTIONS.updateState: {
			const newState = { ...openOrdersReportState };
			newState[action.key] = action.value;
			return newState;
		}
		case OPEN_ORDERS_REPORT_ACTIONS.updateStates: {
			const newState = { ...openOrdersReportState };
			action.states.forEach(({ key, value }) => {
				newState[key] = value;
			});
			return newState;
		}
		default: {
			throw Error("[OpenOrdersReportReducer] Unknown action: " + action.type);
		}
	}
};
