import { Paperclip, PhoneIcon, SendIcon, User, VideoIcon, Loader2 } from "lucide-react";
import _ from "lodash";
import cn from "classnames";
import "./messages.scss";

import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../../../api";
import { useAuth } from "../../../providers/AuthProvider";
import no_thread_selected_icon from "../../../assets/no_thread_selected_icon.svg";
import { useChat, useChatDispatch } from "../providers/ChatProvider";
import { useProfile } from "../../../providers/ProfileProvider";
import { useInterval } from "../../../usehooks/usehooks";

const Messages = () => {
	const { messages, isFetchingMessages, selectedThread, isSendingMessage } = useChat();
	const { profile: myProfile } = useProfile();
	const { authTokens } = useAuth();

	const getMessages = useQuery({
		queryFn: () => {
			return api.getMessages({
				token: authTokens.IdToken,
				id: selectedThread.id,
			});
		},
		queryKey: ["messages"],
		enabled: false,
	});

	const [message, setMessage] = useState("");
	const chatDispatch = useChatDispatch();

	const messageInput = useRef(null);
	const paperClip = useRef(null);
	const paperClipDiv = useRef(null);

	const sendMessage = useMutation({
		mutationFn: ({ token, message, id }) => api.createMessage({ token, message, id }),
		onError: (error, variables, context) => {},
		onSuccess: async () => {
			const messagesRefetchResult = await getMessages.refetch();
			let _messages = messagesRefetchResult.data.data.data.attributes.map((datum) => datum);
			_messages = _.sortBy(_messages, ["createdAt"]);

			chatDispatch({ type: "setMessages", messages: _messages });
			messageInput.current.value = "";
			setMessage("");
			chatDispatch({ type: "setIsSendingMessage", isSendingMessage: false });
		},
	});

	//Poll messages every 5 seconds
	useInterval(async () => {
		const messagesRefetchResult = await getMessages.refetch();
		let _messages = messagesRefetchResult.data.data.data.attributes.map((datum) => datum);
		_messages = _.sortBy(_messages, ["createdAt"]);
		chatDispatch({ type: "setMessages", messages: _messages });
	}, 5000);

	//Get messages
	useEffect(() => {
		(async () => {
			if (!_.isEmpty(selectedThread)) {
				chatDispatch({ type: "setIsFetchingMessages", isFetchingMessages: true });
				const messagesRefetchResult = await getMessages.refetch();
				let _messages = messagesRefetchResult.data.data.data.attributes.map((datum) => datum);
				_messages = _.sortBy(_messages, ["createdAt"]);
				chatDispatch({ type: "setIsFetchingMessages", isFetchingMessages: false });
				chatDispatch({ type: "setMessages", messages: _messages });
			}
		})();
	}, [selectedThread]);

	const positionPaperClipDiv = () => {
		const clipIcon = paperClip.current;
		const window = paperClipDiv.current;

		window.style.top = `${clipIcon.offsetTop + clipIcon.offsetHeight - 80}px`;
		window.style.left = `${clipIcon.offsetLeft - (859 / 2 - clipIcon.offsetWidth * 8)}px`;
	};

	// state variable handling the deal room name
	const onChangeMessage = (evt) => {
		setMessage(evt.target.value);
	};

	const onSendMessageKeyDown = (evt) => {
		if (evt.key === "Enter" && !evt.shiftKey) {
			onSendMessage();
		}
	};

	const onSendMessage = () => {
		if (!_.isEmpty(message) && !isSendingMessage) {
			chatDispatch({ type: "setIsSendingMessage", isSendingMessage: true });
			sendMessage.mutate({
				token: authTokens.IdToken,
				message,
				id: selectedThread.id,
			});
		}
	};

	const getThreadName = (thread) => {
		let name = thread.name;
		if (thread.type == "individual") {
			const tokens = name.split("<>");
			name = thread.createdBy == myProfile.id ? tokens[1] : tokens[0];
		}
		return name;
	};

	return (
		<div className="chat_messages">
			{_.isEmpty(selectedThread) && (
				<div className="no_thread_selected">
					<div className="icon_container">
						<img src={no_thread_selected_icon} alt="" className="icon" />
					</div>
					<div className="heading">Trading Dealrooms</div>
					<div className="sub_heading">Join 1 or more dealrooms and get updates about crypto and fiat trade discounts</div>
				</div>
			)}

			{!_.isEmpty(selectedThread) && (
				<>
					<div className="chat_messages_heading">
						<div className="chat_messages_flex">
							<div className="chat_message_heading_left">
								<div className="chat_messages_avatar">
									<User />
								</div>
								<div className="chat_messages_info">
									<h5 className="name">{getThreadName(selectedThread)}</h5>
									<p className="status">{new Date(selectedThread.createdAt).toLocaleString()}</p>
								</div>
							</div>
							<div className="chat_message_heading_right">
								<div className="right_flex">
									<PhoneIcon />
									<VideoIcon />
								</div>
							</div>
						</div>
					</div>

					{isFetchingMessages && (
						<div className="messages_loader_container">
							<Loader2 className="messages_loader" />
						</div>
					)}

					{!isFetchingMessages && (
						<>
							<div className="chat_messages_list">
								{messages.map((message) => (
									<div key={message.id} className={cn("message", { left: message.author != myProfile.id })}>
										<div className="content">{message.content}</div>
										<div className="avatar">
											<User />
										</div>
									</div>
								))}

								{/* <div className="message_left">
                  <div className="chat_avatar">
                    <User />
                  </div>
                  <p className="my_message">Will revert shortly</p>
                </div>

                <div className="message_left">
                  <div className="chat_avatar">
                    <User />
                  </div>
                  <p className="my_message">Hello</p>
                </div> */}
							</div>

							<div className="chat_messages_bottom">
								<div className="chat_bottom_search">
									<div id="chat_bottom_message_search">
										<input ref={messageInput} type="text" placeholder="Type here" onChange={onChangeMessage} onKeyDown={onSendMessageKeyDown} />
									</div>
									<div className="send_icon" onClick={onSendMessage}>
										{isSendingMessage ? (
											<div className="send_loader_container">
												<Loader2 className="sender_loader" />
											</div>
										) : (
											<SendIcon />
										)}
									</div>
								</div>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default Messages;
