import * as Sentry from "@sentry/react";

const ENVIRONMENTS = {
    dev: "dev",
    staging: "staging",
    production: "production"
}

const getEnvironment = () => {
    const origin = location.origin;
    let environment = "n/a";
    switch (origin) {
        case "https://dev.muda-v2.com":
        case "http://localhost:5173":
            environment = ENVIRONMENTS.dev;
            break;
        case "https://staging.muda-v2.com":
            environment = ENVIRONMENTS.staging;
            break;
        case "https://muda-v2.com":
            environment = ENVIRONMENTS.production;
            break;
        default:
            environment = ENVIRONMENTS.dev;
            break;
    }
    return environment;
};

export const initializeSentry = () => {
    const environment = getEnvironment();
    const sentryConfig = {
        dsn: "https://34ab3d95d36cdd5436abc69725790294@o4506138494042112.ingest.us.sentry.io/4507586948235264",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    }

    switch (environment) {
        case ENVIRONMENTS.staging:
            sentryConfig.dsn = "https://779e530211d928b0d0a907d1a1c9077d@o4506138602897408.ingest.us.sentry.io/4507587926360064";
            sentryConfig.tracePropagationTargets = [
                "staging.muda-v2.com",
            ]
            sentryConfig.replaysSessionSampleRate = 1.0;
            break;
        case ENVIRONMENTS.production:
            sentryConfig.dsn = "https://19dc06361418143fa24553f0f11a6b2d@o4506138660765696.ingest.us.sentry.io/4507587919347712";
            sentryConfig.tracePropagationTargets = [
                "muda-v2.com",
            ]
            sentryConfig.replaysSessionSampleRate = 1.0;
            break;
        case ENVIRONMENTS.dev:
        default:
            sentryConfig.dsn = "https://34ab3d95d36cdd5436abc69725790294@o4506138494042112.ingest.us.sentry.io/4507586948235264";
            sentryConfig.tracePropagationTargets = [
                "localhost",
                "muda-v2.com",
                "dev.muda-v2.com",
                "muda.tech"
            ]
            sentryConfig.replaysSessionSampleRate = 1.0;
            break;
    }

    Sentry.init(sentryConfig);
}