import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useReducer, useState } from "react";
import api from "../../../api";
import { useAuth } from "../../../providers/AuthProvider";
import { Infinity } from "lucide-react";
import { useProfile } from "../../../providers/ProfileProvider";
import { useLocation } from "react-router-dom";

const ChatContext = createContext();
const ChatDispatchContext = createContext();

export const ChatProvider = ({ children }) => {
  const { authTokens, isLoggedIn } = useAuth();
  const getThreads = useQuery({
    queryFn: () => {
      return api.getThreads({ token: authTokens.IdToken });
    },
    queryKey: ["threads"],
    enabled: false
  });

  const emptyChatState = {
    selectedThread: {},
    threads: [],
    isFetchingThreads: false,
    getThreads,
    messages: [],
    isFetchingMessages: false,
    criteria: "",
    name: "",
    checked: false,
    addNewDeal: false,
    addParticipants: false,
    profiles: [],
    selectedProfiles: [],
    isSendingMessage: false,
    isCreatingThread: false
  };
  const [chatState, chatDispatch] = useReducer(chatReducer, emptyChatState);

  // Get threads
  useEffect(() => {
    if (!isLoggedIn) {
      chatDispatch({ type: "clearState" });
    }

    if (isLoggedIn) {
      (async () => {
        chatDispatch({ type: "setIsFetchingThreads", isFetchingThreads: true });
        const threadsRefetchResult = await getThreads.refetch();
        const threads = threadsRefetchResult.data.data.data.map((datum) => datum.attributes);
        chatDispatch({ type: "setThreads", threads });
        chatDispatch({ type: "setIsFetchingThreads", isFetchingThreads: false });
      })();
    }
  }, [isLoggedIn]);

  return (
    <ChatContext.Provider value={chatState}>
      <ChatDispatchContext.Provider value={chatDispatch}>{children}</ChatDispatchContext.Provider>
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  return useContext(ChatContext);
};

export const useChatDispatch = () => {
  return useContext(ChatDispatchContext);
};

const chatReducer = (chatState, action) => {
  switch (action.type) {
    // DEALING WITH THREADS
    case "setIsFetchingThreads":
      return { ...chatState, isFetchingThreads: action.isFetchingThreads };
    case "setSelectedThread":
      return { ...chatState, selectedThread: action.thread };
    case "setChecked":
      return { ...chatState, checked: action.checked };
    case "setAddNewDeal":
      return { ...chatState, addNewDeal: action.addNewDeal };
    case "setAddParticipants":
      return { ...chatState, addParticipants: action.addParticipants };
    case "setProfiles":
      return { ...chatState, profiles: action.profiles };
    case "setSelectedProfiles":
      // eslint-disable-next-line no-case-declarations
      const isSelected = chatState.selectedProfiles.some((selectedProfile) => selectedProfile.id === action.selectedProfile.id);
      if (isSelected) {
        return {
          ...chatState,
          selectedProfiles: chatState.selectedProfiles.filter((selectedProfile) => selectedProfile.id !== action.selectedProfile.id)
        };
      } else {
        return {
          ...chatState,
          selectedProfiles: [...chatState.selectedProfiles, action.selectedProfile]
        };
      }

    case "removeSelectedProfile":
      return {
        ...chatState,
        selectedProfiles: [...chatState.selectedProfiles.filter((profile) => profile.id !== action.id)]
      };
    case "setSearchTerm":
      return { ...chatState, criteria: action.criteria };
    case "setDealRoomName":
      return { ...chatState, name: action.name };
    case "setThreads":
      return { ...chatState, threads: action.threads };

    case "setIsCreatingThread":
      return { ...chatState, isCreatingThread: action.isCreatingThread };

    // DEALING WITH MESSAGES
    case "setIsFetchingMessages":
      return { ...chatState, isFetchingMessages: action.isFetchingMessages };
    case "setIsSendingMessage":
      return { ...chatState, isSendingMessage: action.isSendingMessage };
    case "setMessages":
      return { ...chatState, messages: action.messages };
    case "clearState":
      const emptyChatState = {
        selectedThread: {},
        threads: [],
        isFetchingThreads: false,
        messages: [],
        isFetchingMessages: false,
        criteria: "",
        name: "",
        checked: false,
        addNewDeal: false,
        addParticipants: false,
        profiles: [],
        selectedProfiles: [],
        isSendingMessage: false,
        isCreatingThread: false
      };
      return { ...chatState, ...emptyChatState };
    default: {
      throw Error("[chatReducer] Unknown action: " + action.type);
    }
  }
};
