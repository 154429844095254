import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../header/header";
import { Loader2, Info } from "lucide-react";
import cn from "classnames";
import "./login.scss";
import api from "../api";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../providers/AuthProvider";
import _ from "lodash";

const Login = () => {
	const { setIsLoggedIn, setAuthTokens, isLoggedIn } = useAuth();
	const navigate = useNavigate();

	if (isLoggedIn) {
		navigate(`/`);
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const login = useMutation({
		mutationFn: (creds) => {
			return api.login(creds);
		},
		onError: (error, variables, context) => {
			const errors = error.response.data.errors;
			const emailNotConfirmed = _.find(errors, ["title", "Email not confirmed"]);

			if (!_.isEmpty(emailNotConfirmed)) {
				const email = variables.email;
				navigate(`/confirm/email?email=${email}`);
			}
		},
		onSuccess: (data, variables, context) => {
			//Login (should be in auth provider)
			const authTokens = data.data.data.attributes;
			localStorage.setItem("authTokens", JSON.stringify(authTokens));
			setAuthTokens(authTokens);
			setIsLoggedIn(true);
		},
	});

	const onLogin = (creds) => {
		login.mutate(creds);
	};

	const handleForgotPasswordClick = () => {
		navigate("/start/reset/password");
	};

	return (
		<>
			<Header></Header>
			<div className="login_page">
				<div className="login_form">
					<div className="login_header">Log in</div>
					<div className="login_input_group email">
						<label className="login_input">
							<span className="login_input_label">
								Email <span className="required"></span>
							</span>
							<input
								placeholder="Enter your email"
								name="email"
								className={cn({
									error: errors.email?.type == "required" || login.isError,
								})}
								{...register("email", { required: true })}
							/>
							{errors.email?.type === "required" && (
								<span className="login_input_error">
									<Info className="login_input_error_icon" /> Email is required
								</span>
							)}
						</label>
					</div>
					<div className="spacer"></div>
					<div className="login_input_group password">
						<label className="login_input">
							<span className="login_input_label">
								Password <span className="required"></span>
							</span>
							<input
								type="password"
								placeholder="Enter your password"
								name="password"
								className={cn({
									error: errors.password?.type == "required" || login.isError,
								})}
								{...register("password", { required: true })}
							/>
							{errors.password?.type === "required" && (
								<span className="login_input_error">
									<Info className="login_input_error_icon" /> Password is required
								</span>
							)}
						</label>
					</div>
					<div className="login_forgot_password" onClick={handleForgotPasswordClick}>
						<span className="login_forgot_password_label">Forgot password?</span>
					</div>
					<div className="spacer"></div>
					<div className="spacer"></div>
					<div className="login_error_box">
						{login.isError && ( //check logic here
							<span className="signup_input_error">
								<Info className="signup_input_error_icon" /> Please check your email or password, & try again
							</span>
						)}
					</div>
					<div className="spacer"></div>
					<div className="login_btn_box">
						<div className={cn("login_btn", { is_logging_in: login.isPending })} onClick={handleSubmit(onLogin)}>
							{login.isPending && <Loader2 className="login_loader" />}
							{!login.isPending && <span>Log in</span>}
						</div>
					</div>
					<div className="spacer"></div>
					<div className="or_box">
						<hr /> <span className="or">or</span> <hr />
					</div>
					<div className="spacer"></div>
					<div className="do_not_have_an_account_box">
						Don't have an account? <Link to="/signup">Sign up here</Link>
					</div>
					<div className="spacer"></div>
					<div className="tocs">
						By clicking on Log in, you agree to our{" "}
						<a href="https://muda.tech/#/terms-and-conditions" target="_blank" className="highlight">
							terms & conditions
						</a>{" "}
						and{" "}
						<a href="https://muda.tech/#/privacy-policy" target="_blank" className="highlight">
							privacy policy
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
