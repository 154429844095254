import ReactSelect from "react-select";
import { useRates } from "../../../providers/Rates/RatesProvider";
import "./addTransaction.scss";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import api from "../../../api";
import { useAuth } from "../../../providers/AuthProvider";
import { Loader2 } from "lucide-react";
import _ from "lodash";
import Big from "big.js";
import cn from "classnames";
import { useSwapsReport } from "../transactionsHistory/swaps/providers/swapsReportProvider";

const reactSelectStyle = {
	control: (baseStyles, state) => ({
		...baseStyles,
		fontSize: ".9rem",
		fontWeight: "600",
		padding: ".2rem",
		borderRadius: "8px",
		color: "#172c50",
		backgroundColor: "#e4efff",
		borderColor: "light-dark(rgb(118, 118, 118), rgb(133, 133, 133))",
	}),
};

const emptyAddTransactionForm = {
	clientName: "",
	buyCurrency: "",
	sellCurrency: "",
	buyRate: "",
	sellRate: "",
	deliveredVolume: "",
	transactionCosts: "0",
};

const emptyHasError = {
	clientName: false,
	buyCurrency: false,
	sellCurrency: false,
	buyRate: false,
	sellRate: false,
	deliveredVolume: false,
	transactionCosts: false,
};

const emptyAddTransactionState = {
	addingTransaction: false,
	addTransactionForm: emptyAddTransactionForm,
	hasError: emptyHasError,
	errorMsg: emptyAddTransactionForm,
	addTransactionFormHasError: false,
};

const AddTransaction = () => {
	const { authTokens } = useAuth();

	const { fetchSwapsReport } = useSwapsReport();
	
	const [addTransactionState, setAddTransactionState] = useState(emptyAddTransactionState);

	const { currencies, rates, convert, getRate } = useRates();

	const addTransaction = useMutation({
		mutationFn: ({ transaction, token }) => {
			updateStates([
				{
					key: "addingTransaction",
					value: true,
				},
			]);
			return api.addTransaction({ transaction, token });
		},
		onError: async (error, variables, context) => {
			console.log(error);
			updateStates([
				{
					key: "addingTransaction",
					value: false,
				},
			]);
		},
		onSuccess: async (data, variables, context) => {
			console.log(data);
			await fetchSwapsReport();
			updateStates([
				{
					key: "addingTransaction",
					value: false,
				},
				{
					key: "addTransactionForm",
					value: emptyAddTransactionForm,
				},
			]);
		},
	});

	const updateState = ({ key, value }) => {
		const newState = { ...addTransactionState };
		newState[key] = value;
		setAddTransactionState(newState);
	};

	const updateStates = (states) => {
		const newState = { ...addTransactionState };
		states.forEach(({ key, value }) => {
			newState[key] = value;
		});
		setAddTransactionState(newState);
	};

	const onAddTransaction = async () => {
		if (disableAddTransactionBtn()) {
			return;
		}
		//Check for errors
		const token = authTokens.IdToken;
		const transaction = { ...addTransactionState.addTransactionForm };
		transaction.buyVolume = getBuyVolume();
		transaction.sellVolume = getSellVolume();
		await addTransaction.mutate({ token, transaction });
	};

	const clientNameChanged = (evt) => {
		const newAddTransactionForm = { ...addTransactionState.addTransactionForm, clientName: evt.target.value };
		updateState({ key: "addTransactionForm", value: newAddTransactionForm });
	};

	const buyCurrencyChanged = (evt) => {
		const newAddTransactionForm = { ...addTransactionState.addTransactionForm, buyCurrency: evt.value };
		updateState({ key: "addTransactionForm", value: newAddTransactionForm });
	};

	const sellCurrencyChanged = (evt) => {
		const newAddTransactionForm = { ...addTransactionState.addTransactionForm, sellCurrency: evt.value };
		updateState({ key: "addTransactionForm", value: newAddTransactionForm });
	};

	const getCurrencySelected = (prefix) => {
		const key = `${prefix}Currency`;
		const code = addTransactionState.addTransactionForm[key];
		if (!_.isEmpty(code)) {
			return {
				value: code,
				label: code,
			};
		}
	};

	const buyRateChanged = (evt) => {
		const newAddTransactionForm = { ...addTransactionState.addTransactionForm, buyRate: evt.target.value };
		updateState({ key: "addTransactionForm", value: newAddTransactionForm });
	};

	const sellRateChanged = (evt) => {
		const newAddTransactionForm = { ...addTransactionState.addTransactionForm, sellRate: evt.target.value };
		updateState({ key: "addTransactionForm", value: newAddTransactionForm });
	};

	const deliveredVolumeChanged = (evt) => {
		const newAddTransactionForm = { ...addTransactionState.addTransactionForm, deliveredVolume: evt.target.value };
		updateState({ key: "addTransactionForm", value: newAddTransactionForm });
	};

	const showRateHelpText = () => {
		return !_.isEmpty(addTransactionState.addTransactionForm.buyCurrency) && !_.isEmpty(addTransactionState.addTransactionForm.sellCurrency);
	};

	const showDelieveredVolumeHelpText = () => {
		return !_.isEmpty(addTransactionState.addTransactionForm.buyCurrency);
	};

	const showVolumes = () => {
		return (
			!_.isEmpty(addTransactionState.addTransactionForm.buyRate) &&
			!_.isEmpty(addTransactionState.addTransactionForm.sellRate) &&
			!_.isEmpty(addTransactionState.addTransactionForm.deliveredVolume)
		);
	};

	const formatAmount = (amount) => {
		amount = parseFloat(amount);
		const locale = Intl.NumberFormat().resolvedOptions().locale;
		const result = `${amount.toLocaleString(locale)}`;
		return !_.isNaN(amount) ? result : "";
	};

	const getBuyVolume = () => {
		const buyRate = addTransactionState.addTransactionForm.buyRate;
		const deliveredVolume = addTransactionState.addTransactionForm.deliveredVolume;
		const buyVolume = Big(buyRate).mul(Big(deliveredVolume)).toString();
		return buyVolume;
	};

	const getSellVolume = () => {
		const sellRate = addTransactionState.addTransactionForm.sellRate;
		const deliveredVolume = addTransactionState.addTransactionForm.deliveredVolume;
		const sellVolume = Big(sellRate).mul(Big(deliveredVolume)).toString();
		return sellVolume;
	};

	const transactionCostsChanged = (evt) => {
		const newAddTransactionForm = { ...addTransactionState.addTransactionForm, transactionCosts: evt.target.value };
		updateState({ key: "addTransactionForm", value: newAddTransactionForm });
	};

	const showSummary = () => {
		return showVolumes() && showRateHelpText();
	};

	const getProfit = () => {
		// Profit in Buy Currency
		const buyVolume = getBuyVolume();
		const sellVolume = getSellVolume();
		const profit = Big(sellVolume).sub(Big(buyVolume)).toString();
		return profit;
	};

	const getProfitInUSD = () => {
		// Profit in USD
		const profit = getProfit();
		const profitInUSD = convert({
			rates,
			from: addTransactionState.addTransactionForm.buyCurrency,
			to: "USD",
			amount: profit,
		});
		return profitInUSD;
	};

	const getUsdToUsdtRate = () => {
		const rate = getRate({
			rates,
			from: "USD",
			to: "USDT",
		});
		return rate;
	};

	const getProfitInUSDT = () => {
		// Profit in USD
		const profitInUSD = getProfitInUSD();
		const profitInUSDT = convert({
			rates,
			from: "USD",
			to: "USDT",
			amount: profitInUSD,
		});
		return profitInUSDT;
	};

	const getNetProfitInUSDT = () => {
		const profitInUSDT = getProfitInUSDT();
		const netProfitInUSDT = Big(profitInUSDT).sub(Big(addTransactionState.addTransactionForm.transactionCosts)).toString();
		return netProfitInUSDT;
	};

	const disableAddTransactionBtn = () => {
		const enable =
			!_.isEmpty(addTransactionState.addTransactionForm.clientName) &&
			!_.isEmpty(addTransactionState.addTransactionForm.buyCurrency) &&
			!_.isEmpty(addTransactionState.addTransactionForm.sellCurrency) &&
			!_.isEmpty(addTransactionState.addTransactionForm.buyRate) &&
			!_.isEmpty(addTransactionState.addTransactionForm.sellRate) &&
			!_.isEmpty(addTransactionState.addTransactionForm.deliveredVolume);
		return !enable;
	};

	return (
		<div id="add_transaction_component">
			<div className="add_transaction_controls">
				<svg className="add_transaction_control close" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
					<path d="M19 19L5 5M19 5L5 19" stroke="#172C50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			</div>
			<div className="add_transaction_header">ADD TRANSACTION</div>
			<div className="add_transaction_body">
				<div className="inputs">
					<div className="input client_name">
						<div className="label">Client</div>
						<input type="text" value={addTransactionState.addTransactionForm.clientName} placeholder="Enter client's name" onChange={clientNameChanged} />
					</div>

					<div className="input currency_pair">
						<div className="label">
							Currency pair <span className="help_text">( Buy - Sell )</span>
						</div>
						<div className="currencies">
							<div className="currency buy_currency">
								<ReactSelect
									value={getCurrencySelected("buy")}
									onChange={buyCurrencyChanged}
									styles={reactSelectStyle}
									options={currencies.map((c) => ({
										value: c.code,
										label: c.code,
									}))}></ReactSelect>
							</div>

							<div className="currency sell_currency">
								<ReactSelect
									value={getCurrencySelected("sell")}
									onChange={sellCurrencyChanged}
									styles={reactSelectStyle}
									options={currencies.map((c) => ({
										value: c.code,
										label: c.code,
									}))}></ReactSelect>
							</div>
						</div>
					</div>

					<div className="input rates">
						<div className="buy_rate">
							<div className="label">
								Buy rate
								{showRateHelpText() && (
									<>
										<br />{" "}
										<span className="help_text">
											( 1 {addTransactionState.addTransactionForm.sellCurrency} = ...{addTransactionState.addTransactionForm.buyCurrency} )
										</span>
									</>
								)}
							</div>
							<input type="number" value={addTransactionState.addTransactionForm.buyRate} placeholder="Enter buy rate" onChange={buyRateChanged} />
						</div>
						<div className="sell_rate">
							<div className="label">
								Sell rate
								{showRateHelpText() && (
									<>
										<br />{" "}
										<span className="help_text">
											( 1 {addTransactionState.addTransactionForm.sellCurrency} = ...{addTransactionState.addTransactionForm.buyCurrency} )
										</span>
									</>
								)}
							</div>
							<input type="number" value={addTransactionState.addTransactionForm.sellRate} placeholder="Enter sell rate" onChange={sellRateChanged} />
						</div>
					</div>

					<div className="input delivered_volume">
						<div className="label">
							Delivered Volume
							{showDelieveredVolumeHelpText() && <span className="help_text"> ( {addTransactionState.addTransactionForm.sellCurrency} )</span>}
						</div>
						<input
							type="number"
							value={addTransactionState.addTransactionForm.deliveredVolume}
							placeholder="Enter delivered volume"
							onChange={deliveredVolumeChanged}
						/>
					</div>

					{showVolumes() && (
						<div className="input volumes">
							<div className="label">
								Volumes <span className="help_text">( Buy - Sell )</span>
							</div>
							<div className="volumes">
								<div className="volume buy_volume">
									{formatAmount(getBuyVolume())} {addTransactionState.addTransactionForm.buyCurrency}
								</div>
								<div className="volume sell_volume">
									{formatAmount(getSellVolume())} {addTransactionState.addTransactionForm.buyCurrency}
								</div>
							</div>
						</div>
					)}

					<div className="input transaction_costs">
						<div className="label">
							Transaction Costs <span className="help_text">( USDT )</span>
						</div>
						<input
							type="number"
							value={addTransactionState.addTransactionForm.transactionCosts}
							placeholder="Enter transaction costs"
							onChange={transactionCostsChanged}
						/>
					</div>

					{showSummary() && (
						<div className="summary">
							<div className="summary_line">
								<div className="lhs">Profit :</div>
								<div className="rhs">
									{formatAmount(getProfit())} {addTransactionState.addTransactionForm.buyCurrency} = {formatAmount(getProfitInUSD())} USD
								</div>
							</div>
							<div className="summary_line">
								<div className="lhs">USD/USDT Rate :</div>
								<div className="rhs">1 : {formatAmount(getUsdToUsdtRate())}</div>
							</div>
							<div className="summary_line">
								<div className="lhs">Profit USDT :</div>
								<div className="rhs">{formatAmount(getProfitInUSDT())} USDT</div>
							</div>
							<div className="summary_line">
								<div className="lhs">Transaction Costs :</div>
								<div className="rhs">{addTransactionState.addTransactionForm.transactionCosts} USDT</div>
							</div>
							<div className="summary_line highlight">
								<div className="lhs">Net Profit :</div>
								<div className="rhs">{formatAmount(getNetProfitInUSDT())} USDT</div>
							</div>
						</div>
					)}
				</div>

				{!addTransactionState.addingTransaction && (
					<div className={cn("add_transaction_cta", { disabled: disableAddTransactionBtn() })} onClick={onAddTransaction}>
						Add Transaction
					</div>
				)}
				{addTransactionState.addingTransaction && (
					<div className="add_transaction_cta">
						<Loader2 className="add_transaction_cta_loader" />
					</div>
				)}
			</div>
		</div>
	);
};
export default AddTransaction;
