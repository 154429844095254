import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { useAuth } from "./AuthProvider";
import api from "../api";

const OtctradesContext = createContext();
const OtctradesDispatchContext = createContext();

export const OtctradesProvider = ({ children }) => {
  const { authTokens, isLoggedIn } = useAuth();

  const getOtctrades = useQuery({
    queryKey: ["otctrades"],
    queryFn: () => {
      otctradesDispatch({
        type: "fetchingOtctrades"
      });
      return api.getOtctrades({ token: authTokens.IdToken });
    },
    enabled: false
  });

  const [otctradesState, otctradesDispatch] = useReducer(otctradesReducer, {
    otctrades: [],
    isLoadingOtctrades: false
  });

  const getOtctrade = ({currency}) => {
    const otctrade = otctradesState.otctrades.find((w) => w.currency === currency);
    return otctrade;
  };

  //get otctrades
  useEffect(() => {
    (async () => {
      // fetch otctrades if logged in
      if (isLoggedIn) {
        const otctradesRefetchResult = await getOtctrades.refetch();
        if (otctradesRefetchResult.status == "success") {
          const otctrades = otctradesRefetchResult.data.data.data.map((datum) => datum.attributes);
          otctradesDispatch({
            type: "fetchedOtctrades",
            otctrades
          });
        }
      }
    })();
  }, [isLoggedIn]);

  return (
    <OtctradesContext.Provider value={{ ...otctradesState, getOtctrade }}>
      <OtctradesDispatchContext.Provider value={otctradesDispatch}>{children}</OtctradesDispatchContext.Provider>
    </OtctradesContext.Provider>
  );
};

export const useOtctrades = () => {
  return useContext(OtctradesContext);
};

export const useOtctradesDispatch = () => {
  return useContext(OtctradesDispatchContext);
};

const otctradesReducer = (otctradesState, action) => {
  switch (action.type) {
    case "fetchedOtctrades":
      return { ...otctradesState, otctrades: action.otctrades, isLoadingOtctrades: false };
    case "fetchingOtctrades":
      return { ...otctradesState, isLoadingOtctrades: true };
    default: {
      throw Error("[otctradesReducer] Unknown action: " + action.type);
    }
  }
};
